import React from 'react'
import './PostBasicCard.css'
import { Link } from "react-router-dom";


function PostBasicCard({post}) {
    return (
        <div className='post-basic-card'>
            <Link to={`/blog/${post.slug}`}>
                {/* <img src={post.icon_sm} className="post-basic-card-banner" alt="..."/> */}
                <div className='post-basic-card-banner' style={{backgroundImage: `url(${post.icon_sm})`}}>
                </div>
                <div className="post-basic-card-tags">
                    {post.tags.map((tag, index) => (
                        <object key={tag.id}>
                            <Link 
                                key={tag.id} className="post-basic-card-tag"
                                to={`/blog/tag/${tag.slug}`} 
                                rel="tag" 
                                title={`Открыть статьи раздела ${tag.name}`}
                            >
                                {tag.name}
                            </Link>
                        {index < post.tags.length - 1 &&
                            <span className="post-basic-card-point">•</span>
                        }
                        </object>
                    ))}
                </div>
                <h5 className="post-basic-card-title">{post.title}</h5>
                <p className="post-card-description">{post.subtitle}</p>
            </Link>
        </div>
    )
}

export default PostBasicCard