import { useEffect, useState } from 'react'
import Container from 'components/UI/Container'
import { LikeIcon } from 'components/svg/icons'

function YourResults({resultsArray, resultsImage}) {
    const [leftColumn, setLeftColumn] = useState([])
    const [rightColumn, setRightColumn] = useState([])


    useEffect(() => {
        const middleIndex = Math.ceil(resultsArray.length/2)
        setLeftColumn(resultsArray.slice( 0 , middleIndex ))
        setRightColumn(resultsArray.slice( middleIndex , resultsArray.length ))

    }, [resultsArray])

    return (
        <section className="your-results-section">
            <Container>
                <h2 className="section-title">Ваш <span className="text-color-theme">результат</span> после курса</h2>
                <div className='your-results-content'>
                    <div className='your-results-left'>
                        {leftColumn.map(item =>(
                            <YouResultsItem text={item} key={item}/>
                        ))}
                    </div>
                    <div className='your-results-middle'>
                        <img src={resultsImage} alt="Ваш результат после курса"/>
                    </div>
                    <div className='your-results-right'>
                        {rightColumn.map(item =>(
                            <YouResultsItem text={item} key={item}/>
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default YourResults


const YouResultsItem = ({text}) => {
    return (
        <div className='your-result-item'>
            <div className='your-results-icon-wrapper'>
                <LikeIcon />
            </div>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
    )
}