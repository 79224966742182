export function declOfNum(n, text_forms) {  
    n = Math.abs(n) % 100; 
    var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    if (n > 0 && n < 1) { return text_forms[1]; }
    return text_forms[2];
}

export const getTimePastFromDate = (date, limit_in_days=7) => {
    // Receives UTC date and returns how many days|hours|minutes|seconds passed
    // Exp: 2 дней, 4 месяца, 10 лет
    // If days passed more than limit, returns simple local format date
    // Exp: 10 октября 2012
    const _MS_PER_D = 86400000;
    const _MS_PER_H = 3600000;
    const _MS_PER_M = 60000;
    const _MS_PER_S = 1000;

    const date_options = { year: 'numeric', month: 'long', day: 'numeric' };

    const timeUnits = {
        days: ['день', 'дня', 'дней'],
        hours: ['час', 'часа', 'часов'],
        minutes: ['минута', 'минуты', 'минут'],
        seconds: ['секунда', 'секунды', 'секунд']
    }

    const utc1 = Date.now();
    const utc2 = Date.parse(date)
    const ms_passed = Math.floor(utc1 - utc2)

    if ((Math.floor(ms_passed / _MS_PER_D)) > limit_in_days) {
        return new Date(date).toLocaleDateString('ru-RU', date_options).slice(0, -3);
    }

    let result = ''

    if (ms_passed >= _MS_PER_D) {
        let days = Math.floor(ms_passed / _MS_PER_D)
        let unit = declOfNum(days, timeUnits.days)

        result = `${days} ${unit}`
    } else if (ms_passed >= _MS_PER_H) {
        let hours = Math.floor(ms_passed / _MS_PER_H)
        let unit = declOfNum(hours, timeUnits.hours)

        result = `${hours} ${unit}`
    } else if (ms_passed >= _MS_PER_M) {
        let minutes = Math.floor(ms_passed / _MS_PER_M)
        let unit = declOfNum(minutes, timeUnits.minutes)

        result = `${minutes} ${unit}`
    } else if (ms_passed >= _MS_PER_S) {
        let seconds = Math.floor(ms_passed / _MS_PER_S)
        let unit = declOfNum(seconds, timeUnits.seconds)

        result = `${seconds} ${unit}`
    } else if (ms_passed < _MS_PER_S) {
        return 'только что'
    }

    return result + ' назад'


    // const publish_date = new Date(date_edited).toLocaleString('ru-RU')

    // document.getElementById('button').addEventListener('click', () => {

    // console.log('diffDays: ', diffDays)
    // })
}

export const makeRandomID = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        let randomNumber = Math.floor(Math.random() * characters.length)
        result += characters.charAt(randomNumber)
    }
    return result;
}

export const getHashParameter = (name) => {
    const match = RegExp("[#&]" + name + "=([^&]*)").exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export const getQueryParameter = (name) => {
    const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}