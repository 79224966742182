import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

function UnAuthenticatedRoute({children}) {
    const { isAuth } = useContext(AuthContext);

    if (isAuth) {
        return <Navigate to="/login" replace/>;
    }
  
    return children;
}

export default UnAuthenticatedRoute