import {useState} from 'react'
import {useEventListener} from './useEventListener'

export const getIsMobile = () => window.innerWidth <= 767;

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEventListener(
        "resize", e => setIsMobile(getIsMobile()), window
    )
    
    return isMobile;
}