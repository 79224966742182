import React from 'react'
import { HelpPageBanner } from 'components/svg/images/HelpPageBanner';
import { Row, Col } from 'react-bootstrap';
import { useLocation, Link } from "react-router-dom";
import LinkBlocks from './LinkBlocks';
import SubHead from 'components/UI/SubHead';


function NeedHelp({toggleMobileMenu, someValue}) {
    const { pathname } = useLocation();


    return (
        <div>
            <SubHead title={'Нужна помощь?'} toggleMobileMenu={toggleMobileMenu}/>
            <h1>{someValue}</h1>
            <section id='landing'>
                <Row className='banner-subpage mx-0'>
                    <Col xs={12} md={7} className='pe-0'>
                        <HelpPageBanner />
                    </Col>
                    <Col xs={12} md={5} className='pc-only ps-0'>
                        <h3>Нужна помощь?</h3>
                    </Col>
                </Row>
            </section>
            <section>
                <Row className='mt-4'>
                    {LinkBlocks.map(block => (
                        <Block key={block.link} block={block} pathname={pathname}/>
                    ))}
                </Row>
            </section>
        </div>
    )
}

export default NeedHelp

const Block = ({block, pathname}) => (
    <Col xs={12} sm={6} lg={4} className='d-flex flex-column pb-4'>
        <div className='d-flex align-items-center'>
            {block.icon}
            <h4>{block.title}</h4>
        </div>
        <div className='pt-2 mb-4'>
            <ul className="list-style-none">
                {block.links.map(link =>
                    <li className="pt-2" key={link.to}><Link to={`${pathname}/${block.link}/${link.to}`}>{link.title}</Link></li>
                )}
            </ul>
        </div>
    </Col>
)
