export const toggleFilterInArray = (filter, type, allFilters, setAllFilters)=> {
    // Set Filter in array of Filters to Active or Inactive
    let _allFilters = {...allFilters}

    _allFilters[type] = allFilters[type].map( item => {
        if(item.slug === filter.slug) {
            return {...item, is_active: !filter.is_active};
        }
        return item;
    })

    setAllFilters(_allFilters)
}