import { CircularProgress } from '@mui/material';
import './Loader.css'

const Loader = ({size, background=false, position}) => {
    let classNames = ['loader']
    if (background) { classNames.push('loader-background') }
    if (position==="absolute") { classNames.push('loader-absolute') }

    return (
        <div className={classNames.join(' ')}>
            <CircularProgress color="inherit" size={size}/>
        </div>
    )
}

export default Loader