import { useEffect, useState, useRef } from 'react'

function TextArea({value, onChange}) {
    const textareaRef = useRef(null)
    const [inputBorderWidth, setInputBorderWidth] = useState(0)
    const [inputMinHeight, setInputMinHeight] = useState(0)

    useEffect(() => {
        let inputStyle = window.getComputedStyle(textareaRef.current)
        let _inputBorderWidth = parseInt(inputStyle.borderWidth.replace('px', ''))
        setInputBorderWidth( _inputBorderWidth )
        let inputLineHeight = parseFloat(inputStyle.lineHeight.replace('px', ''))
        let paddingTop = parseInt(inputStyle.paddingTop.replace('px', ''))
        let paddingBottom = parseInt(inputStyle.paddingBottom.replace('px', ''))

        setInputMinHeight ( (inputLineHeight * 2) + (_inputBorderWidth * 2) + paddingTop + paddingBottom )
    }, [])

    useEffect(() => {
        if (inputBorderWidth && inputMinHeight) {
            autoGrow(textareaRef.current, inputBorderWidth, inputMinHeight)
        }
    }, [value, inputBorderWidth, inputMinHeight])

    return (
        <textarea 
            ref={textareaRef}
            className='form-control' 
            onChange={onChange}
            value={value}
        >
        </textarea>
    )
}

export default TextArea

const autoGrow = (element, borderWidth, minHeight) => {
    element.style.height = "5px";
    let newHeight = (element.scrollHeight)+(borderWidth * 2)
    element.style.height = Math.max(minHeight, newHeight) + "px";
}