import Button from "components/UI/Button/Button";
import Container from "components/UI/Container";
import { useState, useEffect, useContext } from "react";
import { useInput } from "hooks/useInput";
import InputErrorMsg from "components/InputErrorMsg";
import BackdropLoader from "components/UI/BackdropLoader/BackdropLoader";
import Loader from "components/UI/Loader/Loader";
import { useFetching } from "hooks/useFetching";
import CourseService from "services/CourseService";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "context/AuthContext";
import MessageContext from "context/MessageContext";
import { Link } from "react-router-dom";



function Checkout() {
    let { isAuth, user } = useContext(AuthContext);
    let { slug } = useParams();
    const [course, setCourse] = useState(null);
    const [purchaseType, setPurchaseType] = useState(null)
    const [paymentValid, setPaymentValid] = useState(true)
    const [acceptAgreement, setAcceptAgreement] = useState(false)
    const fullName = useInput(user ? user.full_name : '', 'full_name', 'text', {notEmpty: true, minLength: 3, maxLength: 50})
    const email = useInput(user ? user.email : '', 'email', 'email', {notEmpty: true, isEmail: true, minLength: 3, maxLength: 50})
    const { setMessages } = useContext(MessageContext)

    
    const [fetchCourseBySlug] = useFetching(async () =>{
        const params = { 'scope': 'access' }
        const response = await CourseService.getCourse(slug, params);
        console.log('response', response)
        const courseData = response.data
        setCourse(courseData)

        if (courseData.access == null) {
            setPurchaseType('new')
            return
        }
        if (course.access.is_expired) {
            setPurchaseType('extend')
        } else {
            setMessages([{level: 'error', message: 'Данный курс уже приобретен'}])
            setPaymentValid(false)
        }
    }, null, 'fetchCourseBySlug')

    const [requestTestPayment, testPaymentLoading] = useFetching(async () => {
        const formData = new FormData()
        formData.append('full_name', fullName.value)
        formData.append('email', email.value)
        formData.append('course_id', course.id)
        const response = await CourseService.requestPayment('tt', formData)
        console.log('response', response)
        window.location.href = `/payment/test?id=${response.data.payment.id}&amount=${response.data.payment.total_amount}`
    })

    function submit(e) {
        e.preventDefault()
        const isFullNameValid = fullName.StartValidation()
        const isEmailValid = email.StartValidation()
        if (isFullNameValid && isEmailValid) {
            console.log('All valid')
            requestTestPayment()
        } else {
            console.log('INVALID')
        }
    }
    
    useEffect(() => {
        fetchCourseBySlug()
        console.log('slug', slug)
      }, []);

    if (course === null) { return null }

    return (
        <Container>
            <form className="checkout-form">
                <div className="checkout-section">
                    <h4 className="mb-3">Информация покупателя</h4>
                    <dl className="form-group">
                        <dt><label className="form-label" htmlFor="fullName">Полное имя</label></dt>
                        <dd>
                            <input 
                            type="text" 
                            id="fullName" 
                            className="form-control full-width" 
                            value={fullName.value} 
                            onChange={e => fullName.onChange(e)} 
                            required
                        />
                        </dd>
                        <InputErrorMsg field={fullName}/>
                    </dl>
                    <dl className="form-group">
                        <dt className="mb-2"><label className="form-label" htmlFor="email">E-mail</label></dt>
                        <dd>
                            <input
                                type="email"
                                value={email.value}
                                id="email"
                                className="form-control full-width"
                                onChange={e => email.onChange(e)} required
                            />
                            <InputErrorMsg field={email}/>
                        </dd>
                    </dl>
                    {course.course_type == 'survey' &&
                        <p className="text-small">Удостоверьтесь в правильности написания почвого ящика. На данную почту будет отправлена ваша анкета.</p>
                    }
                </div>
                <div className="checkout-section">
                    <h4>Детали заказа</h4>
                    <table className="checkout-price-table">
                        <tbody>
                            <tr>
                                <td>{course.title}</td>
                                <td>{course.price}</td>
                            </tr>
                            <tr>
                                <td>Скидка</td>
                                <td>
                                    {purchaseType == 'new'
                                        ? 0
                                        : parseFloat(course.price) - parseFloat(course.extend_price)
                                    }
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>ИТОГО</td>
                                <td>{purchaseType == 'new'
                                        ? course.price
                                        : course.extend_price
                                    }
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="checkout-section">
                    <h4 className="mb-3">Выберите способ оплаты</h4>
                    <div id="settings-emails" className="box payment-platforms">
                        <label className="box-row payment-platform">
                            <div className="custom-radio-wrapper mb-2">
                                <input type="radio" name="email" defaultChecked={true} className="custom-radio"/>
                                <span className="checkmark"></span>
                                <h5>Карты Казахстана и зарубежных банков</h5>
                            </div>
                            <p>Платежи по всем видам карт Казахстана и зарубежья, Apple pay и Samsung Pay</p>
                            <div className="payment-methods-icons">
                                <div className="payment-visa"></div>
                                <div className="payment-mastercard"></div>
                                <div className="payment-unionpay"></div>
                                <div className="payment-applepay"></div>
                                <div className="payment-samsungpay"></div>
                            </div>
                        </label>
                        <label className="box-row payment-platform">
                            <div className="custom-radio-wrapper mb-2">
                                <input type="radio" name="email" defaultChecked={false} className="custom-radio"/>
                                <span className="checkmark"></span>
                                <h5>Карты выпущенные в РФ</h5>
                            </div>
                            <p>Банковские карты выпущенные в России, а также приложения банков SberPay, СБП, Альфа-Клик, Тинькофф и Mir Pay</p>
                            <div className="payment-methods-icons">
                                <div className="payment-visa"></div>
                                <div className="payment-mir"></div>
                                <div className="payment-mastercard"></div>
                                <div className="payment-unionpay"></div>
                                <div className="payment-sberpay"></div>
                                <div className="payment-alfabank"></div>
                                <div className="payment-qiwi"></div>
                            </div>
                        </label>
                    </div>
                    <p>При оплате валютой отличной от Тенге, стоимость будет конвертирована по курсу выбранного платежного сервиса</p>
                </div>
                <div className="mb-3">
                    <label>
                        <div className="custom-checkbox-wrapper">
                            <input type="checkbox" id="vehicle1" checked={acceptAgreement} onChange={e => setAcceptAgreement(e.target.checked)} className="custom-checkbox"/>
                            <span className="checkmark"></span>
                            {isAuth
                                ? <span htmlFor="vehicle1">Я соглашаюсь с <Link to={'/help/agreements/public-offer'}>Договором оферты</Link></span>
                                : <span htmlFor="vehicle1">Я соглашаюсь с <Link to={'/help/agreements/public-offer'}>Договором оферты</Link> и <Link to={'/help/agreements/privacy-policy'}>Политикой конфиденциальности</Link></span>
                            }
                        </div>
                    </label>
                </div>
                <div className='d-flex flex-row align-items-center'>
                    <button onClick={submit} className="btn btn-primary btn-full-width" disabled={!acceptAgreement || !paymentValid}>Далее</button>
                </div>

            </form>
        </Container>
    )
}

export default Checkout