import { useState, useEffect, useRef } from 'react'
import { useSize } from 'hooks/useSize';

export default function TopBanner() {
    const titleWrapper = useRef(null)
    const titleWrapperSize = useSize(titleWrapper);
    const titlePercentage = 0.35; // % 
    const [fontSize, setFontSize] = useState(50)

    useEffect(() => {
        if (titleWrapperSize) {
            let minSide = Math.min(titleWrapperSize.height, titleWrapperSize.width)
            let newFontSize = Math.round(titlePercentage * minSide);
            setFontSize(newFontSize)
        }
    }, [titleWrapperSize])

    return (
        <section className="banner-section">
            <div className="banner-wrapper">
                <div className='banner-title-wrapper col-7' ref={titleWrapper} style={{ fontSize: fontSize }}>
                    <div className='banner-title-inner-wrapper'>
                        <p className='banner-main-title'>MaddyFit</p>
                        <p className='banner-sub-title'>студия фитнеса</p>
                    </div>
                </div>
                <div className='banner-pic-wrapper col-5'>
                    <img src='images/banner_pic.png' alt='MaddyFit'></img>
                </div>
            </div>
        </section>
    )
}