import Modal from "components/UI/Modal/Modal";
import Button from "components/UI/Button/Button";

import { useState, useEffect } from "react";
import { useInput } from "hooks/useInput";
import InputErrorMsg from "components/InputErrorMsg";
import BackdropLoader from "components/UI/BackdropLoader/BackdropLoader";
import Loader from "components/UI/Loader/Loader";
import { useFetching } from "hooks/useFetching";
import CourseService from "services/CourseService";
import { useNavigate } from "react-router-dom";

function PurchaseModal({course, isAuth, user, isOpen, setOpen}) {
    const fullName = useInput(user ? user.full_name : '', 'full_name', 'text', {notEmpty: true, minLength: 3, maxLength: 50})
    const email = useInput(user ? user.email : '', 'email', 'email', {notEmpty: true, isEmail: true, minLength: 3, maxLength: 50})

    const [showLoader, setShowLoader] = useState(false)

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    const [infoComplete, setInfoComplete] = useState(fullName.value.length >= 3 && re.test(String(email.value).toLowerCase()))
    const [showPaymentSelect, setShowPaymentSelect] = useState(course.course_type == 'standard' && infoComplete)

    function submit(e) {
        e.preventDefault()
        const isFullNameValid = fullName.StartValidation()
        const isEmailValid = email.StartValidation()
        if (isFullNameValid && isEmailValid) {
            console.log('All valid')
            setInfoComplete(true)
            setShowPaymentSelect(true)
        } else {
            console.log('INVALID')
        }
    }

    if (!isOpen) { return null }

    return (
        <Modal isOpen={isOpen} setOpen={setOpen} showCloseBtn={true} showLoader={showLoader}>
        <div className='buy-course-modal'>
            {showPaymentSelect ?
                <PaymentSelect course={course} setShowLoader={setShowLoader} fullName={fullName.value} email={email.value}/>
                :
                <form className="buy-course-form">
                    <div>
                        <dl className="form-group">
                            <dt><label className="form-label" htmlFor="fullName">Полное имя</label></dt>
                            <dd>
                                <input 
                                type="text" 
                                id="fullName" 
                                className="form-control full-width" 
                                value={fullName.value} 
                                onChange={e => fullName.onChange(e)} 
                                required
                            />
                            </dd>
                            <InputErrorMsg field={fullName}/>
                        </dl>
                        <dl className="form-group">
                            <dt className="mb-2"><label className="form-label" htmlFor="email">E-mail</label></dt>
                            <dd>
                                <input
                                    type="email"
                                    placeholder="E-mail адрес"
                                    value={email.value}
                                    id="email"
                                    className="form-control full-width"
                                    onChange={e => email.onChange(e)} required
                                />
                                <InputErrorMsg field={email}/>
                            </dd>
                        </dl>
                        {course.course_type == 'survey' &&
                            <p className="text-small">Удостоверьтесь в правильности написания почвого ящика. На данную почту будет отправлена ваша анкета.</p>
                        }
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <button onClick={submit} className="btn btn-primary btn-full-width">Далее</button>
                    </div>
                </form>
            }
        </div>
        </Modal>
    )
}

export default PurchaseModal


const createEPayPaymentObject = function(epay_auth, payment, post_link, failure_post_link, course) {
    const frontURL = process.env.REACT_APP_FRONT_URL
    const apiURL = process.env.REACT_APP_API_URL
    const paymentObject = {
        invoiceId: payment.id,
        backLink: `${frontURL}/payment/check?id=${payment.id}`,
        failureBackLink: `${frontURL}/payment/check?id=${payment.id}`,
        postLink: post_link,
        failurePostLink: failure_post_link,
        language: "rus",
        description: `Покупка курса ${course.title}`,
        accountId: "testuser1",
        terminal: "67e34d63-102f-4bd1-898e-370781d0074d",
        amount: payment.total_amount,
        data: `{\"statement\":{\"name\":\"${payment.full_name}\",\"invoiceID\":\"${payment.id}\"}}`,
        currency: "KZT",
        email: payment.email,
        cardSave: false
    };
    paymentObject.auth = epay_auth;
    return paymentObject;
};


const PaymentSelect = ({course, fullName, email, setShowLoader})=> {

    const [requestEPay, EPayLoading] = useFetching(async () => {
        const formData = new FormData()
        formData.append('full_name', fullName)
        formData.append('email', email)
        formData.append('course_id', course.id)
        const response = await CourseService.requestPayment('ep', formData)
        const data = response.data
        window.halyk.pay(createEPayPaymentObject(data.epay_auth, data.payment, data.post_link, data.failure_post_link, course));
    })


    const [requestYookassa, YookassaLoading] = useFetching(async () => {
        const frontURL = process.env.REACT_APP_FRONT_URL
        const formData = new FormData()
        formData.append('full_name', fullName)
        formData.append('email', email)
        formData.append('course_id', course.id)
        //final return_url is set on backend because it requires Payment ID
        formData.append('return_base_url', `${frontURL}/payment/check`)
        const response = await CourseService.requestPayment('yk', formData)
        console.log('requestYookassa response', response.data)
        const confirmation_url = response.data.confirmation_url
        const payment = response.data.payment
        window.location.href = confirmation_url
    })


    const [requestTestPayment, testPaymentLoading] = useFetching(async () => {
        const formData = new FormData()
        formData.append('full_name', fullName)
        formData.append('email', email)
        formData.append('course_id', course.id)
        const response = await CourseService.requestPayment('tt', formData)
        console.log('response', response)
        window.location.href = `/payment/test?id=${response.data.payment.id}&amount=${response.data.payment.total_amount}`
    })

    useEffect(() => {
        setShowLoader(EPayLoading || YookassaLoading || testPaymentLoading)
    }, [EPayLoading, YookassaLoading])


    return (
        <div className="payment-select">
            <h4>Выберите способ оплаты</h4>
            <div className="payment-platform" onClick={requestTestPayment}>
                <h5>Карты Казахстана и зарубежных банков</h5>
                <p>Платежи по всем видам карт Казахстана и зарубежья, Apple pay и Samsung Pay</p>
                <div className="payment-methods-icons">
                    <div className="payment-visa"></div>
                    <div className="payment-mastercard"></div>
                    <div className="payment-unionpay"></div>
                    <div className="payment-applepay"></div>
                    <div className="payment-samsungpay"></div>
                </div>
            </div>
            <div className="payment-platform" onClick={requestTestPayment}>
                <h5>Карты выпущенные в РФ</h5>
                <p>Банковские карты выпущенные в России, а также приложения банков SberPay, СБП, Альфа-Клик, Тинькофф и Mir Pay</p>
                <div className="payment-methods-icons">
                    <div className="payment-visa"></div>
                    <div className="payment-mir"></div>
                    <div className="payment-mastercard"></div>
                    <div className="payment-unionpay"></div>
                    <div className="payment-sberpay"></div>
                    <div className="payment-alfabank"></div>
                    <div className="payment-qiwi"></div>
                </div>
            </div>
            <p>При оплате валютой отличной от Тенге, стоимость будет конвертирована по курсу выбранного платежного сервиса</p>
        </div>
    )
}