import React from "react";
import { useLocation } from "react-router-dom";


// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useNamedQuery(name) {
    const { search } = useLocation();
    let query = React.useMemo(() => new URLSearchParams(search), [search])
    return query.get(name);
}