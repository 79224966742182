import {IconVK, IconFacebook, IconTelegram, IconTwitter, IconOdnoklassniki, IconInstagram, IconWhatsapp } from '../svg/icons';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, VKShareButton, OKShareButton } from 'react-share';
import './SocialShare.css'


function SocialShare({instance}) {
    if (instance == null) {
        return null
    }
    
    return (
        <div className='social-share-wrapper'>
            <div className='share-icons'>
                <VKShareButton url={window.location.href} title={instance.title} image={instance.icon_sm}>
                    <IconVK height={16}/>
                </VKShareButton>

                <FacebookShareButton url={window.location.href}>
                    <IconFacebook height={16}/>
                </FacebookShareButton>

                <TelegramShareButton url={window.location.href} title={instance.title}>
                    <IconTelegram height={16}/>
                </TelegramShareButton>

                <TwitterShareButton url={window.location.href} title={instance.title}>
                    <IconTwitter height={16}/>
                </TwitterShareButton>

                <OKShareButton url={window.location.href} title={instance.title}>
                    <IconOdnoklassniki height={16}/>
                </OKShareButton>

                <WhatsappShareButton url={window.location.href} title={instance.title}>
                    <IconWhatsapp height={16}/>
                </WhatsappShareButton>


                {/* <span className='share-icon-facebook'><IconFacebook /></span>
                <span className='share-icon-twitter'><IconTwitter /></span>
                <span className='share-icon-telegram'><IconTelegram /></span>
                <span className='share-icon-odnoklassniki'><IconOdnoklassniki /></span> */}
            </div>
        </div>
    )
}

export default SocialShare