import Container from 'components/UI/Container'

function PrivacyPolicy() {
    return (
        <Container>
            <div className='docs'>
                <div className='docs-section'>
                    <p>Настоящая Политика конфиденциальности персональной информации (далее – Политика) разработана в целях соблюдения закона Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите» и регулирует правоотношения по обработке персональной информации между пользователем сайта <a href='https://maddy.fit'>https://maddy.fit</a> (далее – Сайт) сети «Интернет» и Администрацией сайта.<p/>
                    <p></p>Использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации. В случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>1.	Общие положения</h4>
                    <p>1.1.	Основные понятия, используемые в Политике:</p>
                    <p>1.1.1.	«Администрация сайта» – ТОО MaddyFit, БИН 220340008615</p>
                    <p>1.1.2.	«Персональная информация» Пользователя:</p>
                    <p>Персональная информация, которую Пользователь предоставляет о себе самостоятельно при создании заказа (регистрации учетной записи) или в процессе использования Сайта, включая персональные данные Пользователя. Обязательная для предоставления информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение. В число персональных данных Пользователя входят данные, которые Пользователь может предоставить о себе: фамилия, имя отчество (псевдоним), номер телефона, дополнительная информация, указываемая самостоятельно Пользователем на Сайте.</p>
                    <p>Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.</p>
                    <p>1.1.3.	«Автоматизированная обработка персональных данных» – обработка персональных данных с помощью средств вычислительной техники.</p>
                    <p>1.1.4.	«Блокирование персональных данных» – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                    <p>1.1.5.	«Информационная система персональных данных» — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.</p>
                    <p>1.1.6.	«Обезличивание персональных данных» — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
                    <p>1.1.7.	«Обработка персональных данных» – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                    <p>1.1.8.	«Трансграничная передача персональных данных» – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
                    <p>1.1.9.	«Уничтожение персональных данных» – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>
                    <p>1.2.	Настоящая Политика конфиденциальности применяется только к Сайту <a href='https://maddy.fit'>https://maddy.fit</a>. Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>2.	Цель и правовые основания обработки персональной информации пользователей</h4>
                    <p>2.1.	Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
                    <p>2.2.	Персональную информацию Пользователя Сайт обрабатывает с целью оказания услуг, предоставления Пользователю доступа к ресурсам Сайта и установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, обработку запросов и заявок от Пользователя.</p>
                    <p>2.3.	Правовыми основаниями обработки Персональной информации Пользователя Сайта являются:</p>
                    <p>2.3.1.	Федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных.</p>
                    <p>2.3.2.	Согласие Пользователя на обработку его персональных данных.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>3.	Условия обработки персональной информации пользователей и ее передачи третьим лицам</h4>
                    <p>3.1.	Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами Администрации сайта.</p>
                    <p>3.2.	В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.</p>
                    <p>3.3.	Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
                    <p>3.3.1.	Пользователь выразил согласие на такие действия.</p>
                    <p>3.3.2.	Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем.</p>
                    <p>3.3.3.	Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.</p>
                    <p>3.3.4.	В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
                    <p>3.4.	Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с законом Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите».</p>
                    <p>3.5.	При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
                    <p>3.6.	Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                    <p>3.7.	Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                    <p>3.8.	Пользователь вправе изменить свои персональные данные на Сайте или потребовать их удаления, направив электронное письмо по адресу: <a href='mailto:info@maddy.fit'>info@maddy.fit</a>.</p>
                    <p>3.9.	Администрация сайта имеет право назначить лицо, ответственное за организацию обработки персональных данных Пользователя.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>4.	Трансграничная передача персональных данных</h4>
                    <p>4.1.	Администрация сайта до начала осуществления трансграничной передачи персональных данных обязана убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</p>
                    <p>4.2.	Трансграничная передача персональных данных на территории иностранных государств не осуществляется.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>5.	Обязательства сторон</h4>
                    <p>5.1.	Пользователь обязан:</p>
                    <p>5.1.1.	Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</p>
                    <p>5.1.2.	Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.</p>
                    <p>5.2.	Администрация сайта обязана:</p>
                    <p>5.2.1.	Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.</p>
                    <p>5.2.2.	Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.</p>
                    <p>5.2.3.	Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.</p>
                    <p>5.2.4.	Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>6.	Ответственность сторон</h4>
                    <p>6.1.	Администрация сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Казахстан.</p>
                    <p>6.2.	В случае утраты или разглашения конфиденциальной информации Администрация сайта не несет ответственности, если данная конфиденциальная информация:</p>
                    <p>6.2.1.	Стала публичным достоянием до ее утраты или разглашения.</p>
                    <p>6.2.2.	Была получена от третьей стороны до момента ее получения Администрацией сайта.</p>
                    <p>6.2.3.	Была разглашена с согласия Пользователя.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>7.	Разрешение споров</h4>
                    <p>7.1.	До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                    <p>1.1.	Получатель претензии в течение 10 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                    <p>7.2.	При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Республики Казахстан.</p>
                    <p>7.3.	К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Республики Казахстан.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>8.	Дополнительные условия</h4>
                    <p>8.1.	Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
                    <p>8.2.	Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности. Политика действует бессрочно до замены ее новой Политикой.</p>
                    <p>8.3.	Все предложения или вопросы по настоящей Политике конфиденциальности следует направлять по адресу: <a href='mailto:info@maddy.fit'>info@maddy.fit</a>.</p>
                    <p>8.4.	Действующая Политика конфиденциальности размещена на Сайте по адресу: <a href='https://maddy.fit/help/agreements/privacy-policy'>https://maddy.fit/help/agreements/privacy-policy</a> сети «Интернет».</p>
                </div>
            </div>
        </Container>
    )
}

export default PrivacyPolicy



