import React, { useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext'
import { Redirect, Routes, Route } from 'react-router-dom';
import CoursePage from '../pages/Course/CoursePage';
import LoginPage from '../pages/Login/LoginPage';
import SignUpPage from '../pages/Login/SignUpPage';

import HomePage from '../pages/Home/HomePage';
import StudyPage from '../pages/Study/StudyPage';
import MySettings from '../pages/MySettings'
import MyProfile from '../pages/MyProfile'
import PasswordResetSend from '../pages/PasswordResetSend';
import PasswordResetConfirm from '../pages/PasswordResetConfirm';
import PostPage from '../pages/PostPage';
import PostsPage from '../pages/PostsPage';
import ProfilePage from '../pages/ProfilePage';
import HelpPage from '../pages/Help/HelpPage';
import AboutPage from '../pages/AboutPage';
import ContactsPage from '../pages/ContactsPage';
import PostEditor from '../pages/admin/PostEditor';
import Unsubscribe from '../pages/Unsubscribe';
import { useLocation } from 'react-router-dom';
import RecipePage from '../pages/RecipePage';
import RecipesListPage from '../pages/RecipesListPage';
import AuthPopupCallBack from '../components/SocialAuth/AuthPopupCallBack';
import GetVKAuthCode from '../components/SocialAuth/VK/GetVKAuthCode';
import GetFacebookAuthCode from '../components/SocialAuth/Facebook/GetFacebookAuthCode';
import ProtectedRoute from './ProtectedRoute';
import UnAuthenticatedRoute from './UnAuthenticatedRoute';
import PaymentSuccess from 'pages/Payment/PaymentSuccess';
import PaymentFailed from 'pages/Payment/PaymentFailed';
import PaymentCheck from 'pages/Payment/PaymentCheck';
import SurveyPage from 'pages/Study/SurveyPage';
import PaymentTest from 'pages/Payment/PaymentTest';
import Checkout from 'pages/Payment/Checkout';


const AppRouter = () => {
    const { isAuth } = useContext(AuthContext);
    const { pathname } = useLocation()

    const authorizedAndCommonRoutes = commonRoutes.concat(authorizedUsersOnly)
    const unauthorizedAndCommonRoutes = commonRoutes.concat(unauthorizedUsersOnly)


    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/help/*" element={<HelpPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/course/:slug" element={<CoursePage />} />
            <Route path="/auth/social/vk/start" element={<GetVKAuthCode />} />
            <Route path="/auth/social/facebook/start" element={<GetFacebookAuthCode />} />
            <Route path="/auth/social/:provider/callback" element={<AuthPopupCallBack />} />
            <Route path="/my/*" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
            <Route path="/settings/*" element={<ProtectedRoute><MySettings /></ProtectedRoute>} />
            <Route path="/profile/:id" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/unsubscribe" element={<ProtectedRoute><Unsubscribe /></ProtectedRoute>} />
            <Route path="/password_reset" element={<ProtectedRoute><PasswordResetSend /></ProtectedRoute>} />
            <Route path="/password_reset/confirm/:uid/:token/" element={<ProtectedRoute><PasswordResetConfirm /></ProtectedRoute>} />
            <Route path="/study/:slug/*" element={<ProtectedRoute><StudyPage /></ProtectedRoute>} />
            <Route path="/login" element={<UnAuthenticatedRoute><LoginPage /></UnAuthenticatedRoute>} />
            <Route path="/signup" element={<UnAuthenticatedRoute><SignUpPage /></UnAuthenticatedRoute>} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/failure" element={<PaymentFailed />} />
            <Route path="/payment/check" element={<PaymentCheck />} />
            <Route path="/payment/test" element={<PaymentTest />} />
            <Route path="/checkout/:slug" element={<Checkout />} />
            <Route path="/survey" element={<SurveyPage />} />
        </Routes>
        
        // isAuthd
        // ?
        
        // :
        // <Routes>
        //     {unauthorizedAndCommonRoutes.map(route => 
        //         <Route
        //             component={route.component}
        //             path={route.path}
        //             exact={route.exact}
        //             key={route.path}
        //         />
        //     )}
        //     <Redirect to={{pathname: '/login', state: { from: pathname}}}/>
        // </Routes>
    );
};

export default AppRouter

const commonRoutes = [
    {path: '/about', component: AboutPage, exact: true },
    {path: '/contacts', component: ContactsPage, exact: true },
    {path: '/help', component: HelpPage, exact: false },
    {path: '/profile/:id', component: ProfilePage, exact: true },
    {path: '/unsubscribe', component: Unsubscribe, exact: true },

    {path: '/blog/:slug', component: PostPage, exact: true },
    {path: '/blog/tag/:tag', component: PostsPage, exact: true },
    {path: '/blog', component: PostsPage, exact: false },
    {path: '/recipes', component: RecipesListPage, exact: true },
    {path: '/recipes/:slug', component: RecipePage, exact: true },
    {path: '/password_reset', component: PasswordResetSend, exact: true },
    {path: '/password_reset/confirm/:uid/:token/', component: PasswordResetConfirm, exact: true },
    {path: '/login', component: LoginPage, exact: true },
    {path: '/signup', component: SignUpPage, exact: true },
    {path: '/course/:number', component: CoursePage, exact: true },
    {path: '/auth/social/vk/start', component: GetVKAuthCode, exact: false },
    {path: '/auth/social/facebook/start', component: GetFacebookAuthCode, exact: false },
    {path: '/auth/social/:provider/callback', component: AuthPopupCallBack, exact: false },
    {path: '/', component: HomePage, exact: true },
]


const authorizedUsersOnly = [
    {path: '/admin/posts/editor', component: PostEditor, exact: true },
    {path: '/settings', component: MySettings, exact: false },
    {path: '/notifications', component: MyProfile, exact: false },
    {path: '/courses', component: MyProfile, exact: false },
    {path: '/study/:course_number', component: StudyPage, exact: false },
    // {path: '/study/:course_number/:lesson_number/', component: StudyPage, exact: true },
]


const unauthorizedUsersOnly = [
    {path: '/login', component: LoginPage, exact: true },
    {path: '/signup', component: SignUpPage, exact: true },
]

