import React from 'react'
import { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../UI/Button/Button';
import AuthContext from '../../context/AuthContext';
import PostService from '../../services/PostService';
import { PostCommentsContext } from './PostComments';
import { useFetching } from '../../hooks/useFetching';
import { useInput, setFormsErrors } from '../../hooks/useInput';
import InputErrorMsg from '../InputErrorMsg';
import { FormHelperText } from '@mui/material';
import Avatar from '../UI/Avatar/Avatar';
import Alerts from '../Alerts'
import TextArea from '../Comments/TextArea'
import { addNewCommentToList, updateComment } from '../Comments/utils';


function PostCommentForm({mode, post_id, parent, self, replyTo, toggleForm}) {
    let { user, isAuth } = useContext(AuthContext);
    let { comments, setComments, users, setUsers } = useContext(PostCommentsContext)

    const [alertMessages, setAlertMessages] = useState([])

    const { pathname } = useLocation()
    const text = useInput('', 'comment_text', 'text', {notEmpty: true})


    useEffect(() => {

        let isMounted = true;
        if (isMounted){
            if (mode == 'edit') {
                // Bold text is no longer in use. Can be deleted.
                let noBoldText = self.text.replace(/<b>|<\/b>/g,'')
                text.setValue(noBoldText)
            }

            if (mode == 'reply') {
                const reply_to = users.find(_user => _user.id == parent.author)
                if (reply_to && reply_to.full_name) {
                    text.setValue(`@id${reply_to.id} (${reply_to.full_name.replace(' ', '_')}), `)
                }
            }
        }

        return () => { isMounted = false };

        // if (replyTo) {
        //     const reply_to = users.find(_user => _user.id == replyTo.author)
        //     if (reply_to && reply_to.full_name) {
        //         text.setValue(`@${reply_to.full_name.replace(' ', '_')}, `)
        //     }
        // }
        // if (self) {
        //     let noBoldText = self.text.replace(/<b>|<\/b>/g,'')
        //     text.setValue(noBoldText)
        // }

    }, [])


    async function SumbitComment() {
        const isTextValid = text.StartValidation()
        if (isTextValid) {
            sendNewComment()
        }
    }

    const [sendNewComment, sendNewCommentLoading, sendNewCommentError] = useFetching(async () => {
        let formData = new FormData();
        let highlightName = text.value.replace(/(@[\wа-я]+)/gi, '<b>$1</b>');
        formData.append('text', text.value)
        
        if (mode == 'new') {
            formData.append('target', post_id)
        }

        if (mode == 'reply') {
            formData.append('target', parent.target)
            formData.append('thread', parent.thread ? parent.thread : parent.id)
            formData.append('parent', parent.id)
        }

        let response;
        if (self) {
            response = await PostService.updateComment(self.id, formData)
        } else {
            response = await PostService.addNewComment(formData)
        }
        text.reset()
        handleResponse(response)
    })

    const handleResponse = async (response) => {
        if (mode == 'edit') {
            updateComment(response.data, comments, setComments)
        } else {
            addNewCommentToList(response.data, comments, setComments, users, setUsers)
        }

        if (mode != 'new') { toggleForm(false) }
    }

    useEffect(() => {
        if(sendNewCommentError?.data) {
            if (sendNewCommentError.status == 500) {
                setAlertMessages([{level: 'error', message: 'Что-то пошло не так. Повторите попытку позже.'}])
            } else {
                
            }

        }
    }, [sendNewCommentError])

    return (
        <div className='post-comment-form'>
            <Avatar user={user} showDefault className={'me-2 comment-avatar'}/>
            <div className='post-comment-form-controls'>
                <Alerts messages={alertMessages} setMessages={setAlertMessages}/>
                <div className="form-group post-comment-input mb-2">
                    <TextArea value={text.value} onChange={e => text.setValue(e.target.value)}/>
                    <InputErrorMsg field={text}/>
                </div>
                {isAuth 
                    ?
                    <ControlButtons SumbitComment={SumbitComment} Close={toggleForm} self={self} replyTo={replyTo}/>
                    :
                    <span className='post-comment-form-login-required'>
                        <Link to={{pathname: '/login', state: { from: pathname}}}>Войдите</Link>, чтобы комментировать
                    </span>

                }
            </div>
        </div>
    )
}

export default PostCommentForm


const ControlButtons = ({SumbitComment, Close, self, replyTo}) => {
    const [showClose] = useState(self || replyTo)
    return (
        <div className='post-comment-form-buttons'>
        <Button onClick={SumbitComment} className='btn me-2'>Отправить</Button>
        {showClose &&
            <Button onClick={Close} className='btn'>Закрыть</Button>
        }
        </div>
    )

}
