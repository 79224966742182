import React from 'react'
import './Badge.css'

function Badge({children, className}) {
    return (
        <span className={className}>
            {children}
        </span>
    )
}

export default Badge
