import { useEffect, useState } from 'react'
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import { Container, Col } from 'react-bootstrap';
import MyCourses from '../components/MyProfile/MyCourses';
import Notifications from '../components/MyProfile/Notifications';
import useNotifFilter from '../components/MyProfile/useNotifFilter';
import { useToggle } from '../hooks/useToggle';
import { IconButton } from '@mui/material';
import { OptionsIcon, FilterCancelIcon } from '../components/svg/icons'
import NavMenu from '../components/UI/NavMenu/NavMenu';
import NavMenuMobile from '../components/UI/NavMenu/NavMenuMobile';

function MyProfile() {
    const [showMobileMenu, toggleMobileMenu] = useToggle(false)
    const [bannerImg, setBannerImg] = useState('')

    useEffect(() => {
        const currentHours = new Date().getHours()
        const isDay = currentHours > 6 && currentHours < 21
        const imgUrl = isDay ? "/images/header-img-day@2x.png" : "/images/header-img-night@2x.png"
        setBannerImg(imgUrl)
    }, [])


    return (
        <Container className="d-flex flex-column flex-grow-1 pt-3">
            <div className="two-column-row d-flex flex-direction-row flex-grow-1">
                <Col xs={12} md={3} className="pe-md-4">
                    <img src={bannerImg} className="header-banner" alt="..."/>
                    <NavMenu title={'Мой аккаунт'}>
                        <MyProfileLinks className={'nav-menu-item'}/>
                    </NavMenu>
                </Col>

                <Col xs={12} md={9} className="d-flex flex-column flex-grow-1">
                    <Routes>
                        <Route path={`courses`} element={<MyCourses toggleMobileMenu={toggleMobileMenu}/>} />
                        <Route path={`notifications`} element={<Notifications toggleMobileMenu={toggleMobileMenu}/>} />
                    </Routes>
                </Col>
            </div>
            <NavMenuMobile title={'Мой аккаунт'} isOpen={showMobileMenu} setOpen={toggleMobileMenu}>
                <MyProfileLinks className={'nav-menu-item-mobile'}/>
            </NavMenuMobile>
        </Container>
    )
}

export default MyProfile

const MyProfileLinks = ({className}) => {
    const { pathname } = useLocation()
    const showFilterMenu = pathname === `/notifications`
    const filters = useNotifFilter()

    return (
        <>
        <NavLink to={`courses`} className={className} activeClassName="active">Мои курсы</NavLink>
        <NavLink to={`notifications`} className={className} data-block-closing>Уведомления</NavLink>
        {showFilterMenu &&
            <nav className="filter-menu">
            {className === 'menu-item-mobile' && <hr className="divider"/>}
            {filters.map(options => (
                <div key={options[0].name}>
                    <hr className="divider"/>
                    {options.map(option => ( <FilterLink key={option.name} filter={option} pathname={pathname}/> ))}
                </div>
            ))}
        </nav>}
        </>
    )
}

const FilterLink = ({filter, pathname}) => {
    return (
        <div className='filter-item-wrapper'>
            <NavLink to={{ pathname: pathname, search: filter.search, }} className="filter-item" activeClassName="active" data-block-closing>
                {filter.name}
            </NavLink>
            {filter.active &&
                <NavLink to={{ pathname: pathname, search: filter.clear, }} className="filter-close" data-block-closing>
                    <FilterCancelIcon className='filter-close-icon'/>
                </NavLink>
            }
        </div>
    )
}


