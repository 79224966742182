import axios from "axios";
import $api from '../utils/AxiosInstance';

export default class SurveyService {

    static async getServey(params) {
        return $api.get(`/api/surveys`, {params: params})
    }

    static async submitServey(data) {
        return $api.put(`/api/surveys/`, data)
    }

}