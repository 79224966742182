import { useEffect, useState } from "react"
import { useToggle } from "hooks/useToggle"
import Modal from "./Modal"


function FullImageModal({imagesSelector}) {
    const [showModal, toggleModal] = useToggle(false)
    const [source, setSource] = useState('')

    useEffect(() => {
        const images = document.querySelectorAll(imagesSelector)
        images.forEach(image => {
            image.addEventListener('click', handleImageClick);
        })
        return () => {
            images.forEach((image) => {
              image.removeEventListener('click', handleImageClick);
            });
        };
    }, [])

    const handleImageClick = (event) => {
        setSource(event.target.src)
        toggleModal()
    };

    return (
        <Modal isOpen={showModal} setOpen={toggleModal} className={'full-image-modal'}>
            <img src={source}></img>
        </Modal>
    )
}

export default FullImageModal