import React from 'react';
import ReactDOM from "react-dom/client"
import App from './App';
import { AuthProvider } from './context/AuthContext'
import { MessageProvider } from './context/MessageContext'
import { ThemeProvider } from './context/ThemeContext'
import './fonts/Haigrast.woff2';
import './fonts/Ponpewd-Signature.ttf';


const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <MessageProvider>
          <App />
        </MessageProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
)