import React, { useMemo } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';


function ReturnPaneButton() {

    const navigate = useNavigate()
    // const location = useLocation()

    // const fromUrl = useMemo(() => {
    //     return location?.state?.from;
    // }, [location]);

    // return (
    //     <span className='PreviousStepButton' onClick={() => window.close()}>
    //         <ChevronLeftIcon />
    //     </span>
    // )  

    return (
        <button className='page-return-button' onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
        </button>
    )

    // if (fromUrl) {
    //     console.log('From Url')
    //     return (
    //         <Link className='PreviousStepButton' to={fromUrl}>
    //             <ChevronLeftIcon />
    //         </Link>
    //     )
    // } else {
    //     console.log('Go back')

    //     return (
    //         <span className='PreviousStepButton' onClick={() => history.goBack()}>
    //             <ChevronLeftIcon />
    //         </span>
    //     )

    // }

}

export default ReturnPaneButton

const ChevronLeftIcon = () => (
    <svg width="20" height="20" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.42114 13.8684L1.42114 7.86842L7.42114 1.86842" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)