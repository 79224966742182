import { useState, useEffect, useCallback, useContext } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import StepPlayer from 'components/Player/StepPlayer';
import VideoPagination from "./VideoPagination";
import FinishLesson from 'components/Study/FinishLesson';
import PdfLinks from './PdfLinks';
import Comments from 'components/Courses/Comments';
import { useFetching } from "hooks/useFetching";
import { useToggle } from "hooks/useToggle";
import { getIsMobile } from "hooks/useIsMobile";
import StepService from 'services/StepService';
import FinishCourseModal from 'components/Study/FinishCourseModal';
import usePDFDownload from "hooks/usePDFDownload";
import LessonService from "services/LessonService";
import MessageContext from 'context/MessageContext';
import FullImageModal from "components/UI/Modal/FullImageModal";


function StepPage({course, setCourse, lessons, setLessons, toggleLessonNavDefault, toggleLessonNavMobile}) {
    const [lesson, setLesson] = useState(null) 
    const [steps, setSteps] = useState([])
    const [step, setStep] = useState(null)
    const [playerText, setPlayerText] = useState('')
    const [showFinishCourseModal, setShowFinishCourseModal] = useToggle(false)
    const { setMessages } = useContext(MessageContext)

    const location = useLocation()
    const navigate = useNavigate()
    let { slug, lesson_number, step_number } = useParams();

    const errorHandler = (response) => {
        let message = response?.data?.detail ? response.data.detail : 'Ошибка запроса'
        setMessages([{level: 'error', message: message}])
    }

    // Lesson
    const [getLesson, getLessonLoading, getLessonError] = useFetching(async () => {
        const response = await LessonService.getLesson(course.id, lesson_number)
        setLesson(response.data)
        getSteps()
        getStep()
    }, errorHandler)

    
    // Steps
    const [getSteps, getStepsLoadings, getStepsErrors] = useFetching(async () => {
        const response = await StepService.getSteps(course.id, lesson_number)
        setSteps(response.data)
    })

    // Step
    const [getStep, getStepLoading, getStepError] = useFetching(async () => {
        //Some courses use Lesson pagination and each Lesson have only one step
        //so for them the step number is not showing in the ulr
        //If the url has no step number -> get step 1
        const response = await StepService.getStep(course.id, lesson_number, step_number || 1)
        console.log('getStep', response.data)
        setStep(response.data)
    }, errorHandler)


    const [finishLesson, finishLessonLoading, finishLessonError] = useFetching(async () => {
        const response = await LessonService.finishLesson(lesson.id)
        console.log('finishLesson', response.data)
        let _lessonProgress = response.data?.progress?.lesson
        let _nextLessonProgress = response.data?.progress?.next_lesson
        let _courseProgress = response.data?.progress?.course
        let _courseAccess = response.data?.access?.course
        console.log('_nextLessonProgress', _nextLessonProgress)

        let _lessons = [...lessons]

        if (_lessonProgress) {
            setLesson({...lesson, progress: _lessonProgress})
            const found = _lessons.find( el => el.id === _lessonProgress.lesson )
            if (found) {
                found.progress = _lessonProgress
            }
        }

        if (_nextLessonProgress) {
            const found = _lessons.find( el => el.id === _nextLessonProgress.lesson )
            console.log('found', found)

            if (found) {
                found.progress = _nextLessonProgress
            }
        }
        console.log('_lessons', _lessons)
        setLessons(_lessons)

        if (_courseProgress) {
            setCourse({...course, progress: _courseProgress, access: _courseAccess})
            if (_courseProgress.is_passed === true) {
                setShowFinishCourseModal(true)
            }
        }
    })


    const isLoading = getStepLoading || getStepsLoadings || getLessonLoading

    const toggleLessonNavMenu = () => {
        if (getIsMobile()) { toggleLessonNavMobile() }
        else { toggleLessonNavDefault() }
    }


    const [setStepWatched] = useFetching(async () => {
        const response = await StepService.markStepWatched(step.id)
        console.log('setStepWatched')
    })


    const openNextOnVideoEnd = useCallback(() => {
        console.log('openNextOnVideoEnd 1')
        if (step.progress.is_watched === false) {
            setStepWatched()
        }
        console.log('openNextOnVideoEnd 2')

        let nextSteps = steps.filter(_step => _step.number > step.number)
        let nextLessons = lessons.filter(_lesson => _lesson.number > lesson.number)

        if (nextSteps.length > 0) {
            // Go to next step
            navigate(
                `/study/${course.slug}/${lesson_number}/${nextSteps[0].number}`,
                {state: { autoplay: true}}
            )
            return;
        }

        if (lesson.progress.is_passed == false) {
            console.log('openNextOnVideoEnd 3')

            finishLesson()
        }

    }, [steps, step])


    useEffect(() => {
        setPlayerText('')
        if (lesson == null || lesson.number != lesson_number) {
            // Lesson changed
            console.log('lesson changed')
            getLesson()
        } else if (step == null || step.number != step_number) {
            // Step changed
            console.log('step changed')
            getStep()
        }
    }, [location])

    const downloadPDF = usePDFDownload()

    if (step == null) {
        return null
    }

    return (
        <>
            <h5 className="lesson-title">{lesson.title}</h5>
            <StepPlayer 
                step={step} 
                toggleShowLeftBar={toggleLessonNavMenu} 
                playerText={playerText} 
                isVideoLoading={isLoading} 
                onEnded={openNextOnVideoEnd}
            />
            <VideoPagination
                lessons={lessons}
                lesson={lesson}
                steps={steps}
                step={step}
                isLoading={isLoading}
                paginationType={course.pagination_type}
                toggleMobileLessonMenu={toggleLessonNavMobile}
            />
            {course.progress_type != 'Open' &&
                <FinishLesson 
                    lesson={lesson}
                    finishLesson={finishLesson}
                    finishLessonLoading={finishLessonLoading}
                />
            }
            {/* <PdfLinks links={step.pdf_links}/> */}
            <div className="course-web-content" dangerouslySetInnerHTML={{ __html: step.web_page }}>
            </div>
            {course.support_comments &&
                <Comments step={step}/>
            }
            <FinishCourseModal course={course} isOpen={showFinishCourseModal} toClose={setShowFinishCourseModal}/>
            <FullImageModal imagesSelector={'.course-web-content img'}/>
        </>
    )
}

export default StepPage