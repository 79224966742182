import { useEffect, useState } from 'react'
import { useNamedQuery } from '../../hooks/useQuery';
import { useLocation } from 'react-router-dom';

function useNotifFilter() {

    let currentFavorite = useNamedQuery('favorite')
    let currentStatus = useNamedQuery('read')
    let currentType = useNamedQuery('type')

    const [favoriteFilter, setFavoriteFilter] = useState([
        {
            name: 'Избранное',
            type: 'favorite',
            value: 'true',
            search: '',
            clear: '',
            active: false
        }
    ])

    const [statusFilters, setStatusFilters] = useState([
        {
            name: 'Прочитанное',
            type: 'read',
            value: 'true',
            search: '',
            clear: '',
            active: false
        },
        {
            name: 'Непрочитанное',
            type: 'read',
            value: 'false',
            search: '',
            clear: '',
            active: false

        },
    ])

    const [typeFilters, setTypeFilters] = useState([
        {
            name: 'Аккаунт',
            type: 'type',
            value: 'account',
            search: '',
            clear: '',
            active: false
        },
        {
            name: 'Курсы',
            type: 'type',
            value: 'course',
            search: '',
            clear: '',
            active: false
        },
        {
            name: 'Комментарии',
            type: 'type',
            value: 'comments',
            search: '',
            clear: '',
            active: false
        },
        {
            name: 'Другое',
            type: 'type',
            value: 'other',
            search: '',
            clear: '',
            active: false
        }
    ])

    const { search } = useLocation();

    useEffect(() => {
        let params = new URLSearchParams()

        // Filters will display in this order in address bar
        if (currentStatus) { params.set('read', currentStatus) }
        if (currentType) { params.set('type', currentType) }
        params.set('favorite', '')

        const new_filters = favoriteFilter.map((filter) => {
            params.set('favorite', filter.value)
            return {
                ...filter, 
                search: params.toString(), 
                clear: getCleanedParams(filter.type, search),
                active: filter.value == currentFavorite
            }
        });

        setFavoriteFilter(new_filters)
    }, [search])


    useEffect(() => {
        let params = new URLSearchParams()
        if (currentStatus) { params.set('read', currentStatus) }
        params.set('type', '')
        if (currentFavorite) { params.set('favorite', currentFavorite) }


        const new_filters = typeFilters.map((filter) => {
            params.set('type', filter.value)
            return {
                ...filter, 
                search: params.toString(), 
                clear: getCleanedParams(filter.type, search),
                active: filter.value == currentType
            }
        });
        setTypeFilters(new_filters)

    }, [search])


    useEffect(() => {
        let params = new URLSearchParams()
        params.set('read', '')
        if (currentType) { params.set('type', currentType) }
        if (currentFavorite) { params.set('favorite', currentFavorite) }

        const new_filters = statusFilters.map((filter) => {
            params.set('read', filter.value)
            return {
                ...filter, 
                search: params.toString(), 
                clear: getCleanedParams(filter.type, search),
                active: filter.value == currentStatus
            }
        });
        setStatusFilters(new_filters)
    }, [search])



    return [typeFilters, statusFilters, favoriteFilter]
}

export default useNotifFilter

function getCleanedParams(type, search) {
    let currentParams = new URLSearchParams(search)
    currentParams.delete(type)
    return currentParams.toString()
}