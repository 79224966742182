import $api from '../utils/AxiosInstance';

export default class StepService {
    static async getSteps(course_id, lesson_number) {
        const params = {
            'course_id': course_id,
            'lesson_number': lesson_number
        }
        return $api.get(`/api/study/get_steps`, { params: params })
    }

    static async getStep(course_id, lesson_number, step_number) {
        const params = {
            'course_id': course_id,
            'lesson_number': lesson_number,
            'step_number': step_number
        }
        console.log('getStep params', params)
        return $api.get(`/api/study/get_step`, { params: params })
    }

    static async finishStep(step_id) {
        return $api.post(`/api/steps/${step_id}/complete`)
    }

    static async markStepWatched(step_id) {
        return $api.post(`/api/steps/${step_id}/watched`)
    }
}