import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import NavbarComp from './components/Navbar/Navbar'
import FooterComp from './components/Footer';
import './main.css';
import { useContext, useEffect } from 'react';
import AuthContext from './context/AuthContext';
import AppRouter from './router/AppRouter';
import BackdropLoader from './components/UI/BackdropLoader/BackdropLoader';
import AuthUpdateChecker from './utils/AuthUpdateChecker';

let didInit = false

function App() {
  let { checkAuth, isAuthLoading } = useContext(AuthContext);
  

  // If there is a token in localStorage, then check it before
  // allowing user open url
  // If no token, then set loading is false and open url
  useEffect(() => {
    // document.documentElement.setAttribute('data-theme', 'dark')
    if (!didInit) {
      didInit = true
      checkAuth();
    }
  }, [])


  return (
    isAuthLoading
    ?
    <BackdropLoader/>
    :
    <BrowserRouter>
      {/* <AuthUpdateChecker /> */}
      <NavbarComp />
          <AppRouter/>
      <FooterComp />
    </BrowserRouter>
  );
}

export default App;

