import React from 'react'
import LoadingButton from '../UI/Button/LoadingButton'
import { IconCheckbox } from '../svg/icons'

function FinishLesson ({lesson, finishLesson, finishLessonLoading}) {
    if (lesson == null) { return null }

    return (    
        <div className="finish-lesson-wrapper">
            <div className='d-flex justify-content-center'>
            <div className="finish-lesson-controls">
                
                <LoadingButton className={'btn btn-success d-flex align-items-center'} 
                    isLoading={finishLessonLoading} 
                    disabled={lesson?.progress?.is_passed} 
                    onClick={finishLesson}
                    loadingText='Обработка...'>
                    <IconCheckbox height={18} className={
                        `${lesson?.progress?.is_passed ? 'finish-lesson-checkbox me-2 checked' : 'finish-lesson-checkbox me-2'}`
                    }/> 
                    Урок выполнен!
                </LoadingButton>
            </div>
            </div>
        </div>
    )
}

export default FinishLesson


{/* <div className="finish-step-wrapper">
    <div className='d-flex justify-content-center'>
    <div className="finish-step-controls">
        <Checkbox
            checked={finishCheckBox}
            onChange={toggleFinishCheckBox}
            className='finish-step-checkbox'
            disabled={step?.progress?.is_passed}
        >
        dddd
        </Checkbox>
        <p style={{paddingRight: '10px'}}>Урок выполнен!</p>
        <Button 
            className='btn btn-success btn-small' 
            onClick={finishClicked}
            disabled={step?.progress?.is_passed}
        >
            Урок выполнен!
        </Button>
    </div>
    </div>
    <HelperText 
        message={'Поставьте галочку для завершения упражнения'}
        className='text-center'
        visible={showCheckBoxError}
        size={'xs'}
        warning
    />
</div> */}