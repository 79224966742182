import React from 'react'
import { FormHelperText } from '@mui/material';

function InputErrorMsg({field, className, visible=true}) {

    if (visible == false || field.messages.length == 0) {
        return null
    }

    return (
        <>
        {field.messages.map(message =>
            <FormHelperText key={message} error className={className}>{message}</FormHelperText>
        )}
        </>
    )
}

export default InputErrorMsg
