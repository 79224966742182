export const StarConfetti = () => (
    <svg width="154" height="125" viewBox="0 0 154 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <path d="M154 114.5C154 120.299 119.526 125 77 125C34.4741 125 0 120.299 0 114.5C0 108.701 34.4741 104 77 104C119.526 104 154 108.701 154 114.5Z" fill="#F0F2FB" fillOpacity="1"/> */}
        <circle cx="33.5142" cy="81.7338" r="3.43265" fill="#FF4B4E"/>
        <circle cx="49.4053" cy="41.9185" r="3.43265" fill="#FFCA54"/>
        <circle cx="96.4672" cy="33.6502" r="3.43265" fill="#00CDB3"/>
        <circle cx="125.507" cy="81.7338" r="3.43265" fill="#57BAE5"/>
        <circle cx="63.0019" cy="16.2788" r="3.43265" fill="#57BAE5"/>
        <path d="M121.898 58.0235C120.197 57.7765 94.3948 54.0272 92.8078 53.7966L79.7982 27.4365C78.606 25.0187 75.1455 25.0167 73.9512 27.4365L60.9416 53.7966L31.8516 58.0235C30.6236 58.2018 29.6035 59.0623 29.2197 60.2425C28.8363 61.4228 29.1562 62.7185 30.0448 63.5847L51.0943 84.1027L46.1252 113.076C45.6699 115.732 48.4672 117.768 50.8558 116.513L76.8749 102.834C105.183 117.716 103.274 116.887 104.411 116.887C106.42 116.887 107.967 115.075 107.624 113.075L102.655 84.1027L123.705 63.5847C125.636 61.7025 124.569 58.4116 121.898 58.0235Z" fill="url(#paint0_linear_1_33)"/>
        <path d="M45.4989 32.3607C43.5274 31.984 40.1073 29.5183 41.271 22.8836C41.6957 20.461 41.1724 20.2626 38.3226 19.6153C35.5379 18.9831 31.3299 18.0274 31.0272 12.1379C30.974 11.1059 31.7678 10.2265 32.7996 10.1733C33.834 10.1192 34.7112 10.9139 34.7639 11.9457C34.9146 14.8753 36.2948 15.3174 39.1509 15.966C41.7165 16.5486 46.0098 17.5237 44.9566 23.5299C44.1578 28.0831 46.1804 28.6811 46.2012 28.6849C47.216 28.879 47.8815 29.8591 47.6879 30.8742C47.4934 31.8904 46.5117 32.5548 45.4989 32.3607Z" fill="#F99830"/>
        <circle cx="34.6514" cy="37.0829" r="2.42945" fill="#57BAE5"/>
        <circle cx="114.961" cy="86.5897" r="2.42945" fill="#00CDB3"/>
        <circle cx="36.8149" cy="101.687" r="2.42968" fill="#FFCA54"/>
        <path d="M108.228 33.9461C107.487 33.2258 107.471 32.0411 108.191 31.3002L110.79 28.626C111.51 27.8852 112.695 27.8683 113.436 28.5886C114.177 29.3089 114.194 30.4936 113.474 31.2345L110.874 33.9087C110.152 34.6502 108.968 34.6653 108.228 33.9461Z" fill="#FF4B4E"/>
        <path d="M57.6464 32.9178L54.1895 31.5199C53.2315 31.1327 52.7687 30.042 53.1562 29.0838C53.5434 28.1258 54.6338 27.663 55.5922 28.0505L59.0496 29.4484C60.0075 29.8356 60.4699 30.9263 60.0827 31.8845C59.6961 32.8413 58.6059 33.3057 57.6464 32.9178Z" fill="#FF4B4E"/>
        <path d="M40.5687 94.3548L37.1114 92.9568C36.1537 92.5696 35.6907 91.4788 36.0783 90.5208C36.4655 89.5625 37.5562 89.1009 38.5144 89.4874L41.9717 90.8856C42.929 91.2726 43.3923 92.3635 43.0048 93.3217C42.6155 94.2842 41.5206 94.7402 40.5687 94.3548Z" fill="#00CDB3"/>
        <path d="M119.474 100.745L116.017 99.3475C115.059 98.9603 114.596 97.8694 114.984 96.9114C115.371 95.9537 116.461 95.4911 117.42 95.8783L120.877 97.276C121.835 97.6632 122.297 98.7541 121.91 99.7121C121.526 100.662 120.442 101.136 119.474 100.745Z" fill="#57BAE5"/>
        <path d="M97.2551 1.54567C97.0754 0.528095 96.1041 -0.150216 95.0868 0.0285516C94.0692 0.208232 93.3898 1.17901 93.5697 2.19659C94.0799 5.0854 92.8343 5.82536 90.1964 7.09796C87.8267 8.24088 83.8612 10.1541 86.235 15.7712C88.0398 20.0436 86.1779 21.0742 86.1779 21.0742C85.6541 21.4215 83.9987 22.0368 84.7055 23.7208C85.9265 26.6315 93.5035 23.3591 89.6813 14.3151C88.7242 12.0496 89.1895 11.7388 91.8222 10.4687C94.3943 9.22764 98.2809 7.35298 97.2551 1.54567Z" fill="#F99830"/>
        <path d="M125.76 28.8089C124.912 28.218 123.746 28.4258 123.155 29.2735C121.786 31.2363 121.52 33.5719 121.286 35.6322C120.882 39.1838 120.73 40.5139 117.018 40.2758C112.93 40.0144 109.875 40.8906 107.938 42.8779C105.551 45.326 105.635 48.703 107.886 48.7406C109.757 48.7719 109.884 46.7781 109.884 46.7781C109.884 46.7781 110.27 43.5975 116.78 44.0105C127.029 44.6632 123.754 34.9571 126.224 31.4137C126.816 30.5662 126.608 29.4 125.76 28.8089Z" fill="#F99830"/>
        <path d="M69.206 50.0345L80.0786 28.0039L79.7985 27.4363C78.6062 25.0185 75.1457 25.0165 73.9514 27.4363L60.9418 53.7964L65.3457 53.0142C65.6555 52.9562 68.019 52.4386 69.206 50.0345Z" fill="url(#paint1_linear_1_33)"/>
        <path d="M53.1482 111.828L57.5733 86.0274C58.0939 82.9877 56.2441 81.0295 56.2382 81.0235L37.0674 62.3368C35.5201 60.8288 35.84 58.2439 37.734 57.1685L31.8514 58.0231C30.6233 58.2014 29.6032 59.0619 29.2194 60.242C28.8361 61.4224 29.156 62.7181 30.0445 63.5843L51.0941 84.1023L46.1251 113.075C45.6699 115.732 48.4672 117.768 50.8557 116.512L54.156 114.778C53.3477 114.035 52.9594 112.927 53.1482 111.828Z" fill="url(#paint2_linear_1_33)"/>
        <defs>
        <linearGradient id="paint0_linear_1_33" x1="29.0601" y1="25.6224" x2="29.0601" y2="116.895" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDC5A"/>
        <stop offset="1" stopColor="#FFBC28"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1_33" x1="53.6651" y1="36.6281" x2="75.1645" y2="55.9922" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFB41A"/>
        <stop offset="1" stopColor="#FF8B0E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1_33" x1="18.1826" y1="80.499" x2="59.6597" y2="106.842" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFB41A"/>
        <stop offset="1" stopColor="#FF8B0E"/>
        </linearGradient>
        </defs>
    </svg>
)