import React, {useEffect, useMemo, useState} from 'react'
import {
    NavLink,
    useLocation
  } from "react-router-dom";

const PostPaginationContext = React.createContext(null);

function PostPagination({pagination, className}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search)

    const [current, setCurrent] = useState(0)
    const [showPrevious, setShowPrevious] = useState(false)
    const [showNext, setShowNext] = useState(false)
    const [showCurrent, setShowCurrent] = useState(false)
    const [showLast, setShowLast] = useState(false)
    const [showPreviousDots, setShowPreviousDots] = useState(false)
    const [showNextDots, setShowNextDots] = useState(false)


    useEffect(() => {
        if (pagination) {
            let _current = pagination.page_number
            setCurrent(_current)
            setShowPrevious(pagination.has_previous && pagination.previous_page_number !== 1)
            setShowNext(pagination.has_next && pagination.next_page_number !== pagination.total_pages)
            setShowCurrent(_current !== 1 && _current !== pagination.total_pages)
            setShowLast(pagination.total_pages > 1)
            setShowPreviousDots(_current > 3)
            setShowNextDots(_current < (pagination.total_pages - 2 ))
        }
    }, [pagination])

    const contextData = useMemo(() => ({
        pagination:pagination, 
        current:current, 
        url:location.pathname, 
        pathname: location.pathname,
        location: location,
        params: params
    }), [pagination, current])

    // const current = pagination.page_number
    // const showPrevious = pagination.has_previous && pagination.previous_page_number !== 1
    // const showNext = pagination.has_next && pagination.next_page_number !== pagination.total_pages
    // const showCurrent = current !== 1 && current !== pagination.total_pages
    // const showLast = pagination.total_pages > 1
    // const showPreviousDots = current > 3
    // const showNextDots = current < (pagination.total_pages - 2 )

    // let contextData = {
    //     pagination:pagination, 
    //     current:current, 
    //     url:url, 
    //     pathname: location.pathname,
    //     location: location,
    //     params: params
    // }

    let classNames = ['pagination']
    if (className) { classNames =  [classNames, className].join(' ')}

    return (
        pagination
        ?
        <PostPaginationContext.Provider value={contextData}>
        <div className={classNames}>
            <GoPrevious />
            <PaginationButton number={1}/>
            {showPreviousDots && <span className="page-numbers dots">…</span>}
            {showPrevious && <PaginationButton number={pagination.previous_page_number}/>}
            {showCurrent && <PaginationButton number={current}/>}
            {showNext && <PaginationButton number={pagination.next_page_number}/>}
            {showNextDots && <span className="page-numbers dots">…</span>}
            {showLast && <PaginationButton number={pagination.total_pages}/>}
            <GoNext />
        </div>
        </PostPaginationContext.Provider>
        :
        null
    )
}

export default PostPagination


const GoPrevious = () => {

    let { pagination, pathname, location, params } = React.useContext(PostPaginationContext);

    if (!pagination.has_previous) {
        return <NavLink className='page-numbers prev-btn disabled-link' to={location}>Назад</NavLink>
    }

    let previous_page = pagination.previous_page_number


    if (previous_page == 1) { params.delete('page') }
    else { params.set('page', previous_page) }

    let previous_url = {pathname: pathname, search: params.toString()}

    return (
        <NavLink className='page-numbers prev-btn' to={previous_url}>Назад</NavLink>
    )
}

const GoNext = () => {

    let { pagination, pathname, location, params } = React.useContext(PostPaginationContext);

    if (!pagination.has_next) {
        return <NavLink className='page-numbers next-btn disabled-link' to={location}>Вперед</NavLink>
    }

    let next_page = pagination.next_page_number
    params.set('page', next_page)
    let next_url = {pathname: pathname, search: params.toString()}

    return (
        <NavLink className='page-numbers next-btn' to={next_url}>Вперед</NavLink>
    )
}

const PaginationButton = ({number}) => {

    const location = useLocation()

    let { current, pathname, params } = React.useContext(PostPaginationContext);

    if (number === current){
        return (
            <span className='page-numbers current'>{number}</span>
        )
    }

    if (number == 1) {
        params.delete('page')
    } else {
        params.set('page', number)
    }

    let url = {pathname: pathname, search: params.toString(), state: location.state}

    return (
        
        <NavLink className='page-numbers' to={url}>{number}</NavLink>
        // <NavLink className='page-numbers' to={url}>{number}</NavLink>

    )
}
