import React, { useEffect, useContext, useState } from 'react'
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import NavMenu from 'components/UI/NavMenu/NavMenu';
import NavMenuMobile from 'components/UI/NavMenu/NavMenuMobile';
import { useToggle } from 'hooks/useToggle';
import AuthContext from 'context/AuthContext';
import { OptionsIcon } from 'components/svg/icons'
import { IconButton } from '@mui/material';
import NavMenuBlock from 'components/UI/NavMenu/NavMenuBlock';
import NeedHelp from './NeedHelp';
import LinkBlocks from './LinkBlocks';
import SubHead from 'components/UI/SubHead';

function HelpPage() {
    const { user, isAuth } = useContext(AuthContext);
    const [showMobileMenu, toggleMobileMenu] = useToggle(false)
    const { pathname } = useLocation()

    return (
        <Container className="d-flex flex-column flex-grow-1 pt-3">
        <Row className="two-column-row">
            <Col xs={12} md={3} className="px-0 mx-0 pe-md-4">
                <NavMenu title={'Помощь'}>
                    <HelpLinks className={'nav-menu-item'}/>
                </NavMenu>
            </Col>

            <Col xs={12} md={9} className="px-0 mx-0 d-flex flex-column flex-grow-1">
                <Routes>
                    {LinkBlocks.map(block => (
                        block.links.map(link => (
                        <Route 
                            path={`${block.link}/${link.to}`}
                            key={link.to}
                            element={<HelpSection link={link} toggleMobileMenu={toggleMobileMenu}/>}
                        />
                        ))
                    ))}
                    <Route path={``} element={<NeedHelp />} />
                </Routes>
            </Col>
        </Row>
        <NavMenuMobile title={'Мой аккаунт'} isOpen={showMobileMenu} setOpen={toggleMobileMenu}>
            <HelpLinks className={'nav-menu-item-mobile'}/>
        </NavMenuMobile>
        </Container>
    )
}

export default HelpPage

const HelpSection = ({link, toggleMobileMenu}) => (
    <>
        {/* <div className="subhead mobile-only container">
            <h2 className="subhead-title">{link.title}</h2>
            <IconButton onClick={toggleMobileMenu}>
                <OptionsIcon className='icon subhead-mobile-menu-btn'/>
            </IconButton>
        </div> */}
        <SubHead title={link.title} toggleMobileMenu={toggleMobileMenu}/>
        <link.component/>
    </>
    
)

const HelpLinks = ({className}) => {
    return (
        <>
        <NavLink to={``} className={className} end>Нужна помощь?</NavLink>
        {LinkBlocks.map(block => (
            <NavMenuBlock key={block.link} block={block} className={className}/>
        ))}
        </>
    )
}