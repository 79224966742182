import { FormatColorReset } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const $api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
		'Accept-Language': 'ru-RU',
	}
})

$api.interceptors.request.use((config) => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        console.log('Found token')
        // Found token
    } else {
        // NO token found
        console.log('NO token found')
    }
    return config;
})

$api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    const from = window.location.pathname


    if (error.response.status === 401 && error.config && !error.config._isRetry) {

        originalRequest._isRetry = true;
        originalRequest._SomeThing = 5;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/auth/token/refresh/`, {}, {withCredentials: true});
            localStorage.setItem('token', response.data.access);
            return $api.request(originalRequest);
        } catch (e) {
            // Not logged in
            const regex = /Bearer (.*)/;
            const match = originalRequest.headers.Authorization.match(regex);
            if (match) {

                const oldAccessToken = match[1];
                if (oldAccessToken != localStorage.getItem('token')) {
                    originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
                    return $api.request(originalRequest);
                } else {
                }
                // setTimeout(() => {
                //     if (oldAccessToken != localStorage.getItem('token')) {
                //         console.log('New token found. Change Token and Retry')
                //         originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
                //         return $api.request(originalRequest);
                //     } else {
                //         console.log('Old Token Invalid. No New Token Found')
                //     }
                // }, 2000)
            }


            // localStorage.removeItem('token');
            // window.history.pushState({ from: from }, "", "/login");
            // window.location.reload();
        }
    }
    throw error;
})

export default $api;

export const $static = axios.create({
    baseURL: process.env.REACT_APP_STATIC_URL
})