import { useEffect ,useState } from "react"


function EquipmentInfo({tags, equipment}) {
    const [tagsString, setTagsString] = useState('')
    const [equipmentString, setEquipmentString] = useState('')

    useEffect(() => {
        setTagsString(tags.map(tag => tag.name).join(', '))
        setEquipmentString(equipment.map(unit => unit[0]).join(', '))
    }, [tags, equipment])

    return (
        <div className="course-banner-equipment">
            <p>Тип:&nbsp; {tagsString}</p>
            <p>Снаряжение: <span>{equipmentString}</span></p>
        </div>
    )
}

export default EquipmentInfo