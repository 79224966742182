import { useRef, useEffect } from 'react';
import './Modal.css'
import Loader from '../Loader/Loader';

function Modal({children, isOpen, setOpen, className, showCloseBtn=true, showLoader=false}) {

    const modalRef = useRef(null);
    const wrapperClassName = className ? `modal-wrapper ${className}` : `modal-wrapper`;

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-lock-open');
        }
        return () => {
            document.body.classList.remove('modal-lock-open');
        }
    }, [isOpen])

    if (!isOpen) { return null }


    return (
        <>
            <div className={wrapperClassName} ref={modalRef}>
                {showCloseBtn && <CloseButton action={() => setOpen(false)}/>}
                {showLoader && <Loader background={true} position={"absolute"}/>}
                {children}
            </div>
            <div className='modal-background' onClick={() => setOpen(false)}/>
        </>
    )
}

export default Modal

const CloseButton = ({action}) => (
    <button className='modale-close-btn' onClick={action}><CloseIcon /></button>
)


const CloseIcon = () => (
    <svg aria-hidden="true" width="30px" height="30px" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
    </svg>
)
