import React, { useState, useEffect, useMemo } from 'react'
import { useFetching } from '../../hooks/useFetching'
import UserService from '../../services/UserService'
import DOMPurify from 'dompurify'
import { IconButton } from '@mui/material';
import { updateItemInArray } from '../../utils/Array';
import { useNamedQuery } from '../../hooks/useQuery';
import NotifsPagination from './NotifsPagination';
import { useLocation } from 'react-router-dom';
import Loader from '../UI/Loader/Loader'
import SubHead from 'components/UI/SubHead';

function Notifications({toggleMobileMenu}) {
    const [notifs, setNotifs] = useState([])
    const [pagination, setPagination] = useState(null)
    const { search } = useLocation();
    const [minHeight, setMinHeight] = useState(null)


    let currentFavorite = useNamedQuery('favorite')
    let currentStatus = useNamedQuery('read')
    let currentType = useNamedQuery('type')
    let currentPage = useNamedQuery('page')

    const header = document.getElementById('header')
    const footer = document.getElementById('footer')
    const mainContainer = document.getElementById('main-container')

    let headerHeight = header?.offsetHeight;
    let footerHeight = footer?.offsetHeight;


    let pageHeight = window.innerHeight;

    const showNotifs = useMemo(() => notifs.length > 0, [notifs])

    useEffect(() => {
        if (headerHeight && footerHeight && mainContainer) {
            let style = mainContainer.currentStyle || window.getComputedStyle(mainContainer)

            let containerSpacing = parseInt(style.marginTop) + parseInt(style.paddingTop)
            setMinHeight(pageHeight - headerHeight - footerHeight - containerSpacing)
        }
    }, [headerHeight, footerHeight, mainContainer, pageHeight])


    useEffect(() => {
        fetchNotifs()
    }, [search])


    const [fetchNotifs, notifsLoading, notifsError] = useFetching(async () => {
        let param = {}
        param['order_by'] = '-date_created'
        param['page_size'] = '10'
        if (currentFavorite) {param['favorite'] = currentFavorite}
        if (currentStatus) {param['read'] = currentStatus}
        if (currentType) {param['type'] = currentType}
        if (currentPage && currentPage > 1) {param['page'] = currentPage}

        const response = await UserService.getNotifications(param)
        setNotifs(response.data.notifications)
        setPagination(response.data.pagination)
    })

    const [readToggle, readToggleLoading, readToggleError] = useFetching(async (notif_id) => {
        const response = await UserService.readNotification(notif_id)
        updateItemInArray(response.data, notifs, setNotifs)
    })

    const [favoriteToggle, favoriteToggleLoading, favoriteToggleError] = useFetching(async (notif_id) => {
        const response = await UserService.favoriteNotification(notif_id)
        updateItemInArray(response.data, notifs, setNotifs)
    })


    return (
        <>
        <SubHead title={'Уведомления'} toggleMobileMenu={toggleMobileMenu}/>
        <div className='notifications-wrapper'>
            {notifsLoading
                ?   <Loader />
                :
                    <div className='notifications-list'>
                        {showNotifs &&
                            notifs.map(notif => (
                                <NotifItem key={notif.id} notif={notif} readToggle={readToggle} favoriteToggle={favoriteToggle}/>
                            ))
                        }
                        {!showNotifs &&
                            <p>Нет уведомлений</p>
                        }
                    </div>
            }
            
            {pagination &&
                <NotifsPagination pagination={pagination}/>
            }
        </div>
        </>
    )
}

export default Notifications

const NotifItem = ({notif, readToggle, favoriteToggle}) => {
    return (
        <div className='notification-card'>
            <IconButton className='me-md-3' onClick={() => readToggle(notif.id)}>
                <CircleIcon className={notif.is_read ? 'notification-read' : 'notification-not-read'}/>
            </IconButton>

            <div className='notification-message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notif.text), }}/> 
            <IconButton className='ms-md-1 me-md-2 notification-favorite-btn' onClick={() => favoriteToggle(notif.id)}>
                <StarIcon className={notif.is_favorite ? 'notification-favorite' : 'icon'}/>
            </IconButton>
        </div>
    )
}

const CircleIcon = ({className}) => (
    <svg width="16" height="16" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={className} d="M11.1651 21.1009C16.688 21.1009 21.1651 16.6238 21.1651 11.1009C21.1651 5.57806 16.688 1.10091 11.1651 1.10091C5.64228 1.10091 1.16513 5.57806 1.16513 11.1009C1.16513 16.6238 5.64228 21.1009 11.1651 21.1009Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

const StarIcon = ({className}) => (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={className} d="M11.1651 1.10091L14.2551 7.36091L21.1651 8.37091L16.1651 13.2409L17.3451 20.1209L11.1651 16.8709L4.98513 20.1209L6.16513 13.2409L1.16513 8.37091L8.07513 7.36091L11.1651 1.10091Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)


