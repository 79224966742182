import React from 'react'
import Button from './Button'
import ButtonProgressIndicator from './ButtonProgressIndicator'

function LoadingButton({ children, onClick, className, isLoading=false, loadingText, circleSize, type='button', disabled}) {

    return (
        <Button className={className} onClick={onClick} type={type} disabled={isLoading || disabled}>
            {!isLoading && children}
            {isLoading && <ButtonProgressIndicator text={loadingText} circleSize={circleSize}/>}
        </Button>
    )
}

export default LoadingButton
