import { useContext } from 'react'
import 'styles/pages/CoursePage.css'
import CourseService from 'services/CourseService';
import AuthContext from 'context/AuthContext';
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useFetching } from 'hooks/useFetching';
import Button from 'components/UI/Button/Button';
import { Container } from 'react-bootstrap';
import DOMPurify from 'dompurify'
import EquipmentInfo from './EquipmentInfo';
import AboutMeSection from 'components/AboutMeSection';
import Reviews from './Reviews';
import FAQ from './FAQ';
import YourResults from './YourResults';
import ToWhom from './ToWhom';
import Equipment from './Equipment';
import Preview from './Preview';
import PurchaseModal from './PurchaseModal';


function CoursePage() {
    let { isAuth, user } = useContext(AuthContext);
    let { state, pathname } = useLocation()
    const [course, setCourse] = useState(null);
    const courseMobileMenu = useRef(null);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false)
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const [fetchCourseBySlug] = useFetching(async (slug) =>{
        const params = new URLSearchParams();
        params.append('scope', 'access,progress,web_page');
        const response = await CourseService.getCourse(slug, params);
        setCourse(response.data)
        console.log('setCourse', response.data)
    }, null, 'fetchCourseBySlug')


    const [extendCourse, extendCourseLoading, extendCourseError] = useFetching(async () => {
        let formData = new FormData
        formData.append('course', course.id)
        const response = await CourseService.extendCourse(formData);
        console.log('extendCourse', response.data)
        setCourse({...course, access: response.data})
    }, null, 'extendCourse')


    const toggleMobileMenu = () => {
        courseMobileMenu.current.classList.toggle('open')
    }

    useEffect(() => {
        fetchCourseBySlug(params.slug)
        const script = document.createElement("script");
        script.src = "https://test-epay.homebank.kz/payform/payment-api.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [])

    if (course === null) { return null }

    return (
        <>
        <section className='course-banner-section' /*</>style={{backgroundImage: `url(${course.banner})`}}*/>
            <Container>
                <div className='course-banner-content'>
                    {course.duration &&        
                        <div className='course-banner-time'>
                            {course.duration &&
                                <div className='course-banner-days'>
                                    <i className="fa-solid fa-calendar"></i>&nbsp;<span>{course.duration} дней</span>
                                </div>
                            }
                            {course.min_time &&
                                <div className='course-banner-minutes'>
                                    <i className="fa-solid fa-clock"></i>
                                    &nbsp;
                                    <span>{[course.min_time, course.max_time].filter(Boolean).join('-')} минут в день</span>
                                </div>
                            }
                        </div>
                    }
                    <div className='course-banner-title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(course.web_title)}}></div>
                    <div className='course-banner-info'>
                        <p className='course-banner-description'>{course.description}</p>
                        <EquipmentInfo tags={course.tags} equipment={course.equipment}/>
                    </div>
                    <div className='course-buy-btn-wrapper'>
                        <div className='d-flex flex-column'>
                            <StudyOrBuyButton course={course} isAuth={isAuth}/>
                            <p className='text-center'>Доступ на 60 дней</p>
                        </div>                 
                    </div>
                </div>
                <div className='course-banner-img-wrapper'>
                    <img className='course-banner-img' src={course.banner_image} alt={course.title}></img>
                </div>
            </Container>
            <div className='course-banner-mobile-wrapper' ref={courseMobileMenu}>
                <div className='course-banner-mobile-content'>
                    <StudyOrBuyButton course={course} isAuth={isAuth}/>
                    <div className='course-banner-mobile-expandable'>
                        <div className='course-banner-time-mobile'>
                            <div className='course-banner-days'>
                                <i className="fa-solid fa-calendar"></i>&nbsp;<span>{course.duration} дней</span>
                            </div>
                            <div className='course-banner-minutes'>
                                <i className="fa-solid fa-clock"></i>&nbsp;
                                <span>{[course.min_time, course.max_time].filter(Boolean).join('-')} минут в день</span>
                            </div>
                        </div>
                        <EquipmentInfo tags={course.tags} equipment={course.equipment}/>
                    </div>
                    <div className='expand-icon-wrapper' onClick={toggleMobileMenu}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </div>
            </div>
        </section>
        <ToWhom itemsArray={course.to_whom_text} imageUrl={course.to_whom_image}/>
        <section className="course-about-study-section">
            <Container>
                <h2 className="section-title">Как проходит <span className="text-color-theme">обучение</span></h2>
                <div className="course-about-study-wrapper">
                    <div className='course-about-study-img-wrapper'>
                        <img className='course-about-study-img-pc' src="/images/course-iphone.png" alt="Как проходит обучение"/>
                    </div>
                    <div className='about-course-study-content'>
                        <p>Регистрируйтесь на сайте и оплачиваете курс удобным вам способом</p>
                        <p>Получаете мгновенный доступ к обучающий материалам</p>
                        {/* <p>Заходите в личный кабинет с любого устройства</p> */}
                        <p>Открываете тренировку в любое время и с любого устройства</p>
                        <p>Получаете поддержку от тренера через комментарии и WhatsApp</p>
                        <p>Радуйтесь результатам</p>
                    </div>
                </div>
                <img className='course-about-study-img-mobile' src="/images/iphone-ipad.png" alt="Как проходит обучение"/>
            </Container>
        </section>
        <YourResults resultsArray={course.results_text} resultsImage={course.results_image}/>
        <Preview videosSource={course.preview}/>
        <Equipment equipmentArray={course.equipment}/>
        <Reviews />
        <section className="contraindications-section">
            <Container>
                <div className='contraindications-wrapper'>
                    <h2 className="section-title"><span className="text-color-theme">Противопоказания</span></h2>
                    <div className='contraindications-content'>
                        {course.contraindications.map(contraindication => (
                            <div key={contraindication}>
                                <p dangerouslySetInnerHTML={{ __html: contraindication }}></p>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </section>
        <AboutMeSection />
        <FAQ questions_answers={course.faq}/>
        <PurchaseModal isOpen={showPurchaseModal} setOpen={setShowPurchaseModal} course={course} isAuth={isAuth} user={user}/>
        </>
    )
}

export default CoursePage


const StudyOrBuyButton = ({course, isAuth}) => {
    let linkText;
    let linkUrl;

    if (course.access) {
        if (course.access.is_expired == false) {
            linkText = 'Учиться'
            linkUrl = `/study/${course.progress.continue_url}`
        } else {
            linkText = `Продлить за ${course.extend_price} ₸`
            linkUrl = `/checkout/${course.slug}`
        }
    } else {
        if (!isAuth && course.course_type == "standard") {
            linkUrl = `/signup?to=/checkout/${course.slug}`
        } else {
            linkUrl = `/checkout/${course.slug}`
        }
        linkText = `Купить за ${course.price} ₸`
    }

    return <Link className={'btn btn-success btn-buy-course'} to={linkUrl}>{linkText}</Link>
}