import { useEffect } from 'react'
import React from 'react'
import getFacebookURL from './getFacebookURL';

function GetFacebookAuthCode() {
    const state = Math.random().toString(36).substring(7);
    const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID
    const scope = 'public_profile'
    const responseType = 'token'
    const urlSearchParams = new URLSearchParams(window.location.search)
    const socketID = urlSearchParams.get('socket')
    const redirectUri = window.location.origin + `/auth/social/facebook/callback?socket=${socketID}`

    useEffect(() => {
        localStorage.socialState = state;
        localStorage.socialRedirectUri = redirectUri;
        window.location.href = getFacebookURL({ clientId, state, scope, responseType, redirectUri });
    }, [])

    return (
        <></>
    )
}

export default GetFacebookAuthCode