import $api from '../utils/AxiosInstance';

export default class UserService {

    static async updateBasicInfo(formData) {
        return $api.put('/api/users/update_info/', formData)
    }

    static async updateAvatar(formData) {
        return $api.put('/api/users/update_avatar/', formData)
    }

    static async getEmails() {
        return $api.get('/api/users/user_emails/')
    }

    // Actions: action_add, action_primary, action_send, action_remove
    static async emailAction(formData) {
        return $api.post('/api/users/user_emails/', formData)
    }

    // Change current password
    static async changePassword(formData) {
        return $api.post('/api/users/auth/password/change/', formData)
    }

    // Reset password
    static async resetPassword(formData) {
        return $api.post('/api/users/password/reset/confirm/', formData)
    }

    // Send password reset email
    static async sendResetEmail(formData) {
        return $api.post('/api/users/password/reset/', formData)
    }

    // Get all user notifications or by type (course, comments, other, etc)
    static async getNotifications(param) {
        return $api.get('/api/notifications', { params: param })
    }

    static async getNotificationsUnreadCount() {
        return $api.get('/api/notifications/count', {})
    }

    // Toggle notification Read|Unread status
    static async readNotification(id) {
        return $api.post(`/api/notifications/${id}/read`, {})
    }

    // Toggle notification Favorite status
    static async favoriteNotification(id) {
        return $api.post(`/api/notifications/${id}/favorite`, {})
    }

    // Get all Subscription Types
    static async getSubscriptionTypes() {
        return $api.get(`/api/subscriptions`, {})
    }

    // Get all user Subscriptions
    static async getUserSubscriptions() {
        return $api.get(`/api/subscriptions/user/list`, {})
    }

    // Change user Subscriptions
    static async changeSubscriptions(formData) {
        return $api.put(`/api/subscriptions/user/modify`, formData)
    }

    // Cancel all user Subscriptions
    static async cancelAllSubscriptions(unsub_uuid) {
        return $api.put(`/api/subscriptions/user/unsub_all/${unsub_uuid}`, {})
    }

    // Get public user info by user ID
    static async getPublicInfo(id) {
        return $api.get(`/api/users/user_public_info/${id}`, {})
    }

    // Get user mention info by user ID
    static async getMentionInfo(id) {
        return $api.get(`/api/users/user_mention_info/${id}`, {})
    }

    // Get user social links
    static async getSocialLinks() {
        return $api.get(`/api/users/social_links/`, {})
    }

    // Update user social links
    static async updateSocialLinks(formData) {
        return $api.put(`/api/users/social_links/`, formData)
    }
}