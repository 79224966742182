function EnergyValues({recipe}) {
    return (
        <div className='energy-values'>
            <h3 className='mb-3 text-center'>Энергетическая ценность на порцию</h3>
            <div className='energy-values-table'>
                <div className='energy-values-item'>
                    <div className='energy-values-name'>КАЛОРИЙНОСТЬ</div>
                    <div className='energy-values-quantity'>{recipe.calories}<span className='unit-mobile'>&nbsp;ККАЛ</span></div>
                    <div className='unit-pc'>ККАЛ</div>
                </div>
                <div className='energy-values-item'>
                    <div className='energy-values-name'>БЕЛКИ</div>
                    <div className='energy-values-quantity'>{recipe.proteins}<span className='unit-mobile'>&nbsp;ГРАММ</span></div>
                    <div className='unit-pc'>ГРАММ</div>
                </div>
                <div className='energy-values-item'>
                    <div className='energy-values-name'>ЖИРЫ</div>
                    <div className='energy-values-quantity'>{recipe.fat}<span className='unit-mobile'>&nbsp;ГРАММ</span></div>
                    <div className='unit-pc'>ГРАММ</div>
                </div>
                <div className='energy-values-item'>
                    <div className='energy-values-name'>УГЛЕВОДЫ</div>
                    <div className='energy-values-quantity'>{recipe.carbohydrates}<span className='unit-mobile'>&nbsp;ГРАММ</span></div>
                    <div className='unit-pc'>ГРАММ</div>
                </div>
            </div>
        </div>
    )
}

export default EnergyValues