import Container from 'components/UI/Container'

function PublicOffer() {
    return (
        <Container>
            <div className='docs'>
                <div className='docs-section'>
                    <p>20 июня 2023 г.</p>
                    <p>Настоящий договор является официальным предложением (публичной офертой) ТОО MaddyFit (БИН: 220340008615), именуемого в дальнейшем «Исполнитель» для лица, которое в полном объеме и безоговорочно примет настоящее предложение на указанных ниже условиях, именуемого в дальнейшем «Заказчик».</p>
                    <p>Оплата услуг подтверждает, что Заказчик ознакомился с нижеизложенными условиям и принял их. После оплаты услуг договор считается заключенным на условиях, содержащихся в Оферте.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>1.	Термины и определения</h4>
                    <p>1.1.	Для целей Договора приведенные ниже термины и определения используются в следующем значении:</p>
                    <p>«Оферта» — публичное предложение Исполнителя, адресованное любому физическому лицу или юридическому лицу (Заказчику), заключить с ним договор на изложенных в настоящей Оферте условиях, а также на основании ознакомления с иными правилами работы платформы, представленным на сайте https://maddy.fit.</p>
                    <p>«Акцепт» — полное и безоговорочное принятие Заказчиком условий Оферты. Оплата Заказчиком выбранного формата доступа к Курсам платформы, оформленная на сайте https://maddy.fit означает согласие Заказчика с условиями настоящей Оферты.</p>
                    <p>«Курс» – совокупность Обучающих материалов, вебинаров и услуг по предоставлению обратной связи, объединенных единой тематикой.</p>
                    <p>«Обучающие материалы» – уроки, методические материалы, вебинары, используемые Исполнителем в процессе оказания услуг по настоящему Договору-оферте.</p>
                    <p>«Сайт» — совокупность данных, доступных для просмотра в сети Интернет на домене https://maddy.fit и его поддменах.</p>
                    <p>«Исполнитель» — ТОО MaddyFit, зарегистрированный в установленном порядке на территории Республики Казахстан, осуществляющий реализацию информационных услуг с помощью сети Интернет.</p>
                    <p>«Заказчик» — лицо, заключившее с Исполнителем Договор на условиях Оферты, на возмездное оказание Исполнителем информационно-консультационных услуг путем проведения курса в форме видео-тренировок, лекций, персональных консультаций, мастер-классов, методических пособий.</p>
                    <p>«Личный кабинет Заказчика» – аккаунт на платформе MaddyFit</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>2.	Общие положения</h4>
                    <p>2.1.	Предметом Оферты является возмездное предоставление Заказчику доступа к набору заранее записанных видео-тренировок, лекций, персональных консультаций, мастер-классов, методических пособий.</p>
                    <p>2.2.	Совершая действия по акцепту настоящего публичного Договора-оферты, Заказчик подтверждает свою правомерность, полномочия, дееспособность, достижение возраста 18 лет, а также законное право вступать в договорные отношения с Исполнителем.</p>
                    <p>2.3.	Совершая действия по акцепту настоящего Договора-оферты, Заказчик дает согласие на обработку (сбор, хранение, передачу третьим лицам) предоставляемых им в процессе заключения и исполнения настоящего Договора-оферты персональных данных (имени, фамилии, отчества, номера телефона, адреса электронной почты) в соответствии с Политикой обработки персональных данных, текст которой постоянно размещен в информационно-телекоммуникационной сети Интернет по адресу https://maddy.fit/help/agreements/privacy-policy.</p>
                    <p>2.4.	Видео – тренировки, любая информация и иные материалы, в том числе на электронных носителях, аудио и видеозаписи, предоставленные Заказчику Исполнителем в рамках оказания Услуг по настоящему Договору, предоставляются исключительно для личного использования Заказчиком.</p>
                    <p>2.5.	Все информационные материалы, предоставляемые Исполнителем Заказчику в процессе проведения Курсов, а также результаты фото- и видеосъемки, полученные Исполнителем во время проведения Курсов, являются результатом интеллектуальной деятельности, исключительное и авторское право, в том числе смежные с авторским права принадлежат Исполнителю.</p>
                    <p>2.6.	Стоимость, перечень, содержание, сроки, место и порядок оказания Услуг указаны на Сайте Исполнителя. Оказание услуг по каждому последующему Курсу осуществляется на основании данного Договора-оферты при первом акцепте Договора-оферты, Заказчик акцептует Договор на определенный срок в соответствии с выбранным форматом доступа.</p>
                    <p>2.7.	Настоящий договор имеет силу акта об оказании слуг. Приемка производится без подписания соответствующего акта.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>3.	Стоимость и порядок оказания услуг</h4>
                    <p>3.1.	Стоимость Услуг определяется Исполнителем в одностороннем порядке в тенге Республики Казахстан.</p>
                    <p>3.2.	Исполнитель оказывает Услуги Заказчику только при выполнении следующих условий:</p>
                    <p>3.2.1.	заказчик отправил свои регистрационные данные и предоставил Исполнителю согласие на их обработку;</p>
                    <p>3.2.2.	заказчик осуществил Акцепт Оферты, оплатив Услуги Исполнителя в порядке 100% предоплаты;</p>
                    <p>3.3.	Моментом оплаты считается поступление денежных средств на расчетный счет Исполнителя.</p>
                    <p>3.4.	При совершении оплаты Заказчиком любого из доступов к Курсам Заказчик подтверждает полное и безоговорочное принятие условий настоящей Оферты.</p>
                    <p>3.5.	Осуществляя Акцепт Оферты, Заказчик подтверждает, что оказание Исполнителем Услуг дистанционно с использованием программного обеспечений полностью соответствует возможностям Заказчика пользоваться Услугами, оказываемыми таким способом.</p>
                    <p>3.6.	Услуга предоставляется Заказчику одним из следующих способов:</p>
                    <p>3.6.1.	доступ к видео-тренировкам и лекциям предоставляется в Личном кабинете Заказчика после поступления платежа за соответствующий в течение 1 (одного) календарного дня;</p>
                    <p>3.6.2.	доступ к материалам требующих заполнения анкеты осуществляется путем отправки материалов на почтовый ящик, указанный при оплате в течение 3 (трех) календарных дней после поступления платежа;</p>
                    <p>3.7.	Срок доступа к обучающим материалам указан на странице описания курса и начинается с даты начала оказания услуги. По истечении данного срока доступ к Обучающим материалам Курса прекращается.</p>
                    <p>3.8.	Данные Услуги носят информационных характер, не являются образовательной деятельностью или медицинской услугой, не подлежат лицензированию, не сопровождаются проведением итоговой аттестации, присвоения какой-либо квалификации и выдачей документа об образовании.</p>
                    <p>3.9.	Заказчик самостоятельно осваивает видео-тренировки, к которым он получил доступ, без проверки качества их выполнения или степени усвоения информации, если иное не указано в описании соответствующего Курса.</p>
                    <p>3.10.	Никакая информация, материалы и / или консультации, предоставляемые Исполнителем в рамках оказания услуг по договору, не могут рассматриваться как гарантии конкретного результата.</p>
                    <p>3.11.	Принятие решений на основе всей предоставленной Исполнителем информации находится в исключительной компетенции Заказчика. Заказчик принимает на себя полную ответственность и риски, связанные с использованием информации и материалов, предоставленных Исполнителем в рамках исполнения своих обязательств по договору.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>4.	Приемка услуг и возврат денежных средств</h4>
                    <p>4.1.	Услуга считается оказанной Исполнителем с момента предоставления доступа Заказчику посредством личного кабинета в Платформе к оплаченным курсам или отправки материалов на почтовый ящик, в зависимости от формата курса.</p>
                    <p>4.2.	Заказчик вправе потребовать возврат 100% уплаченной Исполнителю стоимости Обучающего курса в течение первых 5 (пяти) дней обучения по любым причинам. Для этого Заказчик обязан направить на адрес электронной почты Исполнителя info@maddy.fit с адреса электронной почты, указанной им при регистрации на Сайте Исполнителя Уведомление об одностороннем отказе от исполнения Договора-оферты.</p>
                    <p>4.3.	В случае отсутствия в течение 5 (пяти) дней после оказания услуг по настоящему Договору отказа Заказчика от принятия услуг, услуги считается автоматически принятыми Заказчиком без замечаний и оплаченные Исполнителю денежные средства возврату не подлежат.</p>
                    <p>4.4.	С момента, когда услуги считаются оказанными в полном объеме и без замечаний, возврат денежных средств не производится.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>5.	Права и обязанности сторон</h4>
                    <p>5.1.	Исполнитель обязуется:</p>
                    <p>5.1.1.	Оказывать оплаченные Заказчиком услуги надлежащим образом и в полном объеме в соответствии с условиями настоящего Договора-оферты.</p>
                    <p>5.1.2.	Осуществлять консультационную поддержку относительно предоставляемых услуг, порядка и правил регистрации и оплаты по электронной почте или в личном кабинете Платформы.</p>
                    <p>5.1.3.	Вернуть Заказчику ошибочно повторно оплаченные денежные средства за Курс по письменному требованию Заказчика.</p>
                    <p>5.2.	Исполнитель имеет право:</p>
                    <p>5.2.1.	Изменять содержание и стоимость Курсов без предварительного согласования с Заказчиком. Исполнитель уведомляет Заказчика об указанных изменениях путем размещения информации на сайте Исполнителя по адресу страницы Курса и при этом Заказчик обязан самостоятельно знакомиться с указанными изменениями. Продолжая использование Сайта, предоставленных Исполнителем материалов Заказчик выражает свое согласие с новыми условиями.</p>
                    <p>5.2.2.	Прекратить Заказчику доступ частично или полностью без права на возврат денежных средств (удерживаются в качестве штрафной санкции) в случае нарушения Заказчиком требований Оферты, в том числе, но не ограничиваясь: использование Заказчиком ненормативной лексики в период потребления услуг или участия онлайн-вебинаре, общие призывы к недоверию Исполнителю в сети Интернете, оскорбление подрядчиков Исполнителя, попытки хакерских атак, факт нарушения Заказчиком исключительных авторских прав Исполнителя.</p>
                    <p>5.2.3.	Закрыть доступ к сайту Исполнителя для внесения изменений и устранения технических неполадок.</p>
                    <p>5.2.4.	Привлекать к оказанию Услуг третьих лиц без предварительного получения на то согласия Заказчика.</p>
                    <p>5.3.	Заказчик обязуется:</p>
                    <p>5.3.1.	Самостоятельно и своевременно знакомиться с информацией о Курсах до момента оплаты Курсов, а также с изменениями указанных условий, с актуальной редакцией Договора при каждом посещении Сайта, в том числе и после акцепта Оферты.</p>
                    <p>5.3.2.	При регистрации заполнить необходимые обязательные поля и указать в них достоверную информацию.</p>
                    <p>5.3.3.	Соблюдать на Платформе Курса порядок и дисциплину как при регистрации, так и при проведении Курса, не создавать своими действиями неудобства для других Заказчиков, а также не мешать процессу обучения на Платформе Курса. При нарушении условий настоящего пункта Исполнитель оставляет за собой право не допустить Заказчика к участию в Курсе Платформы или удалить Заказчика с площадки, на которой проводится Курс, при этом денежные средства, выплаченные Заказчиком за Курс, не возвращаются и считаются неустойкой, подлежащей оплате Исполнителю в связи с нарушением Заказчиком принятых на себя обязательств.</p>
                    <p>5.3.4.	Заказчик обязан соблюдать правила и механику использования Платформы, предусмотренные в личном кабинете Платформы.</p>
                    <p>5.4.	Заказчик не вправе:</p>
                    <p>5.4.1.	Обходить технические ограничения, установленные на Платформе или Сайте.</p>
                    <p>5.4.2.	Изучать технологию, декомпилировать или дизассемблировать Сайт, Интеллектуальную собственность, любые материалы, доступ к которым получает Заказчик в связи с исполнением настоящего Договора-оферты.</p>
                    <p>5.4.3.	Изменять Сайт, совершать действия, направленные на изменение функционирования и работоспособности Сайта.</p>
                    <p>5.4.4.	Создавать копии Сайта, Интеллектуальной собственности, любых материалов, доступ к которым получает Заказчик в связи с исполнением настоящего Договора-оферты, а также копировать их внешнее оформление (дизайн).</p>
                    <p>5.4.5.	Предоставлять третьим лицам доступ к Интеллектуальной собственности Исполнителя, личному кабинету и любым материалам, доступ к которым получает Заказчик в связи с исполнением настоящего Договора-оферты в отсутствие прямого письменного согласия на такой доступ со стороны Исполнителя.</p>
                    <p>5.4.6.	Создавать производные и аналогичные обучающие курсы, распространять, передавать третьим лицам или иным образом использовать частично или полностью материалы.</p>
                    <p>5.5.	Заказчик имеет право:</p>
                    <p>5.5.1.	Получать выбранные и оплаченные услуги в соответствии с условиями настоящего Договора-оферты.</p>
                    <p>5.5.2.	Делать письменные записи во время проведения Курса, получать разъяснения по теме Курса, получать полную информацию о содержании и порядке предоставления Услуг Исполнителем</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>6.	Ответственность сторон</h4>
                    <p>6.1.	В случаях неисполнения или ненадлежащего исполнения своих обязательств по Договору, Стороны несут ответственность в соответствии с законодательством РК с учетом условий настоящего Договора.</p>
                    <p>6.2.	Исполнитель не несет ответственности в случае ненадлежащего оказания Услуг, если ненадлежащее исполнение явилось следствием недостоверности, недостаточности или несвоевременности предоставленных Заказчиком сведений, а также вследствие других нарушений условий настоящего Договора со стороны Заказчика.</p>
                    <p>6.3.	Исполнитель не гарантирует непрерывную работу Сайта и отсутствие сбоев. Однако будет прилагать все разумные усилия для оперативного устранения любых проблем и минимизации негативных последствий, связанных с такими сбоями или перерывами.</p>
                    <p>6.4.	Исполнитель не несет ответственности за несоответствие предоставленной услуги ожиданиям Заказчика и/или за его субъективную оценку, такое несоответствие ожиданиям и/или отрицательная субъективная оценка не являются основаниями считать услуги оказанными некачественно, или не в согласованном объеме.</p>
                    <p>6.5.	При выявлении факта доступа третьих лиц к Обучающим материалам по вине Заказчика по требованию Исполнителя, направляемому на адрес электронной почты, указанной Заказчиком при регистрации на Сайте, Заказчик обязан оплатить штраф в размере 1000% (тысячи процентов) от стоимости Услуг. Размер штрафа обусловлен степенью возможного либо причиненного вреда правам и законным интересам Исполнителя, который многократно превышает стоимость услуг, оплаченных Заказчиком по настоящему Договору-оферте. Требование Исполнителя о выплате штрафа подлежит немедленному удовлетворению в добровольном, досудебном порядке. В случае отказа либо неудовлетворения требования о выплате штрафа Заказчиком, Исполнитель вправе незамедлительно обратиться в суд для защиты нарушенного права, без соблюдения досудебного претензионного порядка урегулирования спора.</p>
                    <p>6.6.	Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору, если таковые явились следствием непредвиденных, непреодолимых обстоятельств, а именно землетрясение, пожар, наводнение, прочие стихийные бедствия, эпидемии, аварии, взрывы, военные действия, изменения законодательства, указы уполномоченных лиц, отсутствие электроэнергии и/или сбои работы компьютерной сети, забастовки, гражданские волнения, беспорядки повлекшие за собой невозможность выполнения Сторонами своих обязательств по настоящему Договору.</p>
                    <p>6.7.	Стороны предпримут все возможное, чтобы выполнить все обязательства по настоящему Договору и воспользуются возможностью переноса выполнения обязательств на согласованный срок.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>7.	Срок действия Договора, его изменение и расторжение</h4>
                    <p>7.1.	Настоящий Договор-оферта действует с даты его заключения, которой считается дата акцепта Оферты Заказчиком, до даты исполнения обязательств, принятых на себя по настоящему Договору-оферте Сторонами.</p>
                    <p>7.2.	Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего Договора-оферты в любое время, опубликовывая все изменения на своем Сайте. Если опубликованные изменения для Заказчика неприемлемы, то он в течение 7 дней с момента опубликования изменений должен уведомить об этом Исполнителя письменно. Если уведомления не поступило, то считается, что Заказчик продолжает принимать участие в договорных отношениях на новых условиях.</p>
                    <p>7.3.	Заказчик вправе расторгнуть настоящий Договор в одностороннем порядке в случае непредоставления Исполнителем Услуг в заявленные сроки.</p>
                    <p>7.4.	Стороны вправе расторгнуть настоящий Договор-оферту по взаимному согласию в любой момент до момента его фактического исполнения.</p>
                    <p>7.5.	В случае, если Заказчик желает расторгнуть настоящий Договор-оферту, он обязан:</p>
                    <p>7.5.1.	направить на адрес электронной почты Исполнителя info@maddy.fit уведомление об одностороннем отказе от исполнения Договора- оферты с адреса электронной почты, указанной им при регистрации на Сайте. Настоящий Договор-оферта считается расторгнутым с момента получения Уведомления Заказчика об одностороннем отказе от исполнения настоящего Договора-оферты;</p>
                    <p>7.5.2.	возместить Исполнителю фактически понесенные расходы, связанные с оказанием услуг по настоящему Договору-оферте, в том числе:</p>
                    <p>7.5.3.	расходы на использованное Исполнителем в процессе обучения ПО и оплату услуг третьих лиц;</p>
                    <p>7.5.4.	комиссии банковских, кредитных организаций и соответствующих платежных систем за осуществление возврата денежных средств;</p>
                    <p>7.5.5.	иные расходы, понесенные Исполнителем в процессе оказания услуг по настоящему Договору-оферте. Конкретная сумма фактических расходов определяется Исполнителем самостоятельно.</p>
                    <p>7.5.6.	оплатить стоимость оказанных на момент расторжения настоящего Договора-оферты услуг</p>
                    <p>7.6.	Настоящий Договор-оферта может быть расторгнут по инициативе Исполнителя в случаях нарушение Заказчиком пунктов 5.3, 5.4. настоящего Договора-оферты.</p>
                    <p>7.7.	Расторжение настоящего Договора-оферты по одной из причин, указанных в пункте 7.6. настоящего Договора-оферты происходит посредством уведомления Заказчика электронным сообщением на адрес электронной почты, указанной им при регистрации на сайте Исполнителя. Денежные средства, уплаченные Исполнителю, возврату не подлежат и удерживаются Исполнителем в качестве штрафа за ненадлежащее исполнение условий настоящего Договора-оферты.</p>
                    <p>7.8.	Настоящий Договор-оферта считается расторгнутым с момента направления Исполнителем соответствующего уведомления Заказчику.</p>
                    <p>7.9.	Настоящий Договор-оферта может быть расторгнут Исполнителем в одностороннем порядке в случае распространения Заказчиком недостоверной, порочащей, либо наносящей вред деловой репутации Исполнителя и оказываемых им услуг, любым способом, включая распространение указанной информации (в том числе, в виде обнародования переписки с Исполнителем, его сотрудниками или представителями) в Сети Интернет: в социальных сетях, в мессенджерах посредством любого рода рассылок и т.д. В случае обнаружения Исполнителем факта подобного поведения Заказчика, настоящий Договор-оферта расторгается, о чем Заказчик уведомляется Исполнителем на адрес электронной почты, сообщенный Заказчиком при регистрации на Сайте или при оплате услуг. Денежные средства, уплаченные Исполнителю, возврату не подлежат. Настоящий Договор-оферта считается расторгнутым со дня направления Исполнителем соответствующего сообщения Заказчику.</p>
                    <p>7.10.	В момент досрочного расторжения настоящего Договора-оферты доступ к Обучающим материалам и любым другим материалам, предоставленным Исполнителем Заказчику в процессе исполнения настоящего Договора-оферты прекращается.</p>
                    <p>7.11.	В случае если при расторжении настоящего Договора-оферты Исполнитель обязан произвести возврат денежных средств Заказчику, Заказчик обязан предоставить банковские реквизиты, с которых производилась оплата услуг. В противном случае, Исполнитель вправе не производить перечисление денежных средств до момента предоставления надлежащих банковских реквизитов. На период непредоставления надлежащих банковских реквизитов неустойка на сумму возврата не начисляется.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>8.	Разрешение споров</h4>
                    <p>8.1.	Претензии Заказчика по оказываемым услугам принимаются Исполнителем к рассмотрению посредством электронной почты. Все претензии направляются Заказчиком с адреса электронной почты, указанной им при регистрации на Сайте на адрес электронной почты Исполнителя: info@maddy.fit.</p>
                    <p>8.2.	Сторона, получившая претензию, обязана дать на нее ответ в течение десяти календарных дней со дня получения.</p>
                    <p>8.3.	Все споры и разногласия, которые могут возникнуть из настоящей Оферты или в связи с ней, стороны стараются разрешать путем переговоров.</p>
                    <p>8.4.	В случае недостижения согласия между Сторонами все споры рассматриваются в судебном порядке, в соответствии с законодательством РК по месту регистрации Исполнителя. </p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>9.	Прочие положения</h4>
                    <p>9.1.	Любая информация, доступ к которой получает Заказчик в связи с получением услуг по настоящему Договору-оферте, не может копироваться, передаваться третьим лицам, тиражироваться, распространяться, пересылаться, публиковаться в электронной, «бумажной» или иной форме без дополнительных соглашений или официального письменного согласия Исполнителя.</p>
                    <p>9.2.	Исполнитель имеет право уступать права, переводить долги по всем обязательствам, возникшим из настоящего Договора-оферты. Настоящим Заказчик дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Исполнитель информирует Заказчика посредством направления соответствующего сообщения на адрес электронной почты Заказчика, указанной им при регистрации на Сайте.</p>
                    <p>9.3.	Электронный документооборот по адресам электронной почты, указанной Исполнителем в настоящем Договоре-оферте, указанном Заказчиком в Заявке на регистрацию, приравнивается Сторонами к документообороту на бумажных носителях, если в тексте настоящего Договора-оферты прямо не предусмотрено иное.</p>
                    <p>9.4.	По всем вопросам, не урегулированным настоящим Договором-офертой, Стороны руководствуются действующим законодательством Республики Казахстан.</p>
                </div>
                <div className='docs-section'>
                    <h4 className='docs-section-title'>10.	Реквизиты Исполнителя</h4>
                    <p>ТОО MaddyFit (БИН 220340008615)</p>
                    <p>Сайт компании: https://maddy.fit</p>
                    <p>E-mail службы поддержки: info@maddy.fit</p>
                    <p>Банковские реквизиты:</p>
                    <p>Halyk Bank</p>
                    <p>БИК 000000</p>
                    <p>Р/с: 0000000000</p>
                    <p>К/с: 0000000000</p>
                </div>


            </div>
        </Container>
    )
}

export default PublicOffer



