import { useContext, useState, useEffect, useRef, useMemo} from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';

import Avatar from 'components/UI/Avatar/Avatar';
import ThemeSwitcher from './ThemeSwitcher'
import NotificationsCount from './NotificationsCount';
import AuthContext from 'context/AuthContext';
import { DropdownMenu, DropdownItem } from 'components/UI/Dropdown/DropdownMenu'
import Badge from 'components/UI/Badge/Badge';
import { LogoutMobileIcon } from 'components/svg/icons';
import './Navbar.css'


function NavbarComp() {
  const { pathname } = useLocation()
  let { isAuth, logout, user } = useContext(AuthContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const isHideNavBar = pathname.match('password_reset') || pathname === '/login' || pathname === '/signup' || pathname.match('auth/social')
  const notifsCount = NotificationsCount()
  const isHomePage = pathname == '/'

  const toggleMobileMenu = () =>{
    setShowMobileMenu(!showMobileMenu)
  };


  if (isHideNavBar) {
    return null
  }

  return (
    <header>
      <div className={isHomePage ? 'header-wrapper header-home' : 'header-wrapper'}>
        <Container>
          <div className='header-body'>
            <div className='header-logo'>
              <Link to='/'><h2>MaddyFit</h2></Link>
            </div>
            <div className='header-menu'>
              <Stack className="nav-desktop-links" direction="row" spacing={2}>
                <Link to='/' className="nav-bar-link">Главная</Link>
                {/* <Link to='/about' className="nav-bar-link">Обо мне</Link>
                <Link to='/blog' className="nav-bar-link">Статьи</Link>
                <Link to='/recipes' className="nav-bar-link">Рецепты</Link> */}
                <Link to='/contacts' className="nav-bar-link">Контакты</Link>
              </Stack>
              <ThemeSwitcher />
              <div className="nav-account-icon" title="Account settings">
                <IconButton onClick={e => setDropDown(!dropDown)} size="small" sx={{ ml: 2, zIndex: 81 }}>
                    {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                    <Avatar user={user} showDefault={true} className={'navbar-avatar'}/> 
                    {notifsCount > 0 && <Badge className='notification-badge'></Badge>}
                </IconButton>
                <DropdownMainMenu isOpen={dropDown} isAuth={isAuth} setOpen={setDropDown} logout={logout} notifsCount={notifsCount}/>
              </div>
              <div className="nav-mobile-menu-icon" onClick={toggleMobileMenu}>
                <MenuIcon />
              </div>
            </div>
          </div>
          <DropdownMobileMenu isOpen={showMobileMenu} setOpen={setShowMobileMenu} isAuth={isAuth} logout={logout} notifsCount={notifsCount}/>
        </Container>
      </div>
    </header>
  );
}

export default NavbarComp;


function DropdownMainMenu({isOpen, isAuth, setOpen, logout, notifsCount}) {
  const { pathname } = useLocation()
  
  return (
      <DropdownMenu isOpen={isOpen} setOpen={setOpen} className={'nav-dropdown dropdown-menu-right'}>
        {isAuth
          ? 
          <>
          <DropdownItem link={'/my/courses'} setOpen={setOpen}>Мои курсы</DropdownItem>
          <DropdownItem link={'/my/notifications'} setOpen={setOpen}><NotifsNumber notifsCount={notifsCount}/></DropdownItem>
          <DropdownItem link={'/settings/info'} setOpen={setOpen}>Настройки</DropdownItem>
          <DropdownItem link={'/help'} setOpen={setOpen}>Помощь</DropdownItem>
          <hr className="divider"/>
          <DropdownItem action={logout}>Выйти</DropdownItem>
          </>
          :
          <>
          <DropdownItem link={'/help'} setOpen={setOpen}>Помощь</DropdownItem>
          <hr className="divider"/>
          <DropdownItem link={'/login'} state={{from: pathname}} setOpen={setOpen}>Войти</DropdownItem>
          </>
        }
      </DropdownMenu>
  );
}

function DropdownMobileMenu({isOpen, setOpen, isAuth, logout, notifsCount}) {
  const [menuHeight, setMenuHeight] = useState(0)
  const menuRef = useRef(null)
  const targetHeight = useMemo(() => menuRef.current?.firstChild?.offsetHeight, [menuRef.current])
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpen) { setMenuHeight(targetHeight) }
    else { setMenuHeight(0) }
  }, [isOpen])

  const Close = () => setOpen(false)

  return (
    <div ref={menuRef} className="nav-mobile-menu-wrapper" style={{height: menuHeight}}>
      <div className="nav-mobile-menu" onClick={Close} >
        <Link className="nav-mobile-menu-item" to="/">Главная</Link>
        {/* <Link className="nav-mobile-menu-item" to="/about">Обо мне</Link>
        <Link className="nav-mobile-menu-item" to="/blog">Статьи</Link>
        <Link className="nav-mobile-menu-item" to="/recipes">Рецепты</Link> */}
        <Link className="nav-mobile-menu-item" to="/contacts">Контакты</Link>
        <Link className="nav-mobile-menu-item" to="/help">Помощь</Link>
        <hr className='divider'/>
        {isAuth
          ? 
          <>
          <Link className="nav-mobile-menu-item" to='/my/courses' color="inherit">Мои курсы</Link>
          <Link className="nav-mobile-menu-item" to='/my/notifications'><NotifsNumber notifsCount={notifsCount}/></Link>
          <Link className="nav-mobile-menu-item" to='/settings/info'>Настройки</Link>
          <span className="nav-mobile-menu-item flex-align-center" onClick={logout}><LogoutMobileIcon className='icon-filled me-1'/>Выйти</span>
          </>
          :
          <Link className="nav-mobile-menu-item" to={{pathname: '/login', state: {from: pathname}}}>Войти</Link>
        }
      </div>
    </div>
  )
}


const NotifsNumber = ({notifsCount}) => {
  if (notifsCount < 1) { return 'Уведомления' }
  return `Уведомления (${notifsCount})`
}

