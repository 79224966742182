export function updateItemInArray(updated_item, array, setArray) {
    const new_array = array.map((item) => {
        if(item.id === updated_item.id) {
            return updated_item;
        }
        return item;
    });
    setArray(new_array)
}

export function updateItemValueInArray(updated_item, value_name, array, setArray) {
    const new_array = array.map( item => {
        if(item.id === updated_item.id) {
            let new_item = {...item}
            new_item[value_name] = updated_item[value_name]
            return updated_item;
        }
        return item;
    })
    setArray(new_array)
}

export function updateItemsValueInArray(updated_items, value_name, array, setArray) {
    const new_array = array.map( item => {
        const found = updated_items.find( el => el.id === item.id )
        if (found) {
            let new_item = {...item}
            new_item[value_name] = found[value_name]
            return found
        }
        return item
    })
    setArray(new_array)
}

export function updateItemsInArray(updated_items, array, setArray) {
    const new_array = array.map( item => {
        const found = updated_items.find( el => el.id === item.id )
        if (found) {
            return found
        }
        return item
    })
    setArray(new_array)
}