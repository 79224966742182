import {useState, useEffect} from 'react';
import Alert from './Alert';
import './AlertBar.css'

function AlertBar({messages, setMessages}) {
    // Displays messages to top of the screen
    // Messages stay even if user moves to other location
    // Messages auto fade after delay
    // Messages are stored in MessageContext
    // Message is an object like:
    // {level: 'error', text: 'Произошла ошибка. Пожалуйста, попробуйте позже.'}

    function deleteMessage(message) {
        let new_messages = messages.filter(m => m !== message)
        setMessages(new_messages)
    }


    return (
        <div className='alert-bar'>
            {messages.map(message => (
                <Alert key={message} message={message} deleteMessage={deleteMessage} fadeEnabled={message.fadeEnabled}/>
            ))}
        </div>
    )
}

export default AlertBar