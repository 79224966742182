import { Navigate, useSearchParams } from 'react-router-dom'


function PaymentFailed() {
    // DEPRECATED

    const [searchParams] = useSearchParams();
    const courseSlug = searchParams.get('slug', null)
    if (courseSlug) {
        return <Navigate to={`/course/${courseSlug}`} replace/>;
    } else {
        return <Navigate to={`/`} replace/>;
    }
}

export default PaymentFailed