import { useEffect } from 'react'
import getVKURL from './getVKURL';


function GetVKAuthCode() {
    const state = Math.random().toString(36).substring(7);
    const clientId = process.env.REACT_APP_VK_CLIENT_ID
    const scope = ['email']
    const responseType = 'code'
    const urlSearchParams = new URLSearchParams(window.location.search)
    const socketID = urlSearchParams.get('socket')
    const redirectUri = window.location.origin + `/auth/social/vk/callback?socket=${socketID}`

    useEffect(() => {
        localStorage.socialState = state;
        localStorage.socialRedirectUri = redirectUri;
        window.location.href = getVKURL({ clientId, state, scope, responseType, redirectUri });
    }, [])

    return (
        <></>
    )
}

export default GetVKAuthCode