import { useState } from 'react'
import { declOfNum } from '../../utils/Common'

function IngredientsList({recipe}) {
    const [portions, setPortions] = useState(recipe.portions)
    const minPortions = 1
    const maxPortions = 20
    const [ingredients, setIngredients] = useState(recipe.ingredients)

    const increasePortions = () => {
        let newPortions = portions + 1
        if (newPortions <= maxPortions) {
            updateIngredients(portions, newPortions)
        }
    }

    const decreasePortions = () => {
        let newPortions = portions - 1
        if (newPortions >= minPortions) {
            updateIngredients(portions, newPortions)
        }
    }

    const updateIngredients = (oldPortions, newPortions) => {
        const modifiedIngredients = ingredients.map(ingredient => {
            let modifiedIngredient = {...ingredient}
            modifiedIngredient['quantity'] = (modifiedIngredient['quantity'] / oldPortions) * newPortions
            return modifiedIngredient;
        })
        setIngredients(modifiedIngredients)
        setPortions(newPortions)
    }

    return (
        <div className='ingredients-wrapper'>
            <h3 className='mb-2'>Ингредиенты</h3>

            <div className='ingredients'>
                <div className='portions'>
                    <span>порции</span>
                    <button onClick={decreasePortions}><i className="fa-solid fa-minus"></i></button>
                    <span className='portions-count'>{ portions }</span>
                    <button onClick={increasePortions}><i className="fa-solid fa-plus"></i></button>                            
                </div>
                {ingredients.map((ingredient) => 
                    <div className='ingredient' key={ingredient.id}>
                        <span>{ingredient.product}</span><span>{getIngredientAmount(ingredient.quantity, ingredient.unit)}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default IngredientsList


const getIngredientAmount = (number, unit) => {
    
    if (unit === 'to_taste') {
        return 'по вкусу'
    }

    const units = {
        gram: ['грамм', 'грамма', 'граммов'],
        kilogram: ['килограмм', 'килограмма', 'килограммов'],
        glass: ['стакан', 'стакана', 'стаканов'],
        to_taste: ['по вкусу', 'по вкусу', 'по вкусу'],
        table_spoon: ['столовая ложка', 'столовые ложки', 'столовых ложек'],
        tea_spoon: ['чайная ложка', 'чайные ложки', 'чайных ложек'],
        ml: ['миллилитр', 'миллилитра', 'миллилитров'],
        clove: ['долька', 'дольки', 'долек'],
        unit: ['шт', 'шт', 'шт'],
    }

    let displayNumber = number
    let unitNumber = number

    if (number < 1) {
        switch(true) {
            case number <= 0.25:
                displayNumber = '1/4'
                unitNumber = 0.25
                break;
            case number <= 0.5:
                displayNumber = '1/2'
                unitNumber = 0.5
                break;
            case number <= 0.75:
                displayNumber = '3/4'
                unitNumber = 0.75
                break;
            default:
                displayNumber = '1'
                unitNumber = 1

        }
        return  displayNumber + ' ' + declOfNum(unitNumber, units[unit])
    }

    switch(true) {
        case number < 10:
            displayNumber = (Math.round(number * 10)/10).toString()
            break;
        default:
            displayNumber = Math.round(number).toString()
    }
    return  displayNumber + ' ' + declOfNum(displayNumber, units[unit])
}