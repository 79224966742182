import React, { useState, useContext, useMemo, useEffect } from 'react';
import { TextField, FormControl } from '@mui/material';
import AuthContext from 'context/AuthContext';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Alerts from 'components/Alerts';
import InputErrorMsg from 'components/InputErrorMsg';
import { useInput } from 'hooks/useInput';
import { setFormsErrors } from 'hooks/useInput';
import AuthService from 'services/AuthService';
import { useFetching } from 'hooks/useFetching';
import LoadingButton from 'components/UI/Button/LoadingButton'
import MessageContext from 'context/MessageContext';
import { useNavigate, useLocation } from 'react-router-dom';
import ReturnPaneButton from 'components/ReturnPaneButton';
import SocialLogin from 'components/SocialAuth/SocialLogin';
import { useSearchParams } from 'react-router-dom';
import 'styles/pages/LoginSignupPage.css'



function SignUpPage() {
    const { setMessages } = useContext(MessageContext)
    let [searchParams, setSearchParams] = useSearchParams();
    console.log('searchParams', searchParams)

    let { isAuth, Authorize, Deauthorize } = useContext(AuthContext)
    const [alertMessages, setAlertMessages] = useState([])
    const navigate = useNavigate()
    const location = useLocation()

    const first_name = useInput('', 'first_name', 'text', {notEmpty: true, maxLength: 50})
    const last_name = useInput('', 'last_name', 'text', {maxLength: 50})
    const email = useInput('', 'email', 'email', {notEmpty: true, isEmail: true, maxLength: 100})
    const password1 = useInput('', 'password1', 'password', {notEmpty: true, minLength: 6, maxLength: 50})
    const password2 = useInput('', 'password2', 'password', {notEmpty: true, isMatch: password1.value})
    
    async function Submit(e) {
        e.preventDefault()
        const isFirstNameValid = first_name.StartValidation()
        const isLastNameValid = last_name.StartValidation()
        const isEmailValid = email.StartValidation()
        const isPassword1 = password1.StartValidation()
        const isPassword2 = password2.StartValidation()
        if (isFirstNameValid && isLastNameValid && isEmailValid && isPassword1 && isPassword2) {
            signup()
        }
    }

    const errorHandler = (response) => {
        if(response?.data) {
            let errors = [response?.data]
            let fields = [email, password1, password2]
            setFormsErrors(fields, errors)

            if (response?.data?.non_field_errors) {
                let _errors = response.data.non_field_errors
                let _messages = []
                for (const index in _errors) {
                    _messages.push({level: 'error', message: _errors[index]})
                }
                setAlertMessages(_messages)
            }
        }
    }

    const [signup, isSignupLoading, signupError] = useFetching(async () => {
        const formData = new FormData()
        formData.append('first_name', first_name.value)
        formData.append('last_name', last_name.value)
        formData.append('email', email.value)
        formData.append('password1', password1.value)
        formData.append('password2', password2.value)

        Deauthorize()
        const response = await AuthService.registration(formData);
        if (response.status === 201){
            if (response?.data?.verification) {
                setMessages([{level: 'success', message: response?.data?.verification}])
            }
            handleAuthResponse(response)
        }
    }, errorHandler)


    const handleAuthResponse = (response) => {
        Authorize(response.data)
        const currentSearchParams = new URLSearchParams(location.search);
        if (currentSearchParams.has('to')) {
            let goToUrl = currentSearchParams.get('to');
            currentSearchParams.delete('to');
            if (currentSearchParams.size > 0) {
                goToUrl += `?${currentSearchParams.toString()}`
            }
            navigate(goToUrl);
        } else {
            navigate('/');
        }
    }

    

    return (
        <Container className='auth-container'>
            <div className="auth-form">
            <Alerts messages={alertMessages} setMessages={setAlertMessages}/>
            <div className="auth-card-body">
                <ReturnPaneButton />
                <h5 className="auth-card-title mb-2">Регистрация</h5>
                <form onSubmit={Submit}>
                    <FormControl fullWidth>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Имя*" 
                                variant="outlined" 
                                type="text" 
                                name="first_name"
                                value={first_name.value} 
                                onChange={e => first_name.onChange(e)}
                            />
                            <InputErrorMsg field={first_name}/>
                        </FormControl>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Фамилия" 
                                variant="outlined" 
                                type="text" 
                                name="last_name"
                                value={last_name.value} 
                                onChange={e => last_name.onChange(e)}
                            />
                            <InputErrorMsg field={last_name}/>
                        </FormControl>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Email*" 
                                variant="outlined" 
                                type="email" 
                                name="email"
                                value={email.value} 
                                onChange={e => email.onChange(e)}
                            />
                            <InputErrorMsg field={email}/>
                        </FormControl>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Пароль" 
                                variant="outlined" 
                                type="password" 
                                name="password1"
                                value={password1.value} 
                                onChange={e => password1.onChange(e)}
                            />
                            <InputErrorMsg field={password1}/>
                        </FormControl>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Повторите пароль" 
                                variant="outlined" 
                                type="password" 
                                name="password2"
                                value={password2.value} 
                                onChange={e => password2.onChange(e)}
                            />
                            <InputErrorMsg field={password2}/>
                        </FormControl>
                    </FormControl>
                    <LoadingButton 
                        className="btn fullwidth" 
                        type="submit"
                        isLoading={isSignupLoading}
                        loadingText={'Загрузка'}
                    >
                        Создать аккаунт
                    </LoadingButton>
                    <p className='mt-2 text-center text-small'>Нажимая «Создать аккаунт», я соглашаюсь с <Link to='/help/agreements/privacy-policy'>Политикой конфиденциальности</Link></p>
                    <p className='mb-2 text-center'><Link to={`/login?${searchParams.toString()}`}>Войти</Link> в существующий аккаунт</p>
                </form>
                <div className="divider-wrapper mt-2 mb-2">
                    <hr/><span>или</span><hr/>
                </div>
                <SocialLogin responseHandler={handleAuthResponse} errorHandler={errorHandler}/>
            </div>
            </div>

        </Container>
    )
}

export default SignUpPage
