import { useToggle } from '../../../hooks/useToggle'
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { IconMenuExpand } from '../../svg/icons';


function NavMenuBlock({block, className}) {
    const [isOpen, toggleIsOpen] = useToggle(false)
    const { pathname } = useLocation()
    const btnRef = useRef(null)

    useEffect(() => {
        if (pathname.includes(block.link)) {
            toggleIsOpen(true)
            btnRef.current.classList.add('current')
        } else {
            btnRef.current.classList.remove('current')
        }
    }, [pathname])

    useEffect(() => {
        if (isOpen) { btnRef.current.classList.add('open') }
        else { btnRef.current.classList.remove('open') }
    }, [isOpen])


    return (
        <div key={block.link} className='nav-parent-menu'>
            <div onClick={toggleIsOpen} className={className + ' ' + 'nav-menu-btn'} ref={btnRef} data-block-closing>
                {block.title}
                <IconMenuExpand className={'icon-filled expand-icon'} height={'.7rem'}/>
            </div>
            {isOpen &&
                <div className='nav-child-menu'>
                    {block.links.map(link => (
                        <NavLink key={link.to} to={`${block.link}/${link.to}`} className={className}>{link.title}</NavLink>
                    ))}
                </div>
            }
        </div>
    )
}

export default NavMenuBlock