import { useState, useRef, useCallback } from 'react'
import AuthWatchSocket from './AuthWatchSocket'
import { makeRandomID } from '../../utils/Common'
import useGoogleAuth from './Google/useGoogleAuth'
import getVKAuthCode from './VK/GetVKAuthCode'

function useSocialAuth(providers) {
    // Провайдера это объект данного вида:
    // let providers = {
    //     vk: {slug: 'vk', method: callback},
    //     google: {slug: 'google', method: callback},
    //     facebook: {slug: 'facebook', method: callback},
    // }

    // При выборе любого провайдера закрываются все ранее открытые всплывающие окна

    // Google открывается через родное всплывающее окно, 
    // потому что через его редирект нельзя передать сокет id

    // Сокет открывается только один раз. При смене соц. сети сокет остается прежний.
    // Переоткрывать сокет нет смысла

    const socket = useRef()
    const [socketID, setSocketID] = useState(null)
    const [socketConnected, setSocketConnected] = useState(false)
    const [windowReference, setWindowReference] = useState(null)

    const getGoogleAuthCode = useGoogleAuth(providers.google.method)

    function openLoginPopup (providerSlug) {

        // let url = `/auth/social/vk/start?socket=${socketID}`
        // let url = `/auth/social/${provider.slug}/${socketID}/start`
        // Имя провайдера помещается в середину адреса, потому что ВК не пропускает
        // два query параметра в конца адреса, только первый
        let url = `/auth/social/${providerSlug}/start?socket=${socketID}`

        if (socket == null || socketConnected == false && providerSlug !== 'google') {
            const newSocketID = makeRandomID(32)
            setSocketID(newSocketID)
            AuthWatchSocket(socket, setSocketConnected, newSocketID, providers)
            url = `/auth/social/${providerSlug}/start?socket=${newSocketID}`
        } else {
            // Socket is already exists OR not required
        }
    
        const popupWidth = 520
        const popupHeight = 570
    
        const left = ((window.screen.width - popupWidth) / 2) + (window.screen.availLeft | 0)
        const top = ((window.screen.height - popupHeight) / 2) + (window.screen.availTop | 0)
        
        const title = 'SocialSingIn'
        const options = `height=${popupHeight},width=${popupWidth},left=${left},top=${top},scrollbars=1,resizable=1`
    
        if (windowReference) {
            windowReference.close()
            setWindowReference(null)
        }

        if (providerSlug === 'google') {
            getGoogleAuthCode();
        } else if (providerSlug === 'vk' || providerSlug === 'facebook') {
            setWindowReference( window.open(url, title, options) )
        }        
    }

    return openLoginPopup
}

export default useSocialAuth