import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';


function AboutPage() {

    return (
        <Container className="d-flex flex-column pt-3">
        <div className='text-center'>
            <h1>Всем привет!</h1>
        </div>
        </Container>
    )
}

export default AboutPage