import { useEffect, useState, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFetching } from 'hooks/useFetching';
import CourseService from 'services/CourseService';
import MessageContext from 'context/MessageContext';
import BackdropLoader from 'components/UI/BackdropLoader/BackdropLoader';


function PaymentSuccess() {
    // DEPRECATED
    const { setMessages } = useContext(MessageContext)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const paymentID = searchParams.get('id', null)
    let didInit = false
    let retries = 0
    const maxRetries = 3
    const retryInterval = 3000


    const [checkPayment] = useFetching(async () => {
        const response = await CourseService.checkPayment(paymentID)
        const status = response.data?.status

        switch (status) {
            case 'completed':
                navigate(`/course/${response.data.course}`)
                break;
            case 'pending':
                retries++
                if (retries < maxRetries) {
                    setTimeout(() => {
                        checkPayment()
                    }, retryInterval);
                } else {
                    setLoading(false)
                    setMessages([{level: 'error', message: "Не удается подтвердить оплату. Попробуй немного позже или обратитесь в службу поддержки"}])
                }
                break;
            case 'failed':
                setMessages([{level: 'error', message: "Ошибка оплаты"}])
                break;
            case 'refunded':
                setMessages([{level: 'error', message: "Платеж возращен"}])
                break;
            case 'cancelled':
                setMessages([{level: 'error', message: "Платеж отменен"}])
                break;
            case 'processing':
                console.log('processing');
                break;
            default:
                setMessages([{level: 'error', message: "Не удается определить статус платежи"}])
                break;
        }

        if (status != 'pending') {
            setLoading(false)
        }

    }, () => {
        setLoading(false)
        setMessages([{level: 'error', message: "Ошибка запроса. Пожалуйста, попробуйте позже или обратитесь в службу поддержки."}])
        navigate('/')
    })


    useEffect(() => {
        if (!didInit) {
            didInit = true
            checkPayment()
        }
    }, [])


    return (
        loading ?
        <BackdropLoader />
        :
        <div></div>
    )
}

export default PaymentSuccess