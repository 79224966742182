import React, { useState, useContext } from 'react';
import { TextField, FormControl } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import LoadingButton from 'components/UI/Button/LoadingButton'
import InputErrorMsg from 'components/InputErrorMsg';
import { useInput } from 'hooks/useInput';
import { useCallData } from 'hooks/useFetching';
import { setFormsErrors } from 'hooks/useInput';
import {useNavigate, useLocation } from 'react-router-dom';
import Alerts from 'components/Alerts';
import ReturnPaneButton from 'components/ReturnPaneButton';
import SocialLogin from 'components/SocialAuth/SocialLogin';
import AuthServiceLinks from 'services/AuthServiceLinks';
import { useSearchParams } from 'react-router-dom';
import 'styles/pages/LoginSignupPage.css'


function LoginPage() {
    let { Authorize, Deauthorize } = useContext(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [alertMessages, setAlertMessages] = useState([])
    let [searchParams, setSearchParams] = useSearchParams();


    const email = useInput('', 'email', 'email', {notEmpty: true, isEmail: true})
    const password = useInput('', 'password', 'password', {notEmpty: true})


    
    function errorHandler(response) {
        if(response?.data) {
            let errors = [response?.data]
            let fields = [email, password]
            setFormsErrors(fields, errors)

            if (response?.data?.non_field_errors) {
                let _errors = response.data.non_field_errors
                let _messages = []
                for (const index in _errors) {
                    _messages.push({level: 'error', message: _errors[index]})
                }
                setAlertMessages(_messages)
            }
        }
    }

    const handleAuthResponse = (response) => {
        Authorize(response.data)
        const currentSearchParams = new URLSearchParams(location.search);
        if (currentSearchParams.has('to')) {
            let goToUrl = currentSearchParams.get('to');
            currentSearchParams.delete('to');
            if (currentSearchParams.size > 0) {
                goToUrl += `?${currentSearchParams.toString()}`
            }
            navigate(goToUrl);
        } else {
            navigate('/');
        }
    }

    const [loginCall, isLoginLoading] = useCallData('post', AuthServiceLinks.login, errorHandler)


    async function Submit(e) {
        e.preventDefault()
        const isEmailValid = email.StartValidation()
        const isPassword = password.StartValidation()
        if (isEmailValid && isPassword) { 
            Deauthorize();
            setAlertMessages([])
            const data = {'email': email.value, 'password': password.value}
            const response = await loginCall({data})
            if ( response == undefined ) { return }
            if (response.status === 200) {
                handleAuthResponse(response);
            }
        }
    }


    
    return (
        <Container className='auth-container'> 
            <div className="auth-form">
                <div className="auth-card-body">
                    <ReturnPaneButton />
                    <h5 className="auth-card-title mb-2">Войти</h5>
                    <form onSubmit={e => Submit(e)}>
                    <Alerts messages={alertMessages} setMessages={setAlertMessages}/>
                    <FormControl fullWidth>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                label="Email address" 
                                variant="outlined" 
                                type="email" 
                                name="email"
                                value={email.value} 
                                onChange={e => email.onChange(e)}
                            />
                            <InputErrorMsg field={email}/>
                        </FormControl>
                        <FormControl fullWidth  sx={{ mt: 1, mb: 1 }} variant="outlined">
                            <TextField 
                                className="form-control" 
                                label="Password" 
                                variant="outlined" 
                                type="password" 
                                name="password"
                                value={password.value} 
                                onChange={e => password.onChange(e)}
                            />
                            <InputErrorMsg field={password}/>
                        </FormControl>
                        <Row xs="auto" style={{justifyContent: 'space-between', flexWrap: 'nowrap'}} className="m-0 mb-3">
                            <Link to={`/signup?${searchParams.toString()}`} className="px-3">Регистрация</Link>
                            <Link to='/password_reset' className="px-3">Забыли пароль?</Link>
                        </Row>
                    </FormControl>
                        <LoadingButton 
                            isLoading={isLoginLoading} 
                            loadingText={'Загрузка'} 
                            className="btn fullwidth" 
                            type="submit"
                        >
                            ВОЙТИ
                        </LoadingButton>
                    </form>
                    <div className="divider-wrapper mt-3 mb-2">
                        <hr/><span>или</span><hr/>
                    </div>
                    <SocialLogin responseHandler={handleAuthResponse} errorHandler={errorHandler}/>
                </div>
            </div>
        </Container>
    )
}

export default LoginPage
