import React, { useEffect, useState, useContext, useMemo } from 'react'
import { IconButton, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFetching } from '../../hooks/useFetching';
import CourseService from '../../services/CourseService';
import { CommentsContext } from './Comments';
import CloseIcon from '@mui/icons-material/Close';
import { useInput } from '../../hooks/useInput';
import { addNewCommentToList, updateComment } from '../Comments/utils';
import InputErrorMsg from '../InputErrorMsg';
import { setFormsErrors } from '../../hooks/useInput';
import Button from '../UI/Button/Button';
import Avatar from '../UI/Avatar/Avatar';
import AuthContext from '../../context/AuthContext';
import TextArea from '../Comments/TextArea';


function CommentForm({mode, stepID, parent, self, toggleForm}) {
    const { user } = useContext(AuthContext);
    const { comments, setComments, users, setUsers } = useContext(CommentsContext)
    const [formTitle, setFormTitle] = useState(mode === 'edit' ? 'Редактирование комментария' : 'Новый комментарий')
    const text = useInput('', 'text', 'text', {notEmpty: true})
    // const [file, setFile] = useState('')
    const file = useInput(null, 'video', 'file', {maxFileSize: 300})
    const [filename, setFilename] = useState('')
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const showAddVideo = useMemo(() => (file.value === null && !self?.video) || file.value === '', [file.value])


    useEffect(() => {
        let isMounted = true;

        if (isMounted){
            if (mode === 'edit') {
                let noBoldText = self.text.replace(/<b>|<\/b>/g,'')
                text.setValue(noBoldText)
                
                if (self.video) {
                    // file.setValue(self.video)
                    let videoName = self.video.match(/\w+\.\w+$/g);
                    if (videoName) { setFilename(videoName) }
                }
            }

            if (mode === 'reply') {
                setFormTitle('Ответ пользователю')
                const reply_to = users.find(_user => _user.id === parent.author)
                if (reply_to && reply_to.full_name) {
                    text.setValue(`@id${reply_to.id} (${reply_to.full_name.replace(' ', '_')}), `)
                }
            }
        }
        return () => { isMounted = false };
    }, [mode])


    const Input = styled('input')({
        display: 'none',
    });

    const onFileChange = e => {
        file.setValue(e.target.files[0])
        setFilename(e.target.files[0].name)
    }

    function resetForm() {
        text.reset()
        setUploadPercentage(0)
        file.reset()
        setFilename('')
    }

    function clearFileInput() {
        file.reset()
        setFilename('')
    }

    function SubmitComment(e) {
        e.preventDefault()
        const isTextValid = text.StartValidation()
        const isFileValid = file.StartValidation()

        if (isTextValid && isFileValid) { sendNewComment() }
    }


    const [sendNewComment, sendNewCommentLoading, sendNewCommentError] = useFetching(async () => {
        // let highlightName = text.value.replace(/(@\w+)/g, '<b>$1</b>');
        // let formatedText = highlightName.replace(/(?:\r\n|\r|\n)/g, '<br>');

        const formData = new FormData();

        formData.append('text', text.value)

        if (file.value || file.value === '') {
            formData.append('video', file.value)
        }

        if (mode === 'new') {
            formData.append('target', stepID)
        }

        if (mode === 'reply') {
            formData.append('target', parent.target)
            formData.append('thread', parent.thread ? parent.thread : parent.id)
            formData.append('parent', parent.id)
        }


        let response;
        if (mode === 'edit') {
            response = await CourseService.updateComment(self.id, formData, setUploadPercentage)
        } else {
            response = await CourseService.addNewComment(formData, setUploadPercentage)
        }
        resetForm()
        handleResponse(response)
    })

    const handleResponse = (response) => {
        if (mode === 'edit') {
            updateComment(response.data, comments, setComments)
        } else {
            addNewCommentToList(response.data, comments, setComments, users, setUsers)
        }

        if (mode !== 'new') { toggleForm(false) }
    }

    useEffect(() => {
        if(sendNewCommentError?.data) {
            let errors = sendNewCommentError?.data
            let fields = [text, file]
            setFormsErrors(fields, errors)
        }
    }, [sendNewCommentError])


    return (
        <div className="new-comment-card">
            <div className="new-comment-card-header">
                <p className="title">{formTitle}</p>
                {self &&
                    <IconButton aria-label="close" style={{padding: '0 0 3px 0'}} color="inherit" onClick={toggleForm}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            </div>
            <form>
                <TextArea value={text.value} onChange={e => text.setValue(e.target.value)}/>
                <InputErrorMsg field={text}/>
                <InputErrorMsg field={file}/>

                <div className="new-comment-card-footer">
                    <div className="new-comment-info">
                        <Avatar user={user} showDefault className={'comment-avatar-sm me-2'}/>
                    </div>
                    <div className="new-comment-controls">
                        <p>{filename}</p>
                        {!sendNewCommentLoading && 
                            <>
                            {showAddVideo
                                ?
                                <label htmlFor={`file-input${self ? self.id : ''}`}>
                                    <Input accept="video/*" id={`file-input${self ? self.id : ''}`} type="file" onChange={onFileChange}/>
                                    <span className='btn'>Добавить видео</span>
                                </label>
                                :
                                <Button className='btn' onClick={clearFileInput}>Удалить видео</Button>
                            }
                            </>
                        }
                        {!sendNewCommentLoading
                            ?<Button className='btn' type="submit" onClick={SubmitComment}>Отправить</Button>
                            :<Button className='btn' onClick={sendNewComment}>Отмена</Button>
                        }
                        
                    </div>
                    
                </div>
                {uploadPercentage > 0 &&
                    <LinearProgress style={{marginTop: '5px'}} variant="determinate" value={uploadPercentage} />
                }
            </form>
        </div>

    )
}

export default CommentForm
