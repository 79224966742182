import React from 'react'
import { useState, useEffect, useContext } from "react";
import Row from 'react-bootstrap/Row';
import {useFetching} from '../../../hooks/useFetching'
import AuthService from '../../../services/AuthService';
import MessageContext from '../../../context/MessageContext';
import LoadingButton from '../../UI/Button/LoadingButton'
import Loader from '../../UI/Loader/Loader';
import SocialLinks from './SocialLinks';
import HelperText from '../../UI/HelperText/HelperText';
import BlackTooltip from '../../UI/BlackTooltip/BlackTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useSocialAuth from '../../SocialAuth/useSocialAuth';
import { IconGoogle, IconVK, IconFacebook } from '../../svg/icons';
import SubHead from 'components/UI/SubHead';


function UserSocials({toggleMobileMenu}) {

    const { setMessages } = useContext(MessageContext)
    const errorHandler = useErrorHandler(setMessages)

    const [googleAccount, setGoogleAccount] = useState(null)
    const [vkAccount, setVKAccount] = useState(null)
    const [facebookAccount, setFacebookAccount] = useState(null)

    const [connectGoogle, connectGoogleLoading] = useFetching(async (google_code) => {
        const response = await AuthService.GoogleConnect(google_code);
        handleGoodResponse(response)
    }, errorHandler)

    const [connectVK, connectVKLoading] = useFetching(async (code, redirectUri) => {
        const response = await AuthService.VKConnect(code, redirectUri);
        handleGoodResponse(response)
    }, errorHandler)
    
    const [connectFacebook, connectFacebookLoading] = useFetching(async (access_token) => {
        const response = await AuthService.FBConnect(access_token);
        handleGoodResponse(response)
    }, errorHandler)

    const [getSocial, getSocialLoading] = useFetching(async () => {
        const response = await AuthService.getSocial()
        handleGoodResponse(response)
    }, (e) => console.log('getSocial error', e) )



    const providers = {
        google : {
            slug: 'google',
            name: 'Google',
            icon: <IconGoogle height={'40px'}/>,
            method: connectGoogle,
            auth: ()=>openLoginPopup('google'),
            loading: connectGoogleLoading,
            class: "social-logo btn-google"
        },
        vk : {
            slug: 'vk',
            name: 'ВКонтакте',
            icon: <IconVK height={'28px'}/>,
            method: connectVK,
            auth: ()=>openLoginPopup('vk'),
            loading: connectVKLoading,
            class: "social-logo btn-vk"
        },
        facebook : {
            slug: 'facebook',
            name: 'Facebook',
            icon: <IconFacebook height={'40px'}/>,
            method: connectFacebook,
            auth: ()=>openLoginPopup('facebook'),
            loading: connectFacebookLoading,
            class: "social-logo btn-facebook"
        }
    }

    const openLoginPopup = useSocialAuth(providers)
    

    useEffect(() => {
        getSocial()
    }, [])


    function handleGoodResponse(response) {
        setSocialData(response, setGoogleAccount, setVKAccount, setFacebookAccount)
    }

    return (
        <>
            <SubHead title={'Cоциальные сети'} toggleMobileMenu={toggleMobileMenu} />
            <p className="mb-3">Прикрепив социальные сети, вы сможете использовать их для входа в аккаунт:</p>
            <Row className="social-cards-wrapper">
                <SocialCard 
                    provider={providers.google} 
                    userSocialAccount={googleAccount} 
                    setMessages={setMessages}
                    loading={getSocialLoading}
                    handleGoodResponse={handleGoodResponse}
                    errorHandler={errorHandler}
                />
                <SocialCard 
                    provider={providers.vk}
                    userSocialAccount={vkAccount} 
                    setMessages={setMessages}
                    loading={getSocialLoading}
                    handleGoodResponse={handleGoodResponse}
                    errorHandler={errorHandler}
                />
                <SocialCard 
                    provider={providers.facebook} 
                    userSocialAccount={facebookAccount} 
                    setMessages={setMessages}
                    loading={getSocialLoading}
                    handleGoodResponse={handleGoodResponse}
                    errorHandler={errorHandler}
                />
            </Row>
            <SocialLinks />
        </>
    )
}

export default UserSocials


const SocialCard = ({provider, userSocialAccount, loading, setMessages, handleGoodResponse, errorHandler}) => {

    const [deleteSocial, deleteLoading] = useFetching(async () => {
        const response = await AuthService.disconnectSocial(userSocialAccount.social_id)
        handleGoodResponse(response)
        setMessages([{level: 'success', message: `Привязка к ${provider.name} удалена`}])
    }, errorHandler)


    return (
        <div className="social-card box">
        <div className="social-card-content">
            <div className={provider.class}>
                {loading ? <Loader /> :
                <>
                    {userSocialAccount
                        ? <img src={userSocialAccount.picture} referrerPolicy="no-referrer"/>
                        : provider.icon
                    }
                </>
                }
            </div>
            <p className="text-bold">{userSocialAccount?.full_name}</p>
            <p className="mb-3">{provider.name}</p>
        </div>
        <div className="social-card-controls">
            {userSocialAccount
                ? 
                    <>
                        <LoadingButton 
                            isLoading={deleteLoading} 
                            onClick={deleteSocial} 
                            className="btn" 
                            disabled={!userSocialAccount.is_safe_disconnect}
                        >
                            Удалить
                        </LoadingButton>
                        {userSocialAccount.is_safe_disconnect === false &&
                            <BlackTooltip title="Для удаления всех социальных аккаунтов, необходимо наличие подтвержденного почтового ящика и пароля">
                                <InfoOutlinedIcon fontSize='inherit' className='ms-2'/>
                            </BlackTooltip>
                        }

                    </>      
                :   <LoadingButton className="btn" onClick={provider.auth} isLoading={provider.loading} >
                        Подключить
                    </LoadingButton>
            }
        </div>
        </div>
    )
}



function setSocialData(response, setGoogle, setVK, setFacebook) {

    if (response.data.google) {
        setGoogle(response.data.google)
    } else {
        setGoogle(null)
    }
    if (response.data.vk) {
        setVK(response.data.vk)
    } else {
        setVK(null)
    }
    if (response.data.facebook) {
        setFacebook(response.data.facebook)
    } else {
        setFacebook(null)
    }
}
