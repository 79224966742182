import React from 'react'
import Container from 'react-bootstrap/Container';
import Button from '../components/UI/Button/Button'
import UserService from '../services/UserService';
import MessageContext from '../context/MessageContext';
import InputErrorMsg from '../components/InputErrorMsg';
import { useInput } from '../hooks/useInput';
import { useFetching } from '../hooks/useFetching';
import { useEffect, useContext } from "react";
import { setFormsErrors } from '../hooks/useInput'

function PasswordResetSend() {
    const { setMessages } = useContext(MessageContext)

    const userEmail = useInput('', 'email', 'email', {notEmpty: true, isEmail: true})

    async function Submit(e) {
        e.preventDefault()
        const isUserEmailValid = userEmail.StartValidation()
        if (isUserEmailValid) {
            sendEmail()
        }
    }

    const [sendEmail, isSendEmailLoading, sendEmailError] = useFetching(async () => {
        const formData = new FormData();
        formData.append('email', userEmail.value)
        const response = await UserService.sendResetEmail(formData)

        // On success
        handleResponse(response)
    })

    function handleResponse(response) {
        if (response?.data?.detail?.length > 0) {
            setMessages([{level: 'success', message: response?.data?.detail}])
        }
    }

    useEffect(() => {

        if(sendEmailError?.data) {
            let errors = [sendEmailError?.data]
            let fields = [userEmail]

            setFormsErrors(fields, errors)
        }

    }, [sendEmailError])

    return (
        <>
        <div className="logo-header">
            <h3>MaddyFit</h3>
        </div>
        <Container style={{maxWidth: '540px'}}>
            <div className="page-title">
                <h4>Сброс пароля</h4>
            </div>
            <div className="box mt-3">
                <div className='p-4'>
                    <form onSubmit={e => Submit(e)}>
                        <p>Введите почтовый ящик, привязанный к аккаунту, и вам будет отправлено письмо для восстановления пароля.</p>
                        <input 
                        type="email"
                        className="form-control fullwidth mt-4"
                        required
                        value={userEmail.value} 
                        onChange={e => userEmail.onChange(e)} 
                        />
                        <InputErrorMsg field={userEmail}/>
                        <Button type='submit' className="btn btn-success fullwidth mt-4">Отправить</Button>
                    </form>
                </div>
            </div>
        </Container>
        </>
    )
}

export default PasswordResetSend
