
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import StepLink from 'components/Study/StepLink';


function VideoPagination({lessons, lesson, steps, step, isLoading, paginationType, toggleMobileLessonMenu}) {

    let { course_number, lesson_number, step_number } = useParams();
    const navigate = useNavigate()

    const [nextLink, setNextLink] = useState(null)
    const [prevLink, setPrevLink] = useState(null)

    
    useEffect(() => {

        if (step == null || lesson == null || isLoading) {
            setNextLink( null )
            setPrevLink( null )
            return;
        }
        
        const nextLesson = lessons.find(el => el.number == lesson.number + 1)
        const prevLesson = lessons.find(el => el.number == lesson.number - 1)

        
        if (step.hasNext) {
            setNextLink(`/study/${course_number}/${lesson_number}/${parseInt(step_number) + 1}`)
        } else if (nextLesson?.progress?.is_opened) {
            if (step_number) {
                setNextLink(`/study/${course_number}/${nextLesson.number}/1`)
            } else {
                setNextLink(`/study/${course_number}/${nextLesson.number}`)
            }
        }
        if (step.hasPrev) {
            setPrevLink(`/study/${course_number}/${lesson_number}/${parseInt(step_number) - 1}`)
        } else if (prevLesson?.progress?.is_opened) {
            if (step_number) {
                setPrevLink(`/study/${course_number}/${prevLesson.number}/${prevLesson.steps_count}`)
            } else {
                setPrevLink(`/study/${course_number}/${prevLesson.number}`)
            }
        }
    }, [steps, step, isLoading, lessons, lesson])

    if (lesson == null || step == null) { return null }

    return (
        <div className="video-pagination" data-pagination-type={paginationType.toLowerCase()}>
            <Button 
                className='btn prev-next-video-btn'
                onClick={() => navigate(prevLink)}
                disabled={prevLink == null}>
                Назад
            </Button>
            <Button 
                className='btn prev-next-video-btn lessons-mobile-menu-btn'
                onClick={toggleMobileLessonMenu}>
                <i className="fa-solid fa-list-check"></i>
            </Button>
            <div className="steps-icons">
                {steps.map(_step => 
                    <StepLink 
                        key={_step.id} 
                        step={_step}
                        className={'btn video-nav-btn'}
                    />
                )}
            </div>
            <Button 
                className='btn prev-next-video-btn'
                onClick={() => navigate(nextLink)}
                disabled={nextLink == null}>
                Далее
            </Button>
        </div>
    )
}

export default VideoPagination


function Pagination(steps, step, isLoading, toggleMobileLessonMenu, lessons, lesson, paginationType) {
    let { course_number, lesson_number, step_number } = useParams();
    const navigate = useNavigate()

    const [nextLink, setNextLink] = useState(null)
    const [prevLink, setPrevLink] = useState(null)

    useEffect(() => {

        const nextLesson = lessons.find(el => el.number == lesson.number + 1)
        const prevLesson = lessons.find(el => el.number == lesson.number - 1)

        if (step == null || isLoading) {
            setNextLink( null )
            setPrevLink( null )
        } else if (step.hasNext) {
            setNextLink(`/study/${course_number}/${lesson_number}/${parseInt(step_number) + 1}`)
        } else if (nextLesson?.progress?.is_opened) {
            if (step_number) {
                setNextLink(`/study/${course_number}/${nextLesson.number}/1`)
            } else {
                setNextLink(`/study/${course_number}/${nextLesson.number}`)
            }
        }

        if (step.hasPrev) {
            setPrevLink(`/study/${course_number}/${lesson_number}/${parseInt(step_number) - 1}`)
        } else if (prevLesson?.progress?.is_opened) {
            if (step_number) {
                setPrevLink(`/study/${course_number}/${prevLesson.number}/${prevLesson.steps_count}`)
            } else {
                setPrevLink(`/study/${course_number}/${prevLesson.number}`)
            }
        }
    }, [steps, step, isLoading, lessons, lesson])


    return (
        <div className="video-pagination" data-pagination-type={paginationType.toLowerCase()}>
            <Button 
                className='btn prev-next-video-btn'
                onClick={() => navigate(prevLink)}
                disabled={prevLink == null}>
                Назад
            </Button>
            <Button 
                className='btn prev-next-video-btn lessons-mobile-menu-btn'
                onClick={toggleMobileLessonMenu}>
                <i className="fa-solid fa-list-check"></i>
            </Button>
            <div className="steps-icons">
                {steps.map(_step => 
                    <StepLink 
                        key={_step.id} 
                        step={_step}
                        className={'btn video-nav-btn'}
                        activeClassName={'active-video'}
                    />
                )}
            </div>
            <Button 
                className='btn prev-next-video-btn'
                onClick={() => navigate(nextLink)}
                disabled={nextLink == null}>
                Далее
            </Button>
        </div>
    )
}