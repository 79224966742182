import React from 'react'
import { Editor } from '@tinymce/tinymce-react';

function PostEditor() {

    const handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
    }

    return (
        <Editor
            initialValue={text}
            init={{
            height: 500,
            menubar: true,
            plugins: [
                'advlist autolink lists link image', 
                'charmap print preview anchor help',
                'searchreplace visualblocks code',
                'insertdatetime media table paste wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help'
            }}
            onChange={handleEditorChange}
        />
    )
}

export default PostEditor


const text = `<section className='headline'>
<div className='headline__stamps'>
    <span className='content__author'>
        <img alt="" src="https://wylsa.com/wp-content/uploads/2020/10/snapseed-2-scaled-96x96.jpg"/>
        <a className="content__author_link" href="https://wylsa.com/author/albahn/">Илья Кичаев</a>
    </span>
    <span className='stamp-date'>
        <ClockIcon />
        2 дня назад 
    </span>
    <span className='stamp-comments'>
        <ChatIcon />
        10
    </span>
    <span className='stamp-views'>
        <ViewsIcon />
        5279
    </span>
</div>
<h1 className='main-title'>{post.title}</h1>
<p className='sub-title'>Игры полезнее, чем вы думаете.</p>
</section>
<section className="article__img" style={{backgroundImage: "url('https://wylsa.com/wp-content/uploads/2021/12/games.jpg')"}}>
</section>

<section className='content'>
<div className='content__inner'>
    <p>Компания Samsung в ближайшие несколько лет переведёт свои смартфоны c Android на Fuchsia OS. Об этом сообщают сразу несколько авторитетных инсайдеров, в том числе Ice Universe и Дохён Ким.</p>
    <h2 id="content">Что за Fuchsia OS?</h2>
    <p>Это универсальная операционная система, которую разрабатывает Google с 2016 года. Да, Android тоже разрабатывается Google. Но у Fuchsia OS есть ряд преимуществ.</p>
    <p>Система построена на микроядре Zircon, а не на ядре Linux, как Android или Chrome OS. Это означает, что на запуск приложений будет уходить меньше ресурсов.</p>
    <figure id="">
        <img loading="lazy" className="wp-image-100752 size-full" src="https://wylsa.com/wp-content/uploads/2020/03/google-nest-hub.jpg" alt="" />
        <figcaption id="caption-attachment-100752" className="wp-caption-text">Google Nest Hub. Источник: setGoogle</figcaption>
    </figure>

</div>
<div className='content__footer'>
    <div className='source'>
        <p className='text-bold me-2'>Источник:</p>
        <p><a href="https://twitter.com/UniverseIce/status/1473530810221273090" target="_blank">Ice Universe</a></p>                    
    </div>
    <div className='share-icons'>
        <span className='share-icon-vk'><IconVK /></span>
        <span className='share-icon-facebook'><IconFacebook /></span>
        <span className='share-icon-twitter'><IconTwitter /></span>
        <span className='share-icon-telegram'><IconTelegram /></span>
        <span className='share-icon-odnoklassniki'><IconOdnoklassniki /></span>
    </div>
</div>
</section>`