import { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import Badge from '../UI/Badge/Badge';

function NotificationsCount() {
    const { isAuth } = useContext(AuthContext);
    const [count, setCount] = useState(0)
    const socket = useRef()
    const [connected, setConnected] = useState(false)
    const roomName = 'default';

    useEffect(() => {
        if (isAuth && !connected) {
            connectNotificationWebSocket()
        } else if (!isAuth  && connected) {
            socket.current.close()
        }
    }, [isAuth])

    const connectNotificationWebSocket = () => {
        socket.current = new WebSocket(
            process.env.REACT_APP_WEBSOCKET_URL
            + '/ws/notification/'
            + roomName
            + '/'
        );

        socket.current.onopen = function(e) {
            setConnected(true)
        };
    
        socket.current.onmessage = function(e) {
            const data = JSON.parse(e.data);
            if (data?.notifications_count) {
                setCount(data.notifications_count)
            }
        };

        socket.current.onclose = function(e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setConnected(false)
            // setTimeout(function() {
            // connectNotificationWebSocket();
            // }, 1000);
        };
        
        socket.current.onerror = function(err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            socket.current.close();
            setConnected(false)
        };
    }

    // if (count < 1) { return null } 

    // return <Badge className='notification-badge'>{count}</Badge>
    return count > 0 ? count : ''
}

export default NotificationsCount;
