export default function ({ clientId, state, scope, redirectUri, responseType = "code" }) {
  let current = encodeURIComponent(window.location.href);
  const base = `https://oauth.vk.com/authorize?response_type=${responseType}`;
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );
  const display = mobile ? "touch" : "popup";
  const fullScope =
    scope && scope.length ? `&scope=${encodeURIComponent(scope)}` : "";
  return `${base}&client_id=${clientId}&redirect_uri=${redirectUri}&display=${display}&state=${state}${fullScope}`;
}