import React, { createContext, useState, useEffect } from 'react'
import { useFetching } from '../../hooks/useFetching'
import PostComment from './PostComment'
import PostCommentForm from './PostCommentForm'
import PostService from '../../services/PostService'
import { useQuery } from '../../hooks/useQuery'
import { declOfNum } from '../../utils/Common'
import { updateComment } from '../Comments/utils'
import { updateItemsInArray } from '../../utils/Array'


export const PostCommentsContext = createContext(null)

function PostComments({post_id}) {

    const [comments, setComments] = useState([])
    const [highlighted , setHighlighted] = useState(null)

    const [users, setUsers] = useState([])

    let query = useQuery();

    useEffect(() => {
        fetchComments(post_id)
        setHighlighted(query.get('comment'))
    }, [])


    const [fetchComments] = useFetching(async (post_id) => {
        const response = await PostService.getComments(post_id)
        setComments(response.data.comments)
        setUsers(response.data.users)
    })

    const [deleteComment] = useFetching(async (comment) => {
        const response = await PostService.deleteComment(comment.id)
        if (response && response.status === 200) {
            updateItemsInArray(response.data, comments, setComments)
        }
    })


    let context = {
        comments: comments,
        setComments: setComments,
        users: users,
        setUsers: setUsers,
        deleteComment: deleteComment,
        highlighted: highlighted,
    }

    return (
        <PostCommentsContext.Provider value={context}>
        <div className='post-comments' id="comments">
            <div className='post-comments-head'>
                <p className="post-comments-count">{comments.length} {declOfNum(comments.length, ['комментарий', 'комментария', 'комментариев'])}</p>
            </div>
            <div className='post-comments-new mb-4'>
                <PostCommentForm mode={'new'} post_id={post_id}/>
            </div>
            <div className='post-comments-all'>
                {comments.length > 0 && comments.map(_comment => {
                    if (!_comment.thread) {
                        return (
                            <div className="post-comment-thread" key={_comment.id}>
                                <PostComment comment={_comment}/>
                                <div className="post-comment-replies">
                                    {_comment.descendants.length > 0 &&
                                        _comment.descendants.map(_descendant_id => {
                                                const result = comments.find(_item => _item.id == _descendant_id)
                                                if (result) {
                                                    return <PostComment key={result.id} comment={result}/>
                                                }
                                            }
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
        </PostCommentsContext.Provider>

    )
}

export default PostComments
