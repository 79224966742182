import { IconButton } from '@mui/material';
import { useState, useEffect, useContext, useRef } from 'react';
import CommentForm from './CommentForm';
import { CommentsContext } from './Comments';
import CommentPlayer from '../Player/CommentPlayer';
import Avatar from '../UI/Avatar/Avatar';
import { DropdownMenu, DropdownItem } from '../UI/Dropdown/DropdownMenu'
// import DOMPurify from 'dompurify'
import { useToggle } from '../../hooks/useToggle';
import CommentText from '../Comments/CommentText';
import { OptionsIcon, LikeIcon, VideoIcon, ReplyIcon } from '../svg/icons';
import { goToComment } from '../Comments/utils';

function Comment({comment}) {
  const { users, deleteComment, likeComment, highlighted } = useContext(CommentsContext)
  const [user, setUser] = useState(null)
  const [replyForm, toggleReplyForm] = useToggle(false)
  const [editForm, toggleEditForm] = useToggle(false)
  const [showVideo, setShowVideo] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [isHighlight, setIsHighlight] = useState(false)
  const commentRef = useRef(null)

  useEffect(() => {
    if (highlighted == comment.id) {
      setIsHighlight(true)
      goToComment(commentRef)
    }
  }, [])

  useEffect(() => {
    if (users.length > 0 ) {
      const found = users.find(_user => _user.id === comment.author)
      if (found) { setUser(found) }
    }
  }, [users])

  if (comment.is_deleted) {
    return (
      <div className='comment-wrapper' style={{width: `${comment.thread && '95%'}`}}>
          <div className="comment-card comment-card-deleted">
            Комментарий удален
          </div>
      </div>
    )
  }

  const handleCommentClick = () => {
    if (isHighlight) {
      setIsHighlight(false)
    }
  }

  return (
      user &&
      <div className="comment-wrapper" style={{width: `${comment.thread && '95%'}`}}>
          {!editForm &&
          <div ref={commentRef} onClick={handleCommentClick} className={`comment-card ${isHighlight ? 'comment-highlighted' : ''}`} id={`comment${comment.id}`}>
              {/* <div className='comment-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text), }}/> */}
              {/* <div className='comment-text' dangerouslySetInnerHTML={{ __html: text, }}/> */}
              <CommentText text={comment.text} className={'comment-text'}/>
              <div className="comment-card-footer">
                  <div className="comment-info">
                    <Avatar src={user.profile_image} showDefault className='comment-avatar-sm'/>
                    <p>{user.full_name}</p>
                  </div>
                  <div className="comment-controls">
                      <p>{comment.likes > 0 && comment.likes}</p>
                      <IconButton onClick={() => likeComment(comment.id)}>
                          <LikeIcon className={comment.is_liked ? 'liked' : 'icon'}/>
                      </IconButton>
                      <IconButton onClick={()=>setShowVideo(true)}>
                        <VideoIcon className={comment.video ? 'has-video' : 'icon'}/>
                      </IconButton>
                      <IconButton onClick={toggleReplyForm}>
                        <ReplyIcon className='icon'/>
                      </IconButton>
                      {(comment.can_edit || comment.can_delete) && 
                        <>
                          <IconButton
                            onClick={e => setDropDown(!dropDown)}
                            style={{zIndex: '81'}}>
                              <OptionsIcon className='icon'/>
                          </IconButton>
                          <span className='dropdown-parent'>
                            <DropdownMainMenu 
                              isOpen={dropDown}
                              setOpen={setDropDown} 
                              editComment={()=>toggleEditForm(true)}
                              removeComment={()=>deleteComment(comment.id)}
                            />
                          </span>
                        </>
                      }
                  </div>
              </div>
          </div>
          }
          {editForm &&
            <CommentForm 
              mode={'edit'}
              self={comment} 
              toggleForm={toggleEditForm}
            />
          }
          {replyForm &&
            <div className="comment-reply-form">
              <CommentForm 
                mode={'reply'}
                parent={comment}
                toggleForm={toggleReplyForm}
              />
            </div>
          }
          <CommentPlayer comment={comment} isOpen={showVideo} setOpen={setShowVideo}/>
      </div>
  )
}

export default Comment


function DropdownMainMenu({isOpen, setOpen, editComment, removeComment}) {
  return (
      <DropdownMenu isOpen={isOpen} setOpen={setOpen} className={'post-comment-dropdown'}>
          <DropdownItem className={'small'} action={editComment} setOpen={setOpen}>Редактировать</DropdownItem>
          <DropdownItem className={'small'} action={removeComment} setOpen={setOpen}>Удалить</DropdownItem>
      </DropdownMenu>
  );
}




