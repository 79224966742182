import { useCallback } from 'react'

function useErrorHandler(setMessages) {

    const errorHandler = useCallback(async (errorResponse) => {
        console.log('errorHandler errorResponse', errorResponse)
        if(errorResponse?.data) {
            let data = errorResponse.data
            if (Array.isArray(data)) {
                if (data.every(i => (typeof i === 'string'))) {
                    let _messages = []
                    for (const index in data) {
                        _messages.push({level: 'error', message: data[index]})
                    }
                    setMessages(_messages)
                }
            } else if (data?.detail) {
                setMessages({level: 'error', message: data.detail})
            } else if (data?.message) {
                setMessages({level: 'error', message: data.message})
            } else if (data?.non_field_errors) {
                let _errors = data.non_field_errors
                let _messages = []
                for (const index in _errors) {
                    _messages.push({level: 'error', message: _errors[index]})
                }
                setMessages(_messages)
            }
        }

    }, [setMessages]);

    return errorHandler
}

export default useErrorHandler