import React from 'react'
import { useState, useEffect, useContext } from "react";
import UserService from '../../services/UserService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useInput } from '../../hooks/useInput';
import { useFetching } from '../../hooks/useFetching';
import InputErrorMsg from '../InputErrorMsg';
import BlackTooltip from '../UI/BlackTooltip/BlackTooltip';
import MessageContext from '../../context/MessageContext';
import SubHead from 'components/UI/SubHead';

function UserEmails({toggleMobileMenu}) {


    const { setMessages } = useContext(MessageContext)

    const [emails, setEmails] = useState([])
    const [selectEmail, setSelectEmail] = useState({})
    const newEmail = useInput('', 'email', 'email', {notEmpty: true, isEmail: true, minLength: 3, maxLength: 50})

    useEffect(() => {
        GetUserEmails()
    }, [])


    async function GetUserEmails() {
        const response = await UserService.getEmails()
        setEmails(response.data)
        for (let email of response.data) {
            if (email.primary) {
                setSelectEmail(email)
            }
        }
    }


    async function AddNewEmail(e) {
        e.preventDefault()
        const isValid = newEmail.StartValidation()
        if (isValid) {
            addNewEmail(newEmail.value)
        }
    }

    // Add new email
    const [addNewEmail, isNewEmailLoading, newEmailError] = useFetching(async (email) => {
        const formData = new FormData();
        formData.append('action_add', '')
        formData.append('email', email)
        const response = await UserService.emailAction(formData)
        handleResponse(response)
    })

    useEffect(() => {
        if (newEmailError?.data?.form_errors) {
            newEmail.setManualValid(false)
            let errors = newEmailError.data.form_errors['email']
            let _messages = []
            errors.forEach((error) => {
                _messages = [..._messages, error.message]
            })
            newEmail.setFormErrors(_messages)
        }
    }, [newEmailError])

    
    // Send confirmation email, make email primary, remove email
    const [emailAction, isEmailActionLoading, emailActionError] = useFetching(async (action) => {
        const formData = new FormData();
        formData.append(action, '')
        formData.append('email', selectEmail.email)
        const response = await UserService.emailAction(formData)
        handleResponse(response)
    })


    function handleResponse(response) {
        if (response?.data?.emails?.length > 0) {
            setEmails(response.data.emails)
        }
        if (response?.data?.messages?.length > 0) {
            setMessages(response.data.messages)
        }
    }


    return (

        <div>
            <SubHead title={'Моя почта'} toggleMobileMenu={toggleMobileMenu} />
            <p className="mb-3">Следующие e-mail адреса прикреплены к вашему профилю:</p>
            <ul id="settings-emails" className="box list-style-none">
                {emails.map(email => 
                    <li key={email.id} className="box-row">
                    <div>
                        <input style={{paddingTop: '5px'}} type="radio" name="email" defaultChecked={email.primary} value={email.email}
                        onChange={(e) => {setSelectEmail(email)}}/>
                        <span className="text-bold ms-1" >{email.email}</span>
                    </div>
                    <ul className="mt-2 ms-4 color-default" style={{listStyle: 'disc'}}>
                        {email.primary &&
                            <li>
                            <span>
                            <span>Основной </span>
                            <BlackTooltip title="Основной почтовый ящик может быть использован для получения соообщений об аккаунте и восстановления пароля">
                                <InfoOutlinedIcon fontSize='inherit'/>
                            </BlackTooltip>
                            </span>
                            </li>
                        }
                        
                        <li>
                        {email.verified
                            ?   <span>Подтверждён </span>
                            :   <span className="color-warning">Не подтверждён </span>
                        }
                        <BlackTooltip title="Неподтвержденный почтовый ящик не может быть использован для восстановления пароля">
                            <span><InfoOutlinedIcon fontSize='inherit'/></span>
                        </BlackTooltip>
                        </li>
                    </ul> 
                    </li>
                )}

            </ul>
            <div className="buttonHolder mt-3">
                <button className="btn me-2 mb-1" onClick={() => {emailAction('action_primary')}}>Сделать основным</button>
                <button className="btn me-2 mb-1" onClick={() => {emailAction('action_send')}}>Отправить письмо для подтверждения</button>
                <button className="btn me-2 mb-1" onClick={() => {emailAction('action_remove')}}>Удалить</button>
            </div>

            <hr className="my-3"/>

            <form className="add_email">
                <dl className="form-group">
                <dt className="mb-2"><label className="text-bold" htmlFor="inputFirstName">Добавить e-mail адрес</label></dt>
                <dd>
                    <input type="email" placeholder="E-mail адрес" value={newEmail.value} className="form-control short" onChange={e => newEmail.onChange(e)} required/>
                    <InputErrorMsg field={newEmail}/>
                    <button onClick={AddNewEmail} className="btn block mt-3" name="action_add" type="submit">Добавить e-mail</button>
                </dd>
                </dl>
            </form>
        </div>

    )
}

export default UserEmails


const InfoIcon = () => (
    <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-info">
        <path fillRule="evenodd" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm6.5-.25A.75.75 0 017.25 7h1a.75.75 0 01.75.75v2.75h.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.25v-2h-.25a.75.75 0 01-.75-.75zM8 6a1 1 0 100-2 1 1 0 000 2z"></path>
    </svg>
);