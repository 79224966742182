import '../styles/pages/MySettings.css'
import { useContext } from "react";
import AuthContext from '../context/AuthContext';
import {Container, Col} from 'react-bootstrap';
import Avatar from '../components/UI/Avatar/Avatar';
import UserInfo from '../components/ProfileSettings/UserInfo';
import UserEmails from '../components/ProfileSettings/UserEmails'
import UserSocials from '../components/ProfileSettings/UserSocials/UserSocials'
import UserPassword from '../components/ProfileSettings/UserPassword'
import Notifications from '../components/ProfileSettings/Notifications';
import { useToggle } from '../hooks/useToggle';

import { Routes, Route, NavLink, Link } from "react-router-dom";

import NavMenu from '../components/UI/NavMenu/NavMenu';
import NavMenuMobile from '../components/UI/NavMenu/NavMenuMobile';


function MySettings() {
    const { user } = useContext(AuthContext);
    const [showMobileMenu, toggleMobileMenu] = useToggle(false)

    if (user === null) {
        return null
    }

    return (
        
        <Container className="pt-3">
            <div className="d-flex align-items-center mb-3 justify-content-between flex-wrap">
                <div className='mb-2 mb-md-0'>
                    <div className='d-flex flex-nowrap align-items-center'>
                        <Avatar className='avatar-sm me-2' alt={user.full_name} src={user.profile_image} showDefault={true}/>
                        <div>
                            {user && <h5 className='text-no-wrap'>{user.full_name}</h5>}
                            <p className='text-small text-no-wrap'>Ваш личный аккаунт</p>
                        </div>
                    </div>
                </div>
                <Link to={`/profile/${user.id}`} className='btn'>Открыть личную страницу</Link>
            </div>
            <div className='d-flex flex-direction-row'>
                <Col xs={12} md={3} className="pe-md-4 settings-menu-default">
                    <NavMenu title={'Настройки аккаунта'}>
                        <MySettingsLinks className={'nav-menu-item'}/>
                    </NavMenu>
                </Col>

                <Col xs={12} md={9}>
                    <Routes>
                        <Route path={`info`} element={<UserInfo toggleMobileMenu={toggleMobileMenu} />} />
                        <Route path={`email`} element={<UserEmails toggleMobileMenu={toggleMobileMenu} />} />
                        <Route path={`social`} element={<UserSocials toggleMobileMenu={toggleMobileMenu} />} />
                        <Route path={`password`} element={<UserPassword toggleMobileMenu={toggleMobileMenu} />} />
                        <Route path={`notifications`} element={<Notifications toggleMobileMenu={toggleMobileMenu} />} />
                    </Routes>
                </Col>
            </div>
            <NavMenuMobile title={'Настройки аккаунта'} isOpen={showMobileMenu} setOpen={toggleMobileMenu}>
                <MySettingsLinks className={'nav-menu-item-mobile'}/>
            </NavMenuMobile>
        </Container>
    )
}

export default MySettings


const MySettingsLinks = ({className}) => {
    return (
        <>
        <NavLink to={`info`} className={className}>Моя информация</NavLink>
        <NavLink to={`email`} className={className}>Почта</NavLink>
        <NavLink to={`social`} className={className}>Социальные сети</NavLink>
        <NavLink to={`password`} className={className}>Изменить пароль</NavLink>
        <NavLink to={`notifications`} className={className}>Уведомления на почту</NavLink>
        </>
    )
}