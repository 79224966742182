import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Button from '../UI/Button/Button'
import { useState, useEffect, useCallback } from 'react';
import { DropdownMenu, DropdownItem } from '../UI/Dropdown/DropdownMenu';
import { useFetching } from '../../hooks/useFetching';
import CourseService from '../../services/CourseService';
import { Link } from 'react-router-dom';
import Modal from '../UI/Modal/Modal';
import SubHead from 'components/UI/SubHead';

function MyCourses({toggleMobileMenu}) {
    
    const [userCourses, setUserCourses] = useState([])

    useEffect(() => {
        getCourses()
    }, [])

    const [getCourses, isGetCoursesLoading, getCoursesError] = useFetching(async () => {
        const response = await CourseService.getUserCourses()
        handleResponse(response)
    })

    function handleResponse(response) {
        setUserCourses(response.data)
    }

    const ExtendCourse = useCallback((course) => {
    }, []);

    return (
        <>
        <SubHead title={'Мои курсы'} toggleMobileMenu={toggleMobileMenu}/>
        <Row className="social-cards-wrapper">
            {userCourses.map(userCourse => (
                <CatalogCard 
                    key={userCourse.id}
                    userCourse={userCourse} 
                    ExtendCourse={ExtendCourse}
                />
            ))}
        </Row>
        </>
        
    )
}

export default MyCourses


const CatalogCard = ({userCourse, ExtendCourse}) => {
    const course_info = userCourse.course_info
    const progress = userCourse.progress
    const [dropDown, setDropDown] = useState(false);
    const expire_date = new Date(userCourse.expire_date).toLocaleDateString('ru-RU')
    const [showExtendMenu, setShowExtendMenu] = useState(false)

    const onExtendClicked = useCallback(() => {
        setShowExtendMenu(true)
    }, []);

    const closeExtendDialog = useCallback(() => {
        setShowExtendMenu(false)
    }, []);


    return (
        <Col xs='auto' className='mb-3'>
            <div className='box catalog-card'>
                <div className='catalog-card-description'>
                    <div className='me-2'>
                        <h5>{course_info.title}</h5>
                        {/* <p>{course_info.description}</p> */}
                    </div>
                    <div >
                        <img className='course-image-small' src={course_info.icon_sm}/>
                    </div>
                </div>
                <div>
                    <p className='mb-3'>{progress.lessons_opened}/{course_info.lessons_count}</p>
                    <div className='catalog-card-controls'>
                        {/* <Button className='btn'>Продолжить</Button> */}
                        {/* <Link to={'#'} className='btn'>Продолжить</Link> */}
                        <Link to={`/study/${progress.continue_url}`} className='btn'>
                            Продолжить
                        </Link>
                        <div onClick={e => setDropDown(!dropDown)} className='catalog-card-expire-date dropdown-parent'>
                            <div className='text-small dropdown-button'>до {expire_date}&nbsp;
                                <i className="fas fa-angle-double-down"></i>
                            </div>
                            <CatalogCardMenu isOpen={dropDown} setOpen={setDropDown} action={onExtendClicked}/>
                        </div>
                    </div>
                </div>
            </div>
            <ExtendModal isOpen={showExtendMenu} toClose={closeExtendDialog} courseInfo={course_info}/>
        </Col>
    )
}

const ExtendModal = ({isOpen, toClose, courseInfo}) => (
    <Modal isOpen={isOpen} setOpen={toClose}>
        <div className='extend-course-modal'>
            <div className='extend-course-modal-content'>
                <h3 className='mb-4'>{courseInfo.title}</h3>
                <div className='extend-course-modal-lines'>
                    <div>
                        <p>Продлить до:</p>
                        <p>23.07.2021</p>
                    </div>
                    <hr className='my-3'></hr>
                    <div>
                        <p>Стоимость</p>
                        <p>25 000 KZT</p>
                    </div>
                    <div>
                        <p>Скидка за продление</p>
                        <p>35%</p>
                    </div>
                    <div>
                        <p>К оплате</p>
                        <p>21 000 KZT</p>
                    </div>
                </div>

            </div>
            <Button className='btn'>Оплатить</Button>
        </div>
    </Modal>
)

const CatalogCardMenu = ({isOpen, setOpen, action}) => {
    return (
        <DropdownMenu isOpen={isOpen} setOpen={setOpen} className={'catalog-card-dropdown'} maxHeight={100}>
            <DropdownItem action={action}>Продлить</DropdownItem>
        </DropdownMenu>
    );
}