import { Container } from 'react-bootstrap';
import '../styles/pages/PostPage.css'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PostService from '../services/PostService'
import { useFetching } from '../hooks/useFetching'
import { useParams } from 'react-router-dom';
import BackdropLoader from '../components/UI/BackdropLoader/BackdropLoader';
import { getTimePastFromDate } from '../utils/Common';
import PostComments from '../components/Posts/PostComments';
import Avatar from '../components/UI/Avatar/Avatar';
import SocialShare from '../components/SocialShare/SocialShare';
import { ClockIcon, ChatIcon, ViewsIcon } from '../components/svg/icons';


function PostPage() {
    const params = useParams()
    const [post, setPost] = useState(null)

    useEffect(() => {
        fetchPost(params.slug)
    }, [])

    const [fetchPost, postLoading, postError] = useFetching(async (slug) => {
        const response = await PostService.getPost(slug)
        setPost(response.data)
    })
    
    if (postLoading) {
        return <BackdropLoader />
    }

    return (
        post && 
        <>
            <article>
                <Container className='article-container'>
                    <section className='article-headline'>
                        <div className='article-headline-stamps'>
                            <span className='stamp-author'>
                                <Avatar user={post.author} className={'avatar-xs me-2'}/>
                                <Link to={`/profile/${post.author.id}`}>{post.author.full_name}</Link>
                            </span>
                            <span className='stamp-date'>
                                <ClockIcon />
                                {getTimePastFromDate(post.date_created)}
                            </span>
                            <span className='stamp-comments'>
                                <ChatIcon />{post.comments}
                            </span>
                            <span className='stamp-views'>
                                <ViewsIcon />{post.views}
                            </span>
                        </div>
                        <h1 className='main-title'>{post.title}</h1>
                        <p className='sub-title'>{post.subtitle}</p>
                    </section>
                </Container>
                <Container className="article-banner-container">
                    <section className="article-banner" style={{backgroundImage: `url(${post.icon_lg})`}}>
                    </section>
                </Container>
                <Container className='article-container'>
                    <section className='article-content'>
                        <div className='article-content__inner' dangerouslySetInnerHTML={{ __html: `${post.text}`, }} >
                            
                        </div>
                        <div className='article-content__footer'>
                            <div className='source'>
                                {post.source_name &&
                                    <>
                                    <p className='text-bold me-2'>Источник:</p>
                                    <a href={post.source_link} target="_blank">{post.source_name}</a>  
                                    </>
                                }
                            </div>
                            <SocialShare instance={post}/>
                        </div>
                    </section>
                    
                </Container>
            </article>
            <PostComments post_id={post.id}/>
        </>
    )
}

export default PostPage