import { IconButton } from '@mui/material';
import { OptionsIcon } from 'components/svg/icons'
import Container from './Container';

function SubHead({title, toggleMobileMenu}) {
    return (
        <Container className="subhead">
            <h2 className="subhead-title">{title}</h2>
            <IconButton onClick={toggleMobileMenu}>
                <OptionsIcon className='icon subhead-mobile-menu-btn'/>
            </IconButton>
        </Container>
    )
}

export default SubHead