import React from 'react'
import { useState, useEffect, useContext } from "react";
import MessageContext from '../../context/MessageContext';
import Button from '../UI/Button/Button';
import { useInput } from '../../hooks/useInput';
import InputErrorMsg from '../../components/InputErrorMsg'
import {useFetching} from '../../hooks/useFetching'
import UserService from '../../services/UserService';
import { setFormsErrors } from '../../hooks/useInput';
import {Container, Row, Col} from 'react-bootstrap';
import SubHead from 'components/UI/SubHead';


function UserPassword({toggleMobileMenu}) {
    const { setMessages } = useContext(MessageContext)

    const oldPassword = useInput('', 'old_password', 'password', {notEmpty: false})
    const newPassword1 = useInput('', 'new_password1', 'password', {notEmpty: true, minLength: 6, maxLength: 50})
    const newPassword2 = useInput('', 'new_password2', 'password', {notEmpty: true, isMatch: newPassword1.value})

    async function UpdatePassword(e) {
        e.preventDefault()
        const isOldPasswordValid = oldPassword.StartValidation()
        const isNewPassword1 = newPassword1.StartValidation()
        const isNewPassword2 = newPassword2.StartValidation()
        if (isOldPasswordValid && isNewPassword1 && isNewPassword2) {
            changePassword()
        }
    }

    const [changePassword, isChangePasswordLoading, changePasswordError] = useFetching(async () => {
        const formData = new FormData();
        formData.append('old_password', oldPassword.value)
        formData.append('new_password1', newPassword1.value)
        formData.append('new_password2', newPassword2.value)
        const response = await UserService.changePassword(formData)

        // On success
        handleResponse(response)
    })

    function handleResponse(response) {
        if (response?.data?.detail?.length > 0) {
            setMessages([{level: 'success', message: response?.data?.detail}])
        }
    }

    useEffect(() => {

        if(changePasswordError?.data) {
            let errors = changePasswordError?.data
            let fields = [oldPassword, newPassword1, newPassword2]
            setFormsErrors(fields, errors)
        }

    }, [changePasswordError])

    return (
        <div>
            <SubHead title={'Изменить пароль'} toggleMobileMenu={toggleMobileMenu} />
            {/* <div className="subhead">
                <h2 className="subhead-title">Изменить пароль</h2>
            </div> */}
            <form className="profile-form" onSubmit={e => UpdatePassword(e)}>

                <dl className="form-group">
                    <dt><label className="form-label" htmlFor="inputOldPassword">Старый пароль</label></dt>
                    <dd>
                        <input 
                        type="password" 
                        id="inputOldPassword" 
                        className="form-control medium" 
                        value={oldPassword.value} 
                        onChange={e => oldPassword.onChange(e)} 
                        required/>
                    </dd>
                    <InputErrorMsg field={oldPassword}/>
                </dl>
                <dl className="form-group">
                    <dt><label className="form-label" htmlFor="inputNewPassword1">Новый пароль</label></dt>
                    <dd>
                        <input 
                        type="password" 
                        id="inputNewPassword1" 
                        className="form-control medium" 
                        value={newPassword1.value} 
                        onChange={e => newPassword1.onChange(e)} 
                        required/>
                    </dd>
                    <InputErrorMsg field={newPassword1}/>
                </dl>
                <dl className="form-group">
                    <dt><label className="form-label" htmlFor="inputNewPassword2">Повторите новый пароль</label></dt>
                    <dd>
                        <input 
                        type="password" 
                        id="inputNewPassword2" 
                        className="form-control medium" 
                        value={newPassword2.value} 
                        onChange={e => newPassword2.onChange(e)} 
                        required/>
                    </dd>
                    <InputErrorMsg field={newPassword2}/>
                    <div className="note">
                        Длина пароля должна быть не менее 8 символов.
                    </div>
                </dl>

                <Row className='align-items-center'>
                <Col sm="auto"><Button type="submit" className="btn mb-1">Обновить пароль</Button></Col>
                    <Col><a href="/password_reset">Я забыл(а) свой пароль</a></Col>
                </Row>
            </form>
            
        </div>
    )
}

export default UserPassword
