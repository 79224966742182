import { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { useSize } from 'hooks/useSize';
import './CourseCard.css'

function CourseCard({course}) {
    const bodyRef = useRef(null)
    const bodySize = useSize(bodyRef);
    const [bottomMargin, setBottomMargin] = useState(0)

    // useEffect(() => {
    //     if (bodySize) {
    //         setBottomMargin(bodySize.bottom)
    //     }
    // }, [bodySize])

    return (
        // <div key={course.id} className='course-card' style={{ marginBottom: bottomMargin }}>
        <div key={course.id} className='course-card'>
            <div className="course-card-banner-wrapper" >
                <img src={course.icon_lg} alt="..."/>
            </div>
            <div className="course-card-body" ref={bodyRef}>
                <p className='course-card-title' dangerouslySetInnerHTML={{ __html: course.title }}></p>
                <p className='course-card-description'>{course.description}</p>
                <Link to={`/course/${course.slug}`}>Детали <i className="fa-solid fa-arrow-right-long"></i></Link>
            </div>
        </div>
    )
}

export default CourseCard