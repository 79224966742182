import { FormatColorReset } from '@mui/icons-material';
import RippleNavLink from 'components/UI/RippleLink/RippleNavLink';
import { useParams, useLocation } from "react-router-dom";


function LessonNavMenu({course, lessons}) {
    const { pathname } = useLocation()

    return (
        <>
        <h5>{course.title}</h5>
        <hr className="mb-2"/>
        <div className='lesson-nav-bar'>
            <RippleNavLink 
                to={`/study/${course.slug}/start`}
                className={'btn lesson-nav-btn'}
                disableRipple={false}
                isActive={`/study/${course.slug}/start` == pathname}
            >
                Начало
            </RippleNavLink>
            {lessons.length > 0 && lessons.map(_lesson => 
                <LessonLink 
                    key={_lesson.id}
                    course={course}
                    lesson={_lesson}
                />
            )}
            {course.progress.is_passed
                ?
                    <RippleNavLink 
                    to={`/study/${course.slug}/finish`}
                    className={'btn lesson-nav-btn'}
                    disableRipple={false}
                    isActive={`/study/${course.slug}/finish` == pathname}
                    >
                        Конец
                    </RippleNavLink>
                :
                    <span className={'btn lesson-nav-btn disabled'}>
                        Конец
                    </span>  
            }
            
        </div>
        </>
    )
}

export default LessonNavMenu


function LessonLink({course, lesson}) {
    const { pathname } = useLocation()
    const regex = new RegExp(`^\/study\/${course.slug}\/${lesson.number}(?:\/|$)`);

    return (
        lesson?.progress?.is_opened
        ?
            <RippleNavLink 
                to={`/study/${course.slug}/${lesson.number}/1`}
                className={'btn lesson-nav-btn'}
                isActive={regex.test(pathname)}
                disableRipple={false}
            >
                {lesson.title}
            </RippleNavLink>
        :
            <span className={'btn lesson-nav-btn disabled'}>
                {lesson.title}
            </span>  
    )
}