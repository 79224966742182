import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from '../components/UI/Button/Button';
import {useNamedQuery} from '../hooks/useQuery'
import { useFetching } from '../hooks/useFetching';
import UserService from '../services/UserService';
import Loader from '../components/UI/Loader/Loader';

function Unsubscribe() {
    const unsub_uuid = useNamedQuery('unsub_id');
    const [unsubscribed, setUnsubscribed] = useState(false)
    const [showError, setShowError] = useState(false)


    useEffect(() => {
        cancelSubs(unsub_uuid)
    }, [])

    const [cancelSubs, cancelSubsLoading, cancelSubsError] = useFetching(async (unsub_uuid) => {
        const response = await UserService.cancelAllSubscriptions(unsub_uuid)
        if (response.status === 200){ setUnsubscribed(true) }
    })

    useEffect(() => {
        if(cancelSubsError?.data) { setShowError(true) }
    }, [cancelSubsError])

    return (
        <>
        <header className="logo-header">
            <h1>MaddyFit</h1>
        </header>
        <Container style={{maxWidth: '540px'}}>
            <div className="page-title">
                <h4>Уведомления на почту</h4>
            </div>
            <div className="box mt-3" style={{position: 'relative'}}>
                {cancelSubsLoading && <Loader />}
                
                <div className="p-4" style={{minHeight: '200px'}}>
                    {unsubscribed &&
                        <>
                        <p className="text-bold mb-3">Вы успешно отписались от всех типов рассылок.</p>
                        <p className="mb-3">Если вы вновь захотите получать уведомления на почту, то включить их можно в Настройках аккаунта.</p>
                        <p className="mb-4">Будем рады вашему возвращению!</p>

                        <Link to={'/settings/info'} className="btn btn-success">Настройки аккаунта</Link>
                        <hr className="divider my-4"/>
                        <p>Изменения не затрагивают оповещения о платежах и действий с аккаунтом.</p>
                        </>
                    }
                    {showError &&
                        <>
                        <p className="text-bold mb-3">Кажется произошла ошибка и в данный момент мы не можем обработать ваш запрос.</p>
                        <p className="text-bold mb-3">Пожалуйста, попробуйте еще раз позже или свяжитесь со службой поддержки.</p>
                        </>
                    }
                </div>
                
                

            </div>
        </Container>
        </>
    )
}

export default Unsubscribe;
