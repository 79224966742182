import { useState } from "react";
import { Container } from "react-bootstrap"
import Modal from "./UI/Modal/Modal"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard, FreeMode } from "swiper";
import 'swiper/swiper.min.css'
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";


function AboutMeSection() {
    const [showModal, setShowModal] = useState(false)

    return (
        <section className="about-me-section">
            <div className="about-me-content-wrapper">
                <Container className="about-me-content-container">
                    <img src="/images/about_me_2.png" alt="About me" className="about-me-image"></img>
                    <div className="about-me-content">
                        <h2 className="section-title">Об <span className="text-color-theme">авторе</span> курса</h2>
                        <p className="about-me-description">Меня зовут Мадина, мне 34 года, мама двоих детей и я фитнес тренер! Сама прошла путь в построении красивого и здорового тела и помогу тебе.</p>
                        <div className="about-me-signature-wrapper">
                            <div className="about-me-signature">Mukasheva M</div>
                            <div className="about-me-signature-content">
                                <p className="about-me-signature-name">Мукашев Мадина</p>
                                <p className="about-me-signature-occupation">Тренер</p>
                            </div>
                        </div>
                        <p className="certificates-text">Дипломы и сертификаты можно посмотреть <span onClick={() => setShowModal(true)}>здесь</span></p>
                    </div>
                </Container>
                <div className="about-me-lines-wrapper">
                    <div className="about-me-left-line"></div>
                    <div className="about-me-right-line"></div>
                </div>
            </div>
            <Container className="about-me-education">
                <div className="about-me-education-item">
                    <div className="about-me-education-university">КазНТУ им.Сатпаева</div>
                    <div>архитектор<br/>магистр искусствоведческих наук</div>
                </div>
                <div className="about-me-education-item">
                    <div className="about-me-education-university">Учебный центр ООО “Профессионалы фитнеса”</div>
                    <div>диплом о переподготовке, квалификация тренер</div>
                </div>
                <div className="about-me-education-item">
                    <div className="about-me-education-university">EREPS</div>
                    <div>Personal fitness trainer</div>
                </div>
                <div className="about-me-education-item">
                    <div className="about-me-education-university">Центр Подготовки Атлетов Игоря Озерного</div>
                    <div>Инструктор тренажерного зала</div>
                </div>
                <div className="about-me-education-item">
                    <div className="about-me-education-university">FPA</div>
                    <div>Персональный фитнес-тренер</div>
                </div>
            </Container>
            <CertificatesModal showModal={showModal} setShowModal={setShowModal}/>
            
        </section>
    )
}

export default AboutMeSection


function CertificatesModal({showModal, setShowModal}) {
    console.log('CertificatesModal', showModal ,setShowModal)
    return (
        <Modal isOpen={showModal} setOpen={setShowModal} className={'certificates-modal-wrapper'}>
            <div className="certificates-modal-content">
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    keyboard={true}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination, Keyboard, Navigation]}
                    className="certificates-slider"
                >
                    <SwiperSlide><img src="/images/certificates/certificat_1.jpg" alt="Сертификат"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/certificates/certificat_2.jpg" alt="Сертификат"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/certificates/certificat_3.jpg" alt="Сертификат"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/certificates/certificat_4.jpg" alt="Сертификат"></img></SwiperSlide>
                </Swiper>
            </div>
        </Modal>
    )
}

