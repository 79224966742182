import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {IconVK, IconFacebook, IconTelegram, IconTwitter, IconOdnoklassniki, IconInstagram, IconTiktok} from '../components/svg/icons'
import InputErrorMsg from '../components/InputErrorMsg';
import { useInput } from '../hooks/useInput';


function ContactsPage() {

  const socialLinks = [
    {link: 'https://vk.com/', icon: <IconVK height={32}/>},
    {link: 'https://facebook.com/', icon: <IconFacebook height={32}/>},
    {link: 'https://instagram.com/', icon: <IconInstagram height={32}/>},
    {link: 'https://tiktok.com/', icon: <IconTiktok height={32}/>},
    {link: 'https://t.me/', icon: <IconTelegram height={32}/>},
    {link: 'https://twitter.com/', icon: <IconTwitter height={32}/>},
    {link: 'https://ok.ru/', icon: <IconOdnoklassniki height={32}/>}
  ]

  const email = useInput('', 'email', 'email', {notEmpty: true, isEmail: true})


  return (
    <Container className="d-flex flex-column pt-3">
      <h1 className='text-center mb-5'>Контакты</h1>
      <section id='social' className='mt-2 mb-5'>
          <div className="subhead">
              <h2 className="subhead-title text-center">Мы в соцсетях</h2>
          </div>
          <div className='profile-socials'>
              {socialLinks.map(social => (
                  <a key={social.link} href={social.link}>{social.icon}</a>
              ))}
          </div>
      </section>
      <section id='email' className='mt-2 mb-5'>
          <div className="subhead">
              <h2 className="subhead-title text-center">Наша почта</h2>
          </div>
          <h4 className='text-center'>info@maddy.fit</h4>
      </section>
    </Container>
  )
}

export default ContactsPage


