
import { createContext, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import axios from 'axios';
import $api from '../utils/AxiosInstance';


const AuthContext = createContext()
export default AuthContext;


export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [isAuth, setAuth] = useState(false)
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const [isAuthUpdateLoading, setIsAuthUpdateLoading] = useState(false)

    useEffect(() => {
        console.log('isAuth, Loading', isAuth, isAuthLoading)
    }, [isAuth, isAuthLoading])


    function Authorize(data) {
        console.log('Authorize data', data)
        localStorage.setItem('token', data.access_token);
        setAuth(true);
        setUser(data.user)
    }

    function Deauthorize() {
        console.log('Deauthorize')
        localStorage.removeItem('token');
        setAuth(false);
        setUser(null);
    }


    function checkIsAuth() {
        return isAuth
    }


    let checkAuth = async (e) => {
        // Refresh current Access Token and get user info
        if(!localStorage.getItem('token')) {
            console.log('checkAuth NO token')
            setAuth(false)
            setIsAuthLoading(false)
            return
        }
        console.log('checkAuth token FOUND')
        setIsAuthLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/auth/token/refresh/`, {}, { withCredentials: true})
            localStorage.setItem('token', response.data.access);
            console.log('checkAuth token', response.data.access)
            setAuth(true);
            const user_data = await $api.get('/api/users/user_info/')
            console.log('checkAuth user_data', user_data)
            setUser(user_data.data)
        } catch (e) {
            // Current token compromised
            console.log('Auth Provider - Current token compromised 1')
            Deauthorize()
        } finally {
            setIsAuthLoading(false)
        }
    }


    let updateAuth = async (e) => {
        setIsAuthUpdateLoading(true)
        console.log('Auth Provider - updateAuth Function Running')
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/auth/token/refresh/`, {}, { withCredentials: true})
            localStorage.setItem('token', response.data.access);
            setAuth(true);
            const user_data = await $api.get('/api/users/user_info/')
            setUser(user_data.data)
        } catch (e) {
            console.log('Auth Provider - Current token compromised 2')
            Deauthorize()
        } finally {
            setIsAuthUpdateLoading(false)
        }
    }

    let logout = async (e) => {
        console.log('calling logout')
        setIsAuthLoading(true)
        try {
            const response = await AuthService.logout();
            Deauthorize()
        } catch (e) {
            console.log(e)
        } finally {
            setIsAuthLoading(false)
        }
    }

    let contextData = {
        user:user,
        setUser: setUser,
        Authorize: Authorize,
        Deauthorize: Deauthorize,
        isAuth: isAuth,
        isAuthLoading: isAuthLoading,
        setIsAuthLoading: setIsAuthLoading,
        logout: logout,
        checkAuth: checkAuth,
        updateAuth: updateAuth,
        isAuthUpdateLoading: isAuthUpdateLoading,
        checkIsAuth: checkIsAuth,
    }


    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}
