import { useLocation, Link } from "react-router-dom";
import './RecipeCard.css'


function RecipeCard({recipe}) {
    const { pathname } = useLocation();

    return (
        <div className='recipe-card'>
            <Link className="recipe-card-wrapper" to={`${pathname}/${recipe.slug}`}>
                <div className='recipe-card-banner'>
                    <img src={recipe.icon_sm}/>
                </div>
                <h5>{recipe.title}</h5>
            </Link>
        </div>

    )
}

export default RecipeCard