import { useEffect, useContext } from "react";
import Container from 'react-bootstrap/Container';
import Button from '../components/UI/Button/Button'
import { useParams, useNavigate } from 'react-router';
import InputErrorMsg from '../components/InputErrorMsg';
import { useInput } from '../hooks/useInput';
import { useFetching } from '../hooks/useFetching';
import UserService from '../services/UserService';
import MessageContext from '../context/MessageContext';
import { setFormsErrors } from '../hooks/useInput'


function PasswordResetConfirm() {
    const params = useParams()
    const navigate = useNavigate()
    const { setMessages } = useContext(MessageContext)


    const newPassword1 = useInput('', 'new_password1', 'password', {notEmpty: true, minLength: 6, maxLength: 50})
    const newPassword2 = useInput('', 'new_password2', 'password', {notEmpty: true, isMatch: newPassword1.value})

    async function SetPassword(e) {
        e.preventDefault()
        const isNewPassword1 = newPassword1.StartValidation()
        const isNewPassword2 = newPassword2.StartValidation()
        if (isNewPassword1 && isNewPassword2) {
            resetPassword()
        }
    }

    const [resetPassword, isResetPasswordLoading, resetPasswordError] = useFetching(async () => {
        const formData = new FormData();
        formData.append('uid', params.uid)
        formData.append('token', params.token)
        formData.append('new_password1', newPassword1.value)
        formData.append('new_password2', newPassword2.value)
        const response = await UserService.resetPassword(formData)

        // On success
        handleResponse(response)
    })

    function handleResponse(response) {
        if (response?.data?.detail?.length > 0) {
            setMessages([{level: 'success', message: response?.data?.detail}])
            navigate(`/`)
        }
    }
    
    useEffect(() => {

        if(resetPasswordError?.data) {
            let errors = resetPasswordError?.data
            let fields = [newPassword1, newPassword2]
            setFormsErrors(fields, errors) 
        }

        if(resetPasswordError?.data?.token || resetPasswordError?.data?.uid) {
            setMessages([{level: 'error', message: 'Неверный токен. Попробуйте отправить письмо ещё раз.'}])
        }

    }, [resetPasswordError])

    return (
        <>
        <div className="logo-header">
            <h3>MaddyFit</h3>
        </div>
        <Container style={{maxWidth: '540px'}}>
            <div className="page-title">
                <h4>Придумайте новый пароль</h4>
            </div>
            <div className="box password-reset-card mt-3">
                <form className="profile-form p-4" onSubmit={e => SetPassword(e)}>
                    <dl className="form-group">
                        <dt><label className="form-label" htmlFor="inputNewPassword1">Новый пароль</label></dt>
                        <dd>
                            <input 
                            type="password" 
                            id="inputNewPassword1" 
                            className="form-control fullwidth" 
                            value={newPassword1.value} 
                            onChange={e => newPassword1.onChange(e)} 
                            required/>
                        </dd>
                        <InputErrorMsg field={newPassword1}/>
                    </dl>
                    <dl className="form-group mb-1">
                        <dt><label className="form-label" htmlFor="inputNewPassword2">Повторите новый пароль</label></dt>
                        <dd>
                            <input 
                            type="password" 
                            id="inputNewPassword2" 
                            className="form-control fullwidth" 
                            value={newPassword2.value} 
                            onChange={e => newPassword2.onChange(e)} 
                            required/>
                        </dd>
                        <InputErrorMsg field={newPassword2}/>
                    </dl>
                    <div className="note mb-3">
                        Длина пароля должна быть не менее 6 символов.
                    </div>
                <Button type='submit' className="btn btn-success fullwidth">Установить новый пароль</Button>
                </form>
            </div>
        </Container>
        </>
    )
}

export default PasswordResetConfirm
