import React, {useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const PaginationContext = React.createContext(null);

function NotifsPagination({pagination}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const current = pagination.page_number
    const showPrevious = pagination.has_previous && pagination.previous_page_number !== 1
    const showNext = pagination.has_next && pagination.next_page_number !== pagination.total_pages
    const showCurrent = current !== 1 && current !== pagination.total_pages
    const showLast = pagination.total_pages > 1

    const showPreviousDots = current > 3
    const showNextDots = current < (pagination.total_pages - 2 )

    let contextData = {
        pagination: pagination, 
        current: current, 
        url: location.pathname,
        pathname: location.pathname,
        location: location,
        params: params
    }

    return (
        pagination
        ?
        <PaginationContext.Provider value={contextData}>
        <div className='pagination'>
            <GoPrevious />
            <PaginationButton number={1}/>
            {showPreviousDots && <span className="page-numbers dots">…</span>}
            {showPrevious && <PaginationButton number={pagination.previous_page_number}/>}
            {showCurrent && <PaginationButton number={current}/>}
            {showNext && <PaginationButton number={pagination.next_page_number}/>}
            {showNextDots && <span className="page-numbers dots">…</span>}
            {showLast && <PaginationButton number={pagination.total_pages}/>}
            <GoNext />
        </div>
        </PaginationContext.Provider>
        :
        null
    )
}

export default NotifsPagination


const GoPrevious = () => {

    let { pagination, pathname, location, params } = React.useContext(PaginationContext);

    if (!pagination.has_previous) {
        return <NavLink className='page-numbers prev-btn disabled-link' to={location}>Назад</NavLink>
    }

    let previous_page = pagination.previous_page_number

    if (previous_page == 1) {
        params.delete('page')
    } else {
        params.set('page', previous_page)
    }

    let previous_url = {pathname: pathname, search: params.toString()}

    return (
        <NavLink className='page-numbers prev-btn' to={previous_url}>Назад</NavLink>
    )
}

const GoNext = () => {
    let { pagination, pathname, location, params } = React.useContext(PaginationContext);

    if (!pagination.has_next) {
        return <NavLink className='page-numbers next-btn disabled-link' to={location}>Вперед</NavLink>
    }

    let next_page = pagination.next_page_number
    params.set('page', next_page)
    let next_url = {pathname: pathname, search: params.toString()}

    return (
        <NavLink className='page-numbers next-btn' to={next_url}>Вперед</NavLink>
    )
}

const PaginationButton = ({number}) => {

    let { current, pathname, params } = React.useContext(PaginationContext);

    if (number === current){
        return (
            <span className='page-numbers current'>{number}</span>
        )
    }

    if (number == 1) {
        params.delete('page')
    } else {
        params.set('page', number)
    }

    let url = {pathname: pathname, search: params.toString()}

    return (
        <NavLink className='page-numbers' to={url}>{number}</NavLink>
    )
}
