import { setFormsErrors } from "../hooks/useInput"

export function nonFieldErrorsToMessages(response) {
    let _errors = response.data.non_field_errors
    let _messages = []
    for (const index in _errors) {
        _messages.push({level: 'error', message: _errors[index]})
    }
    return _messages
}

export function handleFormErrors(fields, response, setAlertMessages) {

    if(response?.data) {
        if (response?.status === 400) {
            let errors = response?.data
            setFormsErrors(fields, errors)

            if (response?.data?.non_field_errors) {
                let _messages = nonFieldErrorsToMessages(response)
                setAlertMessages(_messages)
            }
        } else {
            setAlertMessages([{level: 'error', message: 'Произошла ошибка. Пожалуйста, попробуйте повторить попытку позже'}])
        }
    }
}

export function resetForm(fields) {
    fields.forEach(field => field.reset())
}



