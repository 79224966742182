import React from 'react'
import {StarConfetti} from '../svg/images/StarConfetti'
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'

function FinishCourseModal({course, isOpen, toClose}) {
    if (!isOpen) { return null }

    const expire_date = new Date(course.access.expire_date).toLocaleDateString('ru-RU')

    return (
        <Modal isOpen={isOpen} toClose={toClose} showCloseBtn={false}>
        <div className='finish-course-modal'>
            <div className='finish-course-modal-content'>
                <StarConfetti />
                <h3 className='mt-3 mb-4'>Поздравляем с завершением курса!</h3>
                <p>Все материалы будут доступны до {expire_date}</p>
                <p>После чего вы сможете продлить курс со скидкой до 50%</p>



            </div>
            <Button className='btn' onClick={toClose}>ОК</Button>
        </div>
        </Modal>
    )
}

export default FinishCourseModal