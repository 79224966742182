import { useEffect, useState } from 'react'

function useGoogleAuth(callback) {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const [googleClient, setGoogleClient] = useState(null)


    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = true
        script.onload = initializeGsi
        script.id = "google-client-script"
        document.body.appendChild(script);
    }, [])


    const initializeGsi = () => {

        if (gsiScriptLoaded) return

        setGsiScriptLoaded(true)
        /* global google */
        let client = google.accounts.oauth2.initCodeClient({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email profile',
            ux_mode: 'popup',
            callback: (response) => {
                callback(response.code)
            }
        });

        setGoogleClient(client)
    }

    const getGoogleAuthCode = () => {
        googleClient.requestCode();
    }

    return getGoogleAuthCode
}

export default useGoogleAuth