import Alert from '@mui/material/Alert';

function Alerts({messages=[], setMessages}) {

    function deleteMessage(message) {
        let new_messages = messages.filter(m => m !== message)
        setMessages(new_messages)
    }

    return (
        <div>
            {messages.map(message => (
                <Alert className="flash-alert" icon={false} onClose={() => {deleteMessage(message)}} key={message} variant="filled" severity={message.level}>{message.message}</Alert>
            ))}
        </div>
    )
}

export default Alerts
