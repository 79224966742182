export function addNewCommentToList(new_data, comments, setComments, users, setUsers) {
    const new_comment =new_data.new_comment
    const new_user = new_data.user

    if (new_comment.thread) {
        const newComments = comments.map((_comment) => {
            if(_comment.id === new_comment.thread) {
                const updated_thread = {
                    ..._comment,
                    descendants: [..._comment.descendants, new_comment.id]
                }
                return updated_thread
            }
            return _comment
        })
        setComments([...newComments, new_comment])
    } else {
        setComments([...comments, new_comment])
    }

    // Add comment user info to user list
    let found = false
    for (let i = 0; i < users.length; i++) {
        if (users[i].id === new_user.id) {
            found = true
        }
    }
    if (!found) {
        setUsers([...users, new_user])
    }
}

export function updateComment(new_data, comments, setComments) {
    const new_comments_list = comments.map((_comment) => {
        if(_comment.id === new_data.id) {
            return new_data;
        }
        return _comment;
    });
    setComments(new_comments_list)
}

// Likes
export function updateLikeStats(comment, comments, setComments) {
    const new_comments_list = comments.map((_comment) => {
        if(_comment.id === comment.id) {
            return updateCommentLikeStats(_comment, comment);
        }
        return _comment;
    });
    setComments(new_comments_list)
}

function updateCommentLikeStats(item, new_stats) {
    const updated_item = {
        ...item,
        likes: new_stats.likes,
        is_liked: new_stats.is_liked
    };
    return updated_item;
}

// Deprecated
export function removeCommentFromList(comment, comments, setComments) {
    let newComments = comments.filter(c => c.id !== comment.id)
    if (comment.replies.length > 0) {
        comment.replies.forEach(_reply_id => {
            newComments = newComments.filter(c => c.id !== _reply_id)
        })
    }
    setComments(newComments)
}

export function goToComment(ref) {
    setTimeout(() => {
        if (ref.current) {
            ref.current.scrollIntoView({block: "start", behavior: "smooth"});
        }
    }, 500);
}