import { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import './DropdownMenu.css'

export const DropdownMenu = ({children, isOpen, setOpen, className}) => {

    const [menuHeight, setMenuHeight] = useState(0);
    const [parent, setParent] = useState(null)
    const dropdownRef = useRef(null);
    const dropDownClass = className ? `dropdown ${className}` : `dropdown`;


    useEffect(() => {
        if (dropdownRef.current) {
            if (parent) {
                toggleParentActiveStatus(parent, isOpen)
            } else {
                let parentElement = dropdownRef.current.closest('.dropdown-parent')
                if (parentElement) {
                    setParent(parentElement)
                    toggleParentActiveStatus(parentElement, isOpen)
                }
            }
        } else {
            if (parent) {
                toggleParentActiveStatus(parent, isOpen)
            }
        }
    }, [isOpen])

    const Close = () => {
        if (setOpen) { setOpen(false) }
    }

    if (!isOpen) { return null }

    return (
        <>
        {/* <div className={dropDownClass} style={{height: menuHeight}} ref={dropdownRef}> */}
        <div className={dropDownClass} ref={dropdownRef}>
            <div>
                {children}
            </div>
        </div>
        <div className='dropdown-background' onClick={Close}/>
        </>
    )
}

export const DropdownItem = ({children, link, action, className, leftIcon, setOpen, state, blockClosing=false}) => {
    const close = () => {
        if (setOpen && !blockClosing) { setOpen(false) }
    }
    const handleClick = () => {
        close()
        if (action) { action() }
    }

    return (
        <>
        {link
            ?
            <Link onClick={close} to={link} className={`dropdown-item ${className ? className : ''}`} state={state}>
                <span className="icon-button">{leftIcon}</span>
                {children}
            </Link>
            :
            <span onClick={handleClick} className={`dropdown-item ${className}`}>
                <span className="icon-button">{leftIcon}</span>
                {children}
            </span>
        }
        </>
    );
}

const toggleParentActiveStatus = (parent, isDropdownOpen) => {
    if (isDropdownOpen) {
        // Set parent z-index above background
        parent.classList.add('active')
    } else {
        // Reset parent z-index
        parent.classList.remove('active')
    }
}