import { useContext } from 'react'
import Container from 'components/UI/Container'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFetching } from 'hooks/useFetching';
import CourseService from 'services/CourseService';
import MessageContext from 'context/MessageContext';
import LoadingButton from 'components/UI/Button/LoadingButton';


function PaymentTest() {
    const { setMessages } = useContext(MessageContext)
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const paymentID = searchParams.get('id')
    const amount = searchParams.get('amount')

    const [pay, loading] = useFetching(async () => {
        const formData = new FormData()
        formData.append('status', 'succeeded')
        formData.append('amount', amount)
        formData.append('payment_id', paymentID)
        formData.append('transaction_id', '000000')

        const response = await CourseService.makeTestPayment(formData)
        navigate(`/payment/check?id=${paymentID}`)
    }, () => {
        setMessages([{level: 'error', message: 'Ошибка запроса. Попробуй позже или обратитесь в службу поддержки.'}])
        // No course info. Redirect to home page
        navigate('/')
    })

    return (
        <Container>
            <div className='d-flex flex-column align-items-center'>
                <p className='mt-5 text-center'>Это тестовая оплата</p>
                <p className='mb-4 text-center'>Номер заказа: {paymentID}</p>
                <LoadingButton onClick={pay} className='btn' isLoading={loading} loadingText={'Загрузка'}>Оплатить</LoadingButton>
            </div>
        </Container>
    )
}

export default PaymentTest