import React, { useState, useEffect } from 'react'
import { useFetching } from '../hooks/useFetching'
import PostService from '../services/PostService'
import { Container } from 'react-bootstrap';
import PostPagination from '../components/Posts/PostPagination';
import { useParams } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import { useNamedQuery } from '../hooks/useQuery';

function PostsPage() {
    const [posts, setPosts] = useState([])
    const [pagination, setPagination] = useState(null)
    const params = useParams();
    const { search } = useLocation();
    const currentPage = useNamedQuery('page')


    const [fetchPosts, postsLoading, postsError] = useFetching(async () => {
        let param = {}
        param['page_size'] = 7
        if (params?.tag) {param['tags'] = params?.tag}
        if (currentPage && currentPage > 1) {param['page'] = currentPage}
        
        const response = await PostService.getPosts(param)
        setPosts(response.data.posts)
        setPagination(response.data.pagination)
    })

    useEffect(() => {
        fetchPosts()
    }, [search, params])

    return (
        <>
        <Container className="mt-3">
            <section className='posts-grid'>
                {posts.map(post => 
                    <PostCard key={post.id} post={post}/>
                )}
            </section>
            
        </Container>
        {pagination &&
            <PostPagination pagination={pagination}/>
        }
        </>
    )
}

export default PostsPage


const PostCard = ({post}) => {
    const { pathname } = useLocation();
    const date_options = { year: 'numeric', month: 'long', day: 'numeric' };
    const publish_date = new Date(post.date_created).toLocaleDateString('ru-RU', date_options).slice(0, -3);

    return (
        <div className='post-card'>
            <Link className="post-card-wrapper" to={`${pathname}/${post.slug}`}>
            <figure className="post-card-thumbnail" style={{backgroundImage: `url(${post.icon_sm})`}}>
            </figure>
            
            </Link>
            <div className="post-card-content">

                <div className="post-card-title">
                    <Link to={`${pathname}/${post.slug}`}><h2>{post.title}</h2></Link>
                    <div className="post-card-meta">
                        <div className="post-card-tags">
                            {post.tags.map(tag => (
                                <span key={tag.id}>
                                    <Link 
                                        to={`/blog/tag/${tag.slug}`} 
                                        rel="tag" 
                                        title={`Открыть статьи раздела ${tag.name}`}>
                                        {tag.name}
                                    </Link>
                                    <span className="post-card-point"> • </span>
                                </span>
                            ))}
                        </div>
                        <div className="post-card-timestamp">{publish_date}</div>
                    </div>
                </div>
            </div>
        </div>

    )
}