import React from 'react'
import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { PostCommentsContext } from './PostComments';
import { IconButton } from '@mui/material';
import { DropdownMenu, DropdownItem } from '../UI/Dropdown/DropdownMenu'
import Avatar from '../UI/Avatar/Avatar';
import PostCommentForm from './PostCommentForm';
import PostService from '../../services/PostService';
import { updateLikeStats } from '../Comments/utils';
import { useFetching } from '../../hooks/useFetching';
import { getTimePastFromDate } from '../../utils/Common'
import DOMPurify from 'dompurify'
import { goToComment } from '../Comments/utils';
import {useToggle} from '../../hooks/useToggle'
import CommentText from '../Comments/CommentText'

function PostComment({comment}) {
    let { comments, setComments, users, deleteComment, highlighted } = useContext(PostCommentsContext)
    const [user, setUser] = useState(null)
    const [dropDown, setDropDown] = useState(false);
    const [showReplyForm, toggleReplyForm] = useToggle(false)
    const [showEditForm, toggleEditForm] = useToggle(false)
    const [isHighlight, setIsHighlight] = useState(false)
    const commentRef = useRef(null)
    const [editedTime, setEditedTime] = useState(getTimePastFromDate(comment.edited_at))


    useEffect(() => {
        if (highlighted == comment.id) {
            setIsHighlight(true)
            goToComment(commentRef)
        }
        const interval = setInterval(() => {
            setEditedTime(getTimePastFromDate(comment.edited_at))
        }, 60000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (users.length > 0 ) {
            const found = users.find(_user => _user.id == comment.author)
            if (found) { setUser(found) }
        }
    }, [users])

    const [likeComment] = useFetching(async () => {
        const response = await PostService.likeComment(comment.id)
        updateLikeStats(response.data, comments, setComments)
    })

    const handleCommentClick = () => {
        if (isHighlight) {
            setIsHighlight(false)
        }
    }


    if (comment.is_deleted) {
        return (
            <div className='post-comment-wrapper' style={{width: `${comment.thread && '95%'}`}}>
                <div ref={commentRef} className={`post-comment-card ${isHighlight ? 'comment-highlighted' : ''}`} id={`comment${comment.id}`}>
                    <Avatar src={'/images/default_avatar.jpg'} className={'small me-2'}/>
                    <div className='post-comment-deleted'>
                        Комментарий удален
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className='post-comment-wrapper' style={{width: `${comment.thread && '95%'}`}}>
            {!showEditForm &&
                <div ref={commentRef} onClick={handleCommentClick} className={`post-comment-card ${isHighlight ? 'comment-highlighted' : ''}`} id={`comment${comment.id}`}>
                    {user && <Avatar src={user.profile_image} className={'small me-2'}/>}
                    <div className='post-comment-content'>
                        <div className='post-comment-header'>
                            <div>
                                {user && <span className='text-bold me-2'>{user.full_name}</span>}
                                <span className='post-comment-time'>{editedTime}</span>
                            </div>        
                        </div>
                        {/* <div className='post-comment-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.text), }}/> */}
                        <CommentText text={comment.text} className={'post-comment-text'}/>
                        <div className='post-comment-contols'>
                            <p className="post-comment-reply" onClick={toggleReplyForm}>Ответить</p>
                            <span>{comment.likes > 0 && comment.likes}</span>
                            <IconButton className='me-4' onClick={likeComment}>
                                <LikeIcon className={comment.is_liked ? 'liked' : ''}/>
                            </IconButton>
                            {(comment.can_edit || comment.can_delete) && 
                                <span className='dropdown-parent'>
                                    <IconButton onClick={e => setDropDown(!dropDown)}>
                                        <OptionsIcon/>
                                    </IconButton>
                                    <DropdownMainMenu 
                                    isOpen={dropDown}
                                    setOpen={setDropDown} 
                                    deleteComment={()=>deleteComment(comment)} 
                                    toggleEditForm={toggleEditForm}/>
                                </span>
                            }
                        </div>
                    </div>
                </div>
            }
            {showEditForm &&
                <PostCommentForm 
                    mode={'edit'}
                    self={comment} 
                    toggleForm={toggleEditForm}
                />
            }
            {showReplyForm &&
                <div className="post-comment-reply-form">
                    <PostCommentForm 
                    mode={'reply'}
                    parent={comment} 
                    toggleForm={toggleReplyForm}/>
                </div>
            }
        </div>
    )
}

export default PostComment

function DropdownMainMenu({isOpen, setOpen, deleteComment, toggleEditForm}) {
    return (
        <DropdownMenu isOpen={isOpen} setOpen={setOpen}>
            <DropdownItem className={'small'} setOpen={setOpen} action={toggleEditForm}>Редактировать</DropdownItem>
            <DropdownItem className={'small'} setOpen={setOpen} action={deleteComment}>Удалить</DropdownItem>
        </DropdownMenu>
    );
}



const LikeIcon = ({className}) => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={className} d="M20.84 4.60999C20.3292 4.099 19.7228 3.69364 19.0554 3.41708C18.3879 3.14052 17.6725 2.99817 16.95 2.99817C16.2275 2.99817 15.5121 3.14052 14.8446 3.41708C14.1772 3.69364 13.5708 4.099 13.06 4.60999L12 5.66999L10.94 4.60999C9.9083 3.5783 8.50903 2.9987 7.05 2.9987C5.59096 2.9987 4.19169 3.5783 3.16 4.60999C2.1283 5.64169 1.54871 7.04096 1.54871 8.49999C1.54871 9.95903 2.1283 11.3583 3.16 12.39L4.22 13.45L12 21.23L19.78 13.45L20.84 12.39C21.351 11.8792 21.7563 11.2728 22.0329 10.6053C22.3095 9.93789 22.4518 9.22248 22.4518 8.49999C22.4518 7.77751 22.3095 7.0621 22.0329 6.39464C21.7563 5.72718 21.351 5.12075 20.84 4.60999Z" stroke="#737380" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

const OptionsIcon = ({className}) => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={className} d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);