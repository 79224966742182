import React from 'react';
import { useState, useEffect } from 'react';
import PlyrPlayer from './PlyrPlayer';
import Loader from '../UI/Loader/Loader';
import PlayerText from './PlayerText';


function StepPlayer({step, toggleShowLeftBar, isVideoLoading, playerText, onEnded}) {
    const [videos, setVideos] = useState([])
    const [poster, setPoster] = useState(null)

    useEffect(() =>{
        console.log('isVideoLoading', isVideoLoading)
    }, [isVideoLoading])

    const playerOptions = {
        listeners: {
            pip (event) {
                event.preventDefault();
                toggleShowLeftBar();
                return false;
            },
        },
        ratio: '16:9',
        controls: [
            'play',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'settings',
            'pip',
            'fullscreen',
            'airplay'
        ],
    }

    useEffect(() => {
        if (step) {
            let temp = []
            // let exercise = step.exercise
            if (step.video_1080) {
                temp.push({
                    src: step.video_1080,
                    type: 'video/mp4',
                    size: '1080',
                })
            }
            if (step.video_720) {
                temp.push({
                    src: step.video_720,
                    type: 'video/mp4',
                    size: '720',
                })
            }
            if (step.video_480) {
                temp.push({
                    src: step.video_480,
                    type: 'video/mp4',
                    size: '480',
                })
            }
            setPoster(step?.icon_sm ? step.icon_sm : null)
            setVideos(temp)
        }
    }, [step])
    
    return (
        <div className="video-wrapper">
            {/* <PlayerText text={playerText}/> */}
            <div className={playerText ? 'player-text active' : 'player-text'}>{playerText}</div>
            <PlyrPlayer
                videos={videos}
                poster={poster}
                options={playerOptions}
                isVideoLoading={isVideoLoading}
                onEnded={onEnded}/>
            {isVideoLoading && <Loader position={'absolute'} background={true}/>}
        </div>
    )
}

export default StepPlayer