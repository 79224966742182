import { useEffect, useState } from 'react'
import './Alert.css'
import { InfoIcon, WarningIcon, SuccessIcon, CloseIcon } from '../../svg/icons'

function Alert({message, deleteMessage, className, fadeDelay=6000, fadeEnabled=true}) {
    const [alertClass, setAlertClass] = useState('')
    const [level, setLevel] = useState(message.level ? message.level : 'info')
    const baseClassName = 'alert'


    useEffect(() => {
        let _level = message.level ? message.level : 'info'
        let _className;

        switch(_level) {
            case 'error':
                _className = baseClassName.concat(' ', 'alert-error')
                break;
            case 'warning':
                _className = baseClassName.concat(' ', 'alert-warning')
                break;
            case 'info':
                _className = baseClassName.concat(' ', 'alert-info')
                break;
            case 'success':
                _className = baseClassName.concat(' ', 'alert-success')
                break;
            default:
                _className = baseClassName.concat(' ', 'alert-info')
        }

        if (className) {
            _className = _className.concat(' ', className)
        }

        setLevel(_level)
        setAlertClass(_className)
        setTimeout(() => {
            if (fadeEnabled) {
                close()
            }
        }, fadeDelay)
    }, [message])

    function close() {
        deleteMessage(message)
    }

    if (!message.message || message.message.length === 0) {
        return null
    }

    return (
        <div className={alertClass}>
            {level === 'error'
                &&  <InfoIcon className={'alert-icon'}/>
            }
            {level === 'info'
                &&  <InfoIcon className={'alert-icon'}/>
            }
            {level === 'warning'
                &&  <WarningIcon className={'alert-icon'}/>
            }
            {level === 'success'
                &&  <SuccessIcon className={'alert-icon'}/>
            }
            <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
            <button onClick={close} className='alert-close-btn'><CloseIcon className={'alert-close-icon'}/></button>
        </div>
    )
}

export default Alert