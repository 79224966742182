export default function ({ clientId, state, scope, redirectUri, responseType = "code" }) {
  let current = encodeURIComponent(window.location.href);
  const base = `https://www.facebook.com/v14.0/dialog/oauth?response_type=${responseType}`;
  // const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
  //   navigator.userAgent
  // );
  // const display = mobile ? "touch" : "popup";
  const fullScope =
    scope && scope.length ? `&scope=${encodeURIComponent(scope)}` : "";
  return `${base}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}${fullScope}`;
}