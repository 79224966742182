import React from 'react'
import { createContext, useState, useEffect } from 'react';
import AlertBar from '../components/UI/AlertBar/AlertBar';

const MessageContext = createContext()
export default MessageContext

export const MessageProvider = ({children}) => {

    const [messages, setMessages] = useState([])

    let contextData = {
        setMessages:setMessages
    }

    return (
        <MessageContext.Provider value={contextData}>
            <AlertBar messages={messages} setMessages={setMessages}/>
            {children}
        </MessageContext.Provider>
    )
}
