import React, { useState, useEffect } from 'react'
// import Modal from '@mui/material/Modal';
import PlyrPlayer from './PlyrPlayer';
import Modal from '../UI/Modal/Modal';

function CommentPlayer({comment, isOpen, setOpen}) {
    const [videos, setVideos] = useState([])

    useEffect(() => {
        if(comment.video) {
            let temp = []
            temp.push(
                {
                    src: comment.video
                }
            )
            setVideos(temp)
        }
    }, [comment])

    if (!isOpen) { return null }

    return (
        <Modal isOpen={isOpen} setOpen={setOpen}>
            <div className="video-modal">
                {videos.length > 0 &&
                    <PlyrPlayer videos={videos} pip={false}/>
                }
            </div>
        </Modal>
    )
}

export default CommentPlayer


/* <button onClick={toClose} className="video-modal-close-btn">&#10006;</button> */