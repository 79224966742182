import React from 'react'
import { Container } from 'react-bootstrap';
import '../styles/pages/RecipePage.css'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import RecipeService from '../services/RecipeService'
import { useFetching } from '../hooks/useFetching'
import { useParams } from 'react-router-dom';
import BackdropLoader from '../components/UI/BackdropLoader/BackdropLoader';
import { getTimePastFromDate } from '../utils/Common';
import PostComments from '../components/Posts/PostComments';
import Avatar from '../components/UI/Avatar/Avatar';
import {IconChefHat, TimeIcon} from '../components/svg/icons';
import EnergyValues from '../components/Recipes/EnergyValues';
import IngredientsList from '../components/Recipes/IngredientsList';
import SocialShare from '../components/SocialShare/SocialShare';


function RecipePage() {
    const [recipe, setRecipe] = useState(null)


    const params = useParams()

    useEffect(() => {
        fetchRecipe(params.slug)
    }, [])

    const [fetchRecipe, recipeLoading, recipeError] = useFetching(async (slug) => {
        const response = await RecipeService.getRecipe(slug)
        setRecipe(response.data)
    })

    useEffect(() => {
        if(recipeError?.data) { console.error(recipeError?.data) }
    }, [recipeError])

    
    if (recipeLoading) {
        return <BackdropLoader />
    }

    return (
        recipe && 
        <>
            <article>
                <Container className="recipe-banner-container">
                    <section className="recipe-banner" style={{backgroundImage: `url(${recipe.icon_lg})`}}>
                    </section>
                </Container>
                <Container className='recipe-container'>
                    <section className='recipe-headline'>
                        <div className='recipe-headline-stamps'>
                            <span className='stamp-author'>
                                <Avatar user={recipe.author} className={'avatar-xs me-2'}/>
                                <Link to={`/profile/${recipe.author.id}`}>{recipe.author.full_name}</Link>
                            </span>
                            <span className='stamp-date'>
                                <ClockIcon />
                                {getTimePastFromDate(recipe.date_created)}
                            </span>
                            <span className='stamp-comments'>
                                <ChatIcon />{recipe.comments}
                            </span>
                            <span className='stamp-views'>
                                <ViewsIcon />{recipe.views}
                            </span>
                        </div>
                        <h1 className='main-title'>{recipe.title}</h1>
                    </section>
                    <section className='recipe-info'>
                        <div className='recipe-info-time'>
                            <TimeIcon height={'60px'}/>
                            <p>{recipe.time} минут</p>
                        </div>
                        <div className='recipe-info-difficulty'>
                            <IconChefHat height={'60px'}/>
                            <p>{recipe.hardness.join(' - ')}</p>
                        </div>
                    </section>
                    <section className='recipe-content'>
                        <div className='recipe-description'>
                            {recipe.description}
                        </div>
                        <EnergyValues recipe={recipe}/>
                        <IngredientsList recipe={recipe}/>
                        <div className='recipe-text' dangerouslySetInnerHTML={{ __html: `${recipe.text}`, }} />

                        <SocialShare instance={recipe}/>
                    </section>
                </Container>
            </article>
            {/* <PostComments post_id={post.id}/> */}
        </>
    )
}

export default RecipePage




const ClockIcon = () => (
    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18.5a8.5 8.5 0 118.5-8.5 8.51 8.51 0 01-8.5 8.5zm.42-14.21h-1.5V13h5.75v-1.5h-4.25z"></path>
    </svg>
)

const ChatIcon = () => (
    <svg width="16" height="16" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 4a1 1 0 011 1v10a1 1 0 01-1 1H7.17l-.58.59L4 19.17V5a1 1 0 011-1h14m0-2H5a3 3 0 00-3 3v19l6-6h11a3 3 0 003-3V5a3 3 0 00-3-3z"></path>
    </svg>
)

const ViewsIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 7.99663C16 9.29828 12.7605 13.5 8.00309 13.5C3.30737 13.5 0 9.29828 0 7.99663C0 6.68823 3.31354 2.5 8.00309 2.5C12.7543 2.5 16 6.68823 16 7.99663ZM1.19707 7.99663C1.19707 8.89362 4.22676 12.3602 8.00309 12.3602C11.7671 12.3602 14.7968 8.89362 14.7968 7.99663C14.7968 7.24801 11.7671 3.63305 8.00309 3.63305C4.22676 3.63305 1.19707 7.24801 1.19707 7.99663ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"></path>
    </svg>
)
