import $api from '../utils/AxiosInstance';

export default class AuthService {
    static async login(email, password) {
        return $api.post('/api/users/auth/login/', {email, password})
    }

    static async registration(formData) {
        return $api.post('/api/users/auth/registration/', formData)
    }

    static async logout() {
        return $api.post('/api/users/auth/logout/', {})
    }

    static async GoogleLogin(code) {
        return $api.post('/api/users/auth/google/', {code: code})
    }

    static async GoogleConnect(code) {
        return $api.post('/api/users/auth/google/connect/', {code: code})
    }

    static async VKLogin(code, redirectUri) {
        return $api.post('/api/users/auth/vk/', {code: code, redirectUri: redirectUri})
    }

    static async RedirectAuthCode(data) {
        return $api.post('/api/users/auth/auth_code/', data)
    }

    static async VKConnect(code, redirectUri) {
        return $api.post('/api/users/auth/vk/connect/', {code: code, redirectUri: redirectUri})
    }

    static async FBLogin(accessToken) {
        return $api.post('/api/users/auth/facebook/', {access_token: accessToken})
    }


    static async FBConnect(access_token) {
        return $api.post('/api/users/auth/facebook/connect/', {access_token: access_token})
    }

    // Get all social accounts
    static async getSocial() {
        return $api.get('/api/users/social/', {})
    }

    static async disconnectSocial(socialID) {
        return $api.post(`/api/users/socialaccounts/${socialID}/disconnect/`, {})
    }
}