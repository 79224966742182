import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSize } from '../../../hooks/useSize';
import './TouchRipple.css'

const TouchRipple = React.forwardRef(
    function TouchRipple(props, ref) {
        const [size, setSize] = useState(0);
        const [parent, setParent] = useState(null);
        const rippleRef = useRef(null);
        const rippleRefSize = useSize(rippleRef);

        useEffect(() => {
            setParent(rippleRef.current.parentElement)
        }, [])
    
        useEffect(() => {
            const largestSide =  Math.sqrt(
                Math.pow(rippleRef.current.offsetWidth, 2) +
                Math.pow(rippleRef.current.offsetHeight, 2)
            )
            setSize(Math.ceil((largestSide * 2) / 100))
        }, [rippleRefSize])

        const startRipple = useCallback((event) => {
            const yCoord = event.pageY;
            const xCoord = event.pageX;

            const x = ((xCoord - parent.offsetLeft) * 100)  / parent.offsetWidth;
            const y = ((yCoord - parent.offsetTop) * 100)  / parent.offsetHeight;

            const dot = document.createElement('span');
    
            dot.style = `
                    width: ${size}px;
                    height: ${size}px;
                `;
    
            dot.className = "touch-ripple"
            
            rippleRef.current.appendChild(dot)
    
            dot.style.left = x + '%';
            dot.style.top = y + '%';
    
            setTimeout(() => {
                try {
                    rippleRef.current.removeChild(dot)
                } catch {
                    
                }
            }, 2000);
        }, [size]);

        React.useImperativeHandle(
            ref,
            () => ({
                startRipple,
            }),
            [startRipple],
        );
    
        return (
            <span ref={rippleRef} className='touch-ripple-root'></span>
        )
    }
);


export default TouchRipple
