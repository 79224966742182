import { useState, useEffect } from 'react'
import LoadingButton from '../../UI/Button/LoadingButton'
import UserService from '../../../services/UserService';
import { updateItemValueInArray } from '../../../utils/Array';
import {
    IconVK, 
    IconFacebook, 
    IconTelegram, 
    IconTwitter, 
    IconOdnoklassniki,
    IconInstagram,
    IconTiktok
} from '../../svg/icons'
import {useFetching} from '../../../hooks/useFetching'


function SocialLinks() {

    useEffect(() => {
        getSocialLinks()
    }, [])

    const [socialLinks, setSocialLinks] = useState(
        [
            {id: 'vk_link', domain: 'https://vk.com/', value: '', icon: <IconVK height={28}/>, is_active: false},
            {id: 'facebook_link', domain: 'https://facebook.com/', value: '', icon: <IconFacebook height={28}/>, is_active: false},
            {id: 'instagram_link', domain: 'https://instagram.com/', value: '', icon: <IconInstagram height={28}/>, is_active: false},
            {id: 'tiktok_link', domain: 'https://tiktok.com/', value: '', icon: <IconTiktok height={28}/>, is_active: false},
            {id: 'telegram_link', domain: 'https://t.me/', value: '', icon: <IconTelegram height={28}/>, is_active: false},
            {id: 'twitter_link', domain: 'https://twitter.com/', value: '', icon: <IconTwitter height={28}/>, is_active: false},
            {id: 'odnoklassniki_link', domain: 'https://ok.ru/', value: '', icon: <IconOdnoklassniki height={28}/>, is_active: false}
        ]
    )

    const [getSocialLinks, getSocialLinksLoading, getSocialLinksError] = useFetching(async () => {
        const response = await UserService.getSocialLinks()        
        const new_array = socialLinks.map( social => {
            if (response.data[social.id]) {
                return {...social, value: response.data[social.id], is_active: true}
            }
            return social
        })
        setSocialLinks(new_array)
    })

    const [updateSocialLinks, updateSocialLinksLoading, updateSocialLinksError] = useFetching(async () => {
        const formData = new FormData()
        socialLinks.forEach((social) => {
            formData.append(social.id, social.value)
        })
        const response = await UserService.updateSocialLinks(formData)
        let newData = new Array
        socialLinks.forEach((social) => {
            newData.push({...social, value: response.data[social.id] ? response.data[social.id] : ''})
        })
        setSocialLinks(newData)
    })


    return (
        <>
            <p className="mt-4 mb-3">Ссылки на социальные сети, которые видны на вашей личной странице:</p>
            {socialLinks.map(social => (
                <SocialLink key={social.id} social={social} socialLinks={socialLinks} setSocialLinks={setSocialLinks}/>
            ))}
            <LoadingButton className='btn' onClick={updateSocialLinks} isLoading={updateSocialLinksLoading}>Сохранить</LoadingButton>
        </>

    )
}

export default SocialLinks

const SocialLink = ({social, setSocial, socialLinks, setSocialLinks}) => {
    const handleChange = (e) => {
        let new_item = {...social, value: e.target.value}
        updateItemValueInArray(new_item, 'value', socialLinks, setSocialLinks)
    }


    return (
        <div className="social-links-settings-wrapper">
            {social.is_active
                ?   <a href={social.domain + social.value} className='social-links-icon-wrapper'>{social.icon}</a>
                :   <div className='social-links-icon-wrapper icon-disabled'>{social.icon}</div>
            }
            <div className='social-links-input-wrapper'>
                <span className='text-muted'>{social.domain}</span>
                <input 
                type="text" 
                className="form-control form-input-inline fullwidth" 
                value={social.value} 
                onChange={e => handleChange(e)}
                />
            </div>
        </div>
    )
}