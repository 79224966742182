import React from 'react'
import './Footer.css'
import { useLocation } from 'react-router-dom';


function FooterComp() {
    const { pathname } = useLocation()
    const isHideFooter = pathname === '/password_reset' || pathname === '/login' || pathname === '/signup' || pathname.match('auth/social')

    
    if (isHideFooter) {
        return null
    }
    
    return (
        <footer className="pt-4" id='footer'>
            <div xs={12} md={4} className="footer-social">
                <h3 className='footer-title'>#студия MaddyFit</h3>
                <ul>
                    <li className="footer-social-icon in"><a href="google.com"><i className="fab fa-instagram"></i></a></li>
                    <li className="footer-social-icon tw"><a href="google.com"><i className="fab fa-twitter"></i></a></li>
                    <li className="footer-social-icon fb"><a href="google.com"><i className="fab fa-facebook-f"></i></a></li>
                    <li className="footer-social-icon li"><a href="google.com"><i className="fab fa-linkedin-in"></i></a></li>
                    <li className="footer-social-icon vk"><a href="google.com"><i className="fab fa-vk"></i></a></li>
                </ul>
            </div>
        </footer>
    )
}

export default FooterComp
