import React from 'react';
import './HelperText.css'

function HelperText({message, visible, error, warning, info, success, className, size='sm'}) {

    const [text, setText] = React.useState(message ? message : '')

    if (!visible || text=='') {
        return null
    }

    let classNames = ['helper-text',]

    if (error) {
        classNames.push('helper-text-error')
    } else if (warning) {
        classNames.push('helper-text-warning')
    } else if (info) {
        classNames.push('helper-text-info')
    } else if (success) {
        classNames.push('helper-text-success')
    }

    switch (size) {
        case 'xs':
            classNames.push('text-xsmall')
          break;
        case 'sm':
            classNames.push('text-small')
          break;
        case 'md':
            classNames.push('text-medium')
          break;
        case 'lg':
            classNames.push('text-large')
          break;
        default:
            classNames.push('text-xsmall')
    }

    if (className) {
        classNames.push(className)
    }

    return (
        <p className={classNames.join(' ')}>
            {text}
        </p>
    )
}

export default HelperText;
