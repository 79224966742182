import { useRef, useState } from 'react'
import { DropdownMenu } from '../UI/Dropdown/DropdownMenu'
import Button from '../UI/Button/Button'
import { toggleFilterInArray } from './utils'

function RepiceFiltersMenu({isOpen, setOpen, filters, setFilters}) {
    const [allFilters, setAllFilters] = useState(filters)

    const clearFilters = ()=> {
        let clearedFilters = {}
        for (let [key, filterType] of Object.entries(allFilters)) {
            clearedFilters[key] = filterType.map(filter => {
                return {...filter, is_active: false}
            })
        }
        setAllFilters(clearedFilters)
    }

    const submitFilters = ()=> {
        setFilters(allFilters)
        setOpen(false)
    }

    return (
        <DropdownMenu isOpen={isOpen} setOpen={setOpen} className={'recipe-filters-dropdown'}>
            <div className="recipe-filters-lists">
                <div className="recipe-filters-column">
                    <div>
                        <FilterTitle title={'Блюдо'}/>
                        <div className="recipe-filters">
                            {allFilters.meal.map(mealFilter=>(
                                <FilterItem key={mealFilter.slug} filter={mealFilter} type={'meal'} allFilters={allFilters} setAllFilters={setAllFilters}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="recipe-filters-column">
                    <div>
                        <FilterTitle title={'Диета'}/>
                        <div className="recipe-filters">
                            {allFilters.diet.map(dietFilter=>(
                                <FilterItem key={dietFilter.slug} filter={dietFilter} type={'diet'} allFilters={allFilters} setAllFilters={setAllFilters}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="recipe-filters-column">
                    <div>
                        <FilterTitle title={'Время'}/>
                        <div className="recipe-filters">
                            {allFilters.time.map(timeFilter=>(
                                <FilterItem key={timeFilter.slug} filter={timeFilter} type={'time'} allFilters={allFilters} setAllFilters={setAllFilters}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='recipe-filters-menu-contols'>
                <Button className={'btn'} onClick={clearFilters}>Сбросить всё</Button>
                <Button className={'btn'} onClick={submitFilters}>Применить</Button>
                {/* <div>
                    <Button className={'btn me-2'} onClick={()=>setOpen(false)}>Отмена</Button>
                    <Button className={'btn'} onClick={submitFilters}>Применить</Button>
                </div> */}

            </div>

        </DropdownMenu>
    )
}

export default RepiceFiltersMenu


const FilterTitle = ({title}) => {
    const titleRef = useRef(null)
    return  <p className='recipe-filters-title' ref={titleRef} onClick={(e)=>{openMobileFitlerList(titleRef)}}>{title}
                <i className="fa-solid fa-chevron-down"></i>
            </p>
}

const openMobileFitlerList = (titleRef) => {
    if (window.innerWidth <= 767) {
        titleRef.current.classList.toggle('open')
    }
}

const FilterItem = ({filter, type, allFilters, setAllFilters})=> {
    return (
        <div 
            className={`${filter.is_active ? 'recipe-filter active' : 'recipe-filter'}`} 
            onClick={() => toggleFilterInArray(filter, type, allFilters, setAllFilters)}
        >
            {filter.name}
        </div>
    )
}

