function AuthWatchSocket(socketRef, setSocketConnected, socketID, providers) {
    socketRef.current = new WebSocket(
        process.env.REACT_APP_WEBSOCKET_URL
        + '/ws/auth/watch/'
        + socketID
        + '/'
    );

    socketRef.current.onopen = function(e) {
        setSocketConnected(true)
    };

    socketRef.current.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data?.provider) {
            if (data.provider === 'facebook') {
                providers.facebook.method(data.access_token)
                closeSocket()
            } else {
                providers[data.provider].method(data.code, data.redirectUri)
                // loginVK(data.code, data.redirectUri)
                closeSocket()
            }
        }
    };

    socketRef.current.onclose = function(e) {
        setSocketConnected(false)
        socketRef.current = null;
    };
    
    socketRef.current.onerror = function(err) {
        console.log('Socket encountered error. Reconnect in 5 seconds.', err.message);
        closeSocket()
        // setTimeout(function() {
        // connectAuthWatchSocket(socketRef, setSocketConnected, socketID, handleAuthResponse);
        // }, 1000);

    };

    const closeSocket = () => {
        setSocketConnected(false)
        socketRef.current.close();
        socketRef.current = null;
    }
}

export default AuthWatchSocket