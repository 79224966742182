import { useCallback } from 'react'


function usePDFDownload() {
    const downloadFunction = useCallback((link) => {
        console.log('downloadFunction', link)
        const regex = /\w+\.pdf/g;
        const found = regex.exec(link);
        const name = found ? found[0] : ''

        console.log('useDownload')
        fetch(link, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute( 'download', name);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.remove();
        });

    }, []);

    return downloadFunction
}

export default usePDFDownload