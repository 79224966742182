import React, { useRef } from 'react'
import PlyrPlayer from 'components/Player/PlyrPlayer'
import { useState, useEffect } from 'react'
import Container from 'components/UI/Container'

function Preview({videosSource, posterUrl=null}) {
    const [videos, setVideos] = useState([])
    const [poster, setPoster] = useState(posterUrl)

    useEffect(() => {
        if (videos) {
            let temp = []
            if (videosSource.video_1080) {
                temp.push({
                    src: videosSource.video_1080,
                    type: 'video/mp4',
                    size: '1080',
                })
            }
            if (videosSource.video_720) {
                temp.push({
                    src: videosSource.video_720,
                    type: 'video/mp4',
                    size: '720',
                })
            }
            if (videosSource.video_480) {
                temp.push({
                    src: videosSource.video_480,
                    type: 'video/mp4',
                    size: '480',
                })
            }
            setVideos(temp)
        }

    }, [videosSource])


    const [maxHeight, setMaxHeight] = useState(0)
    const courseProgramRef = useRef(null)

    const toggleCourseProgram = () => {
        if (maxHeight == 0) {
            setMaxHeight(courseProgramRef.current.clientHeight)
        } else {
            setMaxHeight(0)
        }
    }


    return (
        <section>
            <Container className={'course-preview'}>
                <PlyrPlayer
                    videos={videos}
                    poster={poster}/>
                <h3 className={maxHeight > 0 ? 'section-subtitle open' : 'section-subtitle'} onClick={toggleCourseProgram}>
                    <i className="fa-solid fa-caret-down me-2"></i> Программа тренировок <i className="fa-solid fa-caret-down ms-2"></i>
                </h3>
                <div className='course-program-wrapper' style={{maxHeight: maxHeight}}>
                    <div className='course-program' ref={courseProgramRef}>
                        <h5>Первая неделя:</h5>
                        <p>Тренировка 1. Дыхание в движении</p>
                        <p>Тренировка 2. Дыхание двух диафрагм</p>
                        <p>Тренировка 3. Свободные таз и поясница</p>
                        <p>Тренировка 4. Пробуждение тазового дна</p>
                        <p>Тренировка 5. Стабильная поясница</p>
                        <h5>Вторая неделя:</h5>
                        <p>Тренировка 6. Гибкость во всём теле</p>
                        <p>Тренировка 7. Низ живота, бедра и тазовое дно</p>
                        <p>Тренировка 8. Мобильность ТБС</p>
                        <p>Тренировка 9. Пресс и движение позвоночника</p>
                        <p>Тренировка 10. Свободная шея и плечи</p>
                        <h5>Третья неделя:</h5>
                        <p>Тренировка 11. Силовая тренировка</p>
                        <p>Тренировка 12. Раскрытие грудного отдела и опора на руки</p>
                        <p>Тренировка 13. ТБС и тазовое дно</p>
                        <p>Тренировка 14. Стопы, колени и таз</p>
                        <p>Тренировка 15. Волнительный урок</p>
                        <h5>Четвёртая неделя:</h5>
                        <p>Тренировка 16. Пресс и сгибание поясницы</p>
                        <p>Тренировка 17. Суставная гимнастика</p>
                        <p>Тренировка 18. Пресс контроль</p>
                        <p>Тренировка 19. МФР ног и таза</p>
                        <p>Тренировка 20. Сила и гибкость позвоночника</p>
                        <h5>Пятая неделя:</h5>
                        <p>Тренировка 21. Пресс и ягодицы</p>
                        <p>Тренировка 22. Расслабление МТД</p>
                        <p>Тренировка 23. Внутренняя поверхность ног</p>
                        <p>Тренировка 24. МФР всего тела</p>
                        <p>Тренировка 25. Дыхание и мобильность грудной клетки</p>
                        <h5>Шестая неделя:</h5>
                        <p>Тренировка 26. Тренировка пресса стоя</p>
                        <p>Тренировка 27. Ягодицы на коврике</p>
                        <p>Тренировка 28. Спина, руки, пресс</p>
                        <p>Тренировка 29. МФР всего тела</p>
                        <p>Тренировка 30. Эластичность тазового дна</p>
                        <h5>Седьмая неделя:</h5>
                        <p>Тренировка 31. Пресс и движения позвоночника</p>
                        <p>Тренировка 32. Улучшение подвижности бёдер</p>
                        <p>Тренировка 33. Освобождение крестца</p>
                        <p>Тренировка 34. Раскрытие грудного отдела</p>
                        <p>Тренировка 35. Стопы и тазовое дно</p>
                        <h5>Восьмая неделя:</h5>
                        <p>Тренировка 36. Пресс и передняя поверхность бедра</p>
                        <p>Тренировка 37. Баланс таза и ягодиц</p>
                        <p>Тренировка 38. Пилатес на раскрытие грудной клетки</p>
                        <p>Тренировка 39. Эластичная отдача тазового дна</p>
                        <p>Тренировка 40. Подборка лучших упражнений</p>
                    </div>
                </div>
                    
                
            </Container>
        </section>
    )
}

export default Preview