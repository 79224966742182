import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import UserService from '../../services/UserService';
import Loader from '../UI/Loader/Loader';
import { useFetching } from '../../hooks/useFetching';
import { useToggle } from '../../hooks/useToggle';
import Avatar from '../UI/Avatar/Avatar';
import './CommentText.css'

function CommentText({text, className}) {
    const regexMention = /(@id\d+\s\([^\)]+\))/g;

    if (text.match(regexMention)) {
        let textArray = text.split(regexMention)
        return(
          <div className={className}>
            {textArray.map(item => {
                if (item.match(regexMention)) {
                    return <UserLink mention={item} key={Math.floor(Math.random() * 1000)}/>
                } else {
                    return item
                }
            })}
          </div>
        )
    }

    return (
        <div className={className}>
            {text}
        </div>
    )
}

export default CommentText

const UserLink = ({mention}) => {
    const [showModal, toggleModal] = useToggle(false)
    const regexID = /@id(\d+)/;
    const regexName = /\(([^\)]+)\)/;
  
    let id = regexID.exec(mention)[1]
    let name = regexName.exec(mention)[1]
  
    return (
        <span className="position-relative" onMouseEnter={()=>toggleModal(true)} onMouseLeave={()=>toggleModal(false)}>
            <Link to={`/${id}`}>{name}</Link>
            {showModal &&
                <UserMentionModal user_id={id}/>
            }
        </span>
  
    )
}

const UserMentionModal = ({user_id}) => {
    const [user, setUser] = useState(null)
  
    const [getMentionInfo, mentionInfoLoading, mentionInfoError] = useFetching(async (user_id) => {
        const response = await UserService.getMentionInfo(user_id)
        if (response && response.status === 200) {
            setUser(response.data)
        }
    })
  
    useEffect(() => {
        getMentionInfo(user_id)
    }, [])
  
    return (
        <div className='mention-modal'>
            {user
            ?
                <>
                    <Avatar src={user.profile_image} showDefault className='avatar-lg mention-avatar'/>
                    <div className='mention-info'>
                    <p className='mention-name'>{user.full_name}</p>
                    <Link to={`/profile/${user_id}`} className='btn mention-link'>Профиль</Link>
                    </div>
                </>
            :
                <div className='mention-loader'>
                    <Loader background={false}/>
                </div>
            }
        </div>
    )
}