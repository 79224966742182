import $api from '../utils/AxiosInstance';

export default class LessonService {
    static async getAllLessons(course_id) {
        return $api.get(`/api/study/get_lessons`, {params: {'course_id':course_id}})
    }

    static async getLesson(course_id, lesson_number) {
        const params = {'course_id': course_id, 'lesson_number': lesson_number}
        return $api.get(`/api/study/get_lesson`, {params: params})
    }

    static async finishLesson(lesson_id) {
        return $api.post(`/api/lessons/${lesson_id}/complete`)
    }
}