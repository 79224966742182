import React from 'react'
import { CircularProgress } from '@mui/material';

function ButtonProgressIndicator({text, circleSize=16}) {
    return (
        <div className="button-progress">
            <CircularProgress color="inherit" size={circleSize} />
            {text &&
                <span style={{paddingLeft: '10px', color: 'inherit'}}>{text}</span>
            }
        </div>
    )
}

export default ButtonProgressIndicator
