import React from 'react'
import { useRef } from 'react'
import { NavLink, Link } from "react-router-dom";
import TouchRipple from '../TouchRipple/TouchRipple';

function RippleNavLink({children, to, className='', isActive, disableRipple=false}) {
    const rippleRef = useRef(null);
    const handleKeyDown = (event) => {
        if (!disableRipple) {
            rippleRef.current.startRipple(event)
        }
    }


    return (
        <Link 
            to={to}
            className={isActive ? `${className} active` : className}
            onMouseDown={e => handleKeyDown(e)}
        >
            {children}
            {!disableRipple && <TouchRipple ref={rippleRef}/>}
        </Link>
    )

    return (
        <NavLink 
            to={to}
            className={className}
            onMouseDown={e => handleKeyDown(e)}
        >
            {children}
            {!disableRipple && <TouchRipple ref={rippleRef}/>}
        </NavLink>
    )
}

export default RippleNavLink