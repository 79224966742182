
class PostServiceLinks {
    static getPosts = '/api/posts/';

        // static getPost(slug) {
        //     return $api.get(`/api/posts/${slug}`, {})
        // }
    
        // static getComments(post_id) {
        //     return $api.get(`/api/posts/${post_id}/comments/`, {})
        // }
    
        // static addNewComment(formData) {
        //     return $api.post(`/api/posts/comments/add/`, formData)
        // }
    
        // static updateComment(comment_id, formData) {
        //     return $api.put(`/api/posts/comments/${comment_id}/edit/`, formData)
        // }
    
        // static deleteComment(comment_id) {
        //     return $api.delete(`/api/posts/comments/${comment_id}/edit/`, {})
        // }
    
        // static likeComment(comment_id) {
        //     return $api.post(`/api/posts/comments/${comment_id}/like/`)
        // }
    
}

export default PostServiceLinks;