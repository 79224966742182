import AuthService from "../../services/AuthService";
import useSocialAuth from "./useSocialAuth";
import LoadingButton from "../UI/Button/LoadingButton";
import { useFetching } from "../../hooks/useFetching";
import { IconGoogle, IconVK, IconFacebook } from '../svg/icons'
import './SocialLogin.css'


function SocialLogin({responseHandler, errorHandler}) {

    const [loginGoogle, loginGoogleLoading] = useFetching(async (google_code) => {
        const response = await AuthService.GoogleLogin(google_code);
        responseHandler(response)
    }, errorHandler)

    const [loginVK, loginVKLoading] = useFetching(async (code, redirectUri) => {
        const response = await AuthService.VKLogin(code, redirectUri);
        responseHandler(response)
    }, errorHandler)

    const [loginFacebook, loginFacebookLoading] = useFetching(async (code) => {
        const response = await AuthService.FBLogin(code);
        responseHandler(response)
    }, errorHandler)

    const providers = {
        google : {
            slug: 'google',
            name: 'Google',
            icon: <IconGoogle height={'24px'}/>,
            method: loginGoogle,
            auth: ()=>openLoginPopup('google'),
            loading: loginGoogleLoading,
            class: "btn btn-social btn-google"
        },
        vk : {
            slug: 'vk',
            name: 'ВКонтакте',
            icon: <IconVK height={'20px'}/>,
            method: loginVK,
            auth: ()=>openLoginPopup('vk'),
            loading: loginVKLoading,
            class: "btn btn-social btn-vk mx-3"
        },
        facebook : {
            slug: 'facebook',
            name: 'Facebook',
            icon: <IconFacebook height={'24px'}/>,
            method: loginFacebook,
            auth: ()=>openLoginPopup('facebook'),
            loading: loginFacebookLoading,
            class: "btn btn-social btn-facebook"
        }
    }

    const openLoginPopup = useSocialAuth(providers)

    return (
        <div className='social-login-wrapper'>
            {Object.keys(providers).map(item => (
                <LoadingButton 
                    onClick={providers[item].auth}
                    isLoading={providers[item].loading}
                    className={providers[item].class}
                    circleSize={22}
                    enableText={false}
                    key={item}
                >
                    {providers[item].icon}
                </LoadingButton>
            ))

            }
        </div>
    )
}

export default SocialLogin