import Expire from './Courses/Expire'
import PurchaseExtend from './Courses/PurchaseExtend'
import Structure from './Courses/Structure'
import Updates from './Courses/Updates'

import Login from './GetStarted/Login'
import Purchase from './GetStarted/Purchase'
import SignUp from './GetStarted/SignUp'
import Contacts from './Feedback/Contacts'
import PrivacyPolicy from './Agreements/PrivacyPolicy'
import PublicOffer from './Agreements/PublicOffer'
import { IconStart, IconCourses, IconSettings, IconSecurity, IconFeedback } from 'components/svg/icons';




const LinkBlocks = [
    {
        title: 'Начало',
        link: 'get-started',
        icon: <IconStart className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'signup', title: 'Регистрация', component: SignUp},
            {to: 'login', title: 'Вход в аккаунт', component: Login},
            {to: 'purchase', title: 'Покупка и оплата', component: Purchase}
        ]
    },
    {
        title: 'Курсы',
        link: 'courses',
        icon: <IconCourses className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'structure', title: 'Структура курсов', component: Structure},
            {to: 'updates', title: 'Обновления', component: Updates},
            {to: 'expire', title: 'Время доступа', component: Expire},
            {to: 'purchase-extend', title: 'Покупка и продление', component: PurchaseExtend}
        ]
    },
    {
        title: 'Настройки',
        link: 'settings',
        icon: <IconSettings className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'email', title: 'Почтовый ящик'},
            {to: 'password', title: 'Пароль'},
            {to: 'socials', title: 'Социальные сети'},
            {to: 'notifications', title: 'Уведомления'}
        ]
    },
    {
        title: 'Безопасность',
        link: 'security',
        icon: <IconSecurity className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'private', title: 'Личные данные'},
            {to: 'restore', title: 'Восстановление доступа'},
            {to: 'account-deletion', title: 'Удаление аккаунта'},
        ]
    },
    {
        title: 'Обратная связь',
        link: 'feedback',
        icon: <IconFeedback className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'contacts', title: 'Связаться с нами', component: Contacts}
        ]
    },
    {
        title: 'Соглашения',
        link: 'agreements',
        icon: <IconFeedback className='icon-filled me-2' height={20}/>,
        links: [
            {to: 'public-offer', title: 'Публичная оферта', component: PublicOffer},
            {to: 'privacy-policy', title: 'Политика конфиденциальности', component: PrivacyPolicy}
        ]
    },
]


export default LinkBlocks