import React from 'react'
import './Avatar.css'

function Avatar({src, user, showDefault, className, alt}) {

    if (src) {
        return (
            <div className={`avatar ${className ? className : ''}`}>
                <img alt={alt} src={src}/>
            </div>
        )
    }

    if (user && user.profile_image) {
        return (
            <div className={`avatar ${className ? className : ''}`}>
                <img alt={user.full_name} src={user.profile_image}/>
            </div>
        )
    }

    if (showDefault) {
        return (
            <div className={`avatar 2 ${className ? className : ''}`}>
                <img src="https://static.maddy.fit/images/default_ava.png"/>
            </div>
        )
    }

    return null
}

export default Avatar
