import React, { useRef, useState } from "react";
import Container from "components/UI/Container";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper.min.css'
import "swiper/modules/pagination/pagination.min.css";


function Reviews() {
    return (
        <section className="reviews-before-after-section">
            <Container className={"reviews-container"}>
                <h2 className="section-title"><span className="text-color-theme">Результаты</span> участников курса</h2>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    freeMode={false}
                    breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        }
                    }}
                    className="reviews-slider"
                    loop={true}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[FreeMode, Pagination]}
                >
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/review_1.jpg" alt="Review"></img></SwiperSlide>
                </Swiper>
            </Container>
            <Container className={"before-after-container"}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    freeMode={false}
                    breakpoints={{
                        850: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        }
                    }}
                    centeredSlides={true}
                    className="before-after-slider"
                    loop={true}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[FreeMode, Pagination]}
                >
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                    <SwiperSlide><img src="/images/before_after.png" alt="Review"></img></SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}

export default Reviews
