import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { IconVK, IconFacebook, IconTelegram, IconTwitter, IconOdnoklassniki, IconInstagram, IconTiktok } from 'components/svg/icons';
import InputErrorMsg from 'components/InputErrorMsg';
import { useInput } from 'hooks/useInput';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import LoadingButton from 'components/UI/Button/LoadingButton';
import Alerts from 'components/Alerts';
import { useFetching } from 'hooks/useFetching';
import SupportService from 'services/SupportService';
import { nonFieldErrorsToMessages, handleFormErrors, resetForm } from 'utils/Forms';

function Contacts() {

    const [alertMessages, setAlertMessages] = useState([])


    const socialLinks = [
        {link: 'https://vk.com/', icon: <IconVK height={32}/>},
        {link: 'https://facebook.com/', icon: <IconFacebook height={32}/>},
        {link: 'https://instagram.com/', icon: <IconInstagram height={32}/>},
        {link: 'https://tiktok.com/', icon: <IconTiktok height={32}/>},
        {link: 'https://t.me/', icon: <IconTelegram height={32}/>},
        {link: 'https://twitter.com/', icon: <IconTwitter height={32}/>},
        {link: 'https://ok.ru/', icon: <IconOdnoklassniki height={32}/>}
    ]
    
    const email = useInput('', 'email', 'email', {notEmpty: true, isEmail: true})
    const message = useInput('', 'message', 'text', {notEmpty: true, minLength: 3, maxLength: 1000})


    function submitMessage() {
        const isEmailValid = email.StartValidation()
        const isMessageValid = message.StartValidation()
        if (isEmailValid && isMessageValid) { sendMessage() }
    }

    const [sendMessage, sendMessageLoading, sendMessageError] = useFetching(async () => {
        const formData = new FormData()
        formData.append('email', email.value)
        formData.append('message', message.value)

        const response = await SupportService.sendFormMessage(formData)
        if (response.status===200) {
            setAlertMessages([{level: 'success', message: 'Сообщение отправлено! Скоро мы вам ответим'}])
            resetForm([email, message])
        }
    })

    useEffect(() => {
        handleFormErrors( [email, message], sendMessageError, setAlertMessages )
    }, [sendMessageError])


    return (
        <Container className="d-flex flex-column pt-3">
        <section id='social' className='mb-5'>
            <h4 className="text-center text-normal mb-3">Мы в соцсетях</h4>
            <div className='profile-socials'>
                {socialLinks.map(social => (
                    <a key={social.link} href={social.link}>{social.icon}</a>
                ))}
            </div>
        </section>
        <section id='email' className='mt-2 mb-5'>
            <h4 className="text-center text-normal mb-3">Напишите на нашу почту</h4>
            <h4 className='text-center'><u>info@maddy.fit</u></h4>
        </section>
        <section id='form' className='mt-2 mb-5'>
            <h4 className="text-center text-normal mb-3">Или оставьте нам сообщение</h4>
            <div className='contact-form-wrapper'>
                <Alerts messages={alertMessages} setMessages={setAlertMessages}/>
                <dl className="form-group">
                    <dd>
                        <input 
                        type="email" 
                        id="email" 
                        className="form-control" 
                        value={email.value} 
                        onChange={e => email.onChange(e)} 
                        required
                        placeholder='Ваша почта'/>
                    </dd>
                    <InputErrorMsg field={email}/>
                </dl>
                <dl className="form-group">
                    <dd>
                        <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        value={message.value} 
                        onChange={e => message.onChange(e)}
                        placeholder='Сообщение'
                        resize={'none'}/>
                    </dd>
                    <InputErrorMsg field={message}/>
                </dl>
                <LoadingButton isLoading={sendMessageLoading} className={'btn'} onClick={submitMessage}>
                    Отправить
                </LoadingButton>
            </div>
        </section>
        </Container>
    )
}

export default Contacts