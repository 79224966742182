import { useState, useEffect, useContext } from "react";
import 'styles/pages/StudyPage.css'
import SurveyService from "services/SurveyService";
import { useFetching } from 'hooks/useFetching';
import LessonService from 'services/LessonService';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useToggle } from 'hooks/useToggle';
import SwipeableMenu from 'components/UI/SwipeableMenu/SwipeableMenu';
import LessonNavMenu from 'components/Study/LessonNavMenu';
import MessageContext from 'context/MessageContext';
import { Routes, Route, useLocation } from "react-router-dom";
import StepPage from "./StepPage";
import StartFinishPage from "./StartFinishPage";

function StudyStandard({course, setCourse}) {
    
    const { setMessages } = useContext(MessageContext)

    const [lessons, setLessons] = useState([])
    const [showLessonNavDefault, toggleLessonNavDefault] = useToggle(true)
    const [showLessonNavMobile, toggleLessonNavMobile] = useToggle(false)
    const { pathname } = useLocation();
    let { course_number } = useParams();


    useEffect(() => {
        getAllLessons()
    }, [])

    const [getAllLessons, getAllLessonsLoading, getAllLessonsError] = useFetching(async () => {
        const response = await LessonService.getAllLessons(course.id)
        setLessons(response.data)
    })


    return (
        <>
            <Container>
                <div className="study-page-wrapper">
                    {showLessonNavDefault &&
                        <div className='lesson-nav-menu'>
                            <LessonNavMenu course={course} lessons={lessons}/>
                        </div>
                    }
                    <div className="lesson-main-bar">
                        <Routes>
                            <Route path={`start`} element={<StartFinishPage courseID={course.id} type={'start'}/>} />
                            <Route path={`finish`} element={<StartFinishPage courseID={course.id} type={'finish'}/>} />

                            <Route
                                path={`:lesson_number/:step_number`}
                                element={
                                    <StepPage 
                                    course={course}
                                    setCourse={setCourse}
                                    lessons={lessons}
                                    setLessons={setLessons}
                                    toggleLessonNavDefault={toggleLessonNavDefault}
                                    toggleLessonNavMobile={toggleLessonNavMobile}
                                />
                                }
                            />
                            <Route path={`:lesson_number`} element={<StepPage />}/>
                        </Routes>
                    </div>
                    

                    {/* <Modal isOpen={false}>
                        <img src="https://static.maddy.fit/images/courses/1/slide_1.jpg"></img>
                    </Modal> */}
                </div>

            </Container>

            <SwipeableMenu 
                isOpen={showLessonNavMobile} 
                setOpen={toggleLessonNavMobile} 
                className={'lesson-nav-menu-mobile'}>
                <LessonNavMenu course={course} lessons={lessons}/>
            </SwipeableMenu>
        </>
    )
}

export default StudyStandard