import React, {useEffect, useState, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { useFetching } from '../hooks/useFetching';
import UserService from '../services/UserService';
import Avatar from '../components/UI/Avatar/Avatar';
import {Container, Row, Col} from 'react-bootstrap';
import BackdropLoader from '../components/UI/BackdropLoader/BackdropLoader';
import AuthContext from '../context/AuthContext'
import { Link, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify'
import '../styles/pages/ProfilePage.css'
import {IconVK, IconFacebook, IconTelegram, IconTwitter, IconOdnoklassniki, IconInstagram, IconTiktok} from '../components/svg/icons'
import PostService from '../services/PostService';
import PostPagination from '../components/Posts/PostPagination';
import PostBasicCard from '../components/Posts/PostBasicCard';
import { useNamedQuery } from '../hooks/useQuery';
import Loader from '../components/UI/Loader/Loader'


function ProfilePage() {
    const [profile, setProfile] = useState(null)
    const [posts, setPosts] = useState(null)
    const [pagination, setPagination] = useState(null)
    const { user } = useContext(AuthContext);
    const params = useParams()
    const { search } = useLocation();
    const currentPage = useNamedQuery('page')


    useEffect(() => {
        fetchUser(params.id)
    }, [])


    useEffect(() => {
        if (profile) {
            getSocialLinks()
            if (profile.is_writer) { fetchPosts() }
        }
    }, [profile, search])

    const [fetchUser, fetchUserLoading, fetchUserError] = useFetching(async (id) => {
        const response = await UserService.getPublicInfo(id)
        setProfile(response.data)
    })

    const [fetchPosts, postsLoading, postsError] = useFetching(async () => {
        let param = {}
        param['author'] = profile.id
        param['page_size'] = 4
        if (currentPage && currentPage > 1) {param['page'] = currentPage}
        const response = await PostService.getPosts(param)
        setPosts(response.data.posts)
        setPagination(response.data.pagination)
    })

    const [socialLinks, setSocialLinks] = useState(
        [
            {id: 'vk_link', domain: 'https://vk.com/', value: '', icon: <IconVK height={32}/>, is_active: false},
            {id: 'facebook_link', domain: 'https://facebook.com/', value: '', icon: <IconFacebook height={32}/>, is_active: false},
            {id: 'instagram_link', domain: 'https://instagram.com/', value: '', icon: <IconInstagram height={32}/>, is_active: false},
            {id: 'tiktok_link', domain: 'https://tiktok.com/', value: '', icon: <IconTiktok height={32}/>, is_active: false},
            {id: 'telegram_link', domain: 'https://t.me/', value: '', icon: <IconTelegram height={32}/>, is_active: false},
            {id: 'twitter_link', domain: 'https://twitter.com/', value: '', icon: <IconTwitter height={32}/>, is_active: false},
            {id: 'odnoklassniki_link', domain: 'https://ok.ru/', value: '', icon: <IconOdnoklassniki height={32}/>, is_active: false}
        ]
    )

    const [getSocialLinks, getSocialLinksLoading, getSocialLinksError] = useFetching(async () => {
        const response = await UserService.getSocialLinks()
        console.log('response', response)
        const new_array = socialLinks.map( social => {
            if (response.data[social.id]) {
                return {...social, value: response.data[social.id], is_active: true}
            }
            return social
        })
        setSocialLinks(new_array)
    })

    if (!profile) {return <BackdropLoader />}

    return (
        <Container className="pt-3 container-medium">
            <div className='d-flex flex-column align-items-center mb-4'>
                <Avatar className='avatar-xl mb-2' alt={profile.full_name} src={profile.profile_image} />
                <h3>{profile.full_name}</h3>
                {profile.id === user?.id &&
                    <Link className='btn mt-3' to='/settings/info'>Настройки аккаунта</Link>
                }
            </div>
            <section id='bio' className='mb-4'>
                <div className="subhead">
                    <h2 className="subhead-title">Обо мне</h2>
                </div>
                <div className='bio-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(profile.bio), }}/>
            </section>
            <section id='social' className='mb-4'>
                <div className="subhead">
                    <h2 className="subhead-title">Мои соцсети</h2>
                </div>
                <div className='profile-socials'>
                    {socialLinks.map(social => (
                        <SocialLink key={social.domain} social={social}/>
                    ))}
                </div>
            </section>
            {posts &&
                <section id='articles'>
                <div className="subhead">
                    <h2 className="subhead-title">Мои статьи</h2>
                </div>
                <Row style={{position: 'relative'}}>
                    {postsLoading && <Loader background/>}
                    {posts.map(post =>
                        <Col sm={12} lg={6} className="mb-5" key={post.id}>
                            <PostBasicCard post={post}/>
                        </Col>
                    )}
                </Row>
                <PostPagination pagination={pagination} className='pt-0'/>
                </section>
            }
        </Container>
    )
}

export default ProfilePage

const SocialLink = ({social}) => {
    if (social.is_active) {
        return <a href={social.domain + social.value}>{social.icon}</a>
    }
    return <span className='icon-disabled'>{social.icon}</span>
}