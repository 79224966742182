import { useState, useEffect, useCallback, useMemo } from 'react'
import useLocalContext from './useLocalContext'

export const useInput = (initialValue, name, type, validations) => {
    // Type is needed to display correct error message
    const [value, setValue] = useState(initialValue)
    const valid = useValidation(value, type, validations)
    const intRegex = /^\d*$/;
    const floatRegex = /^\d*\.?\d*$|^$/;

    const onChange = useCallback((e) => {
        let newValue = e.target.value
        if (type == 'integer' && !intRegex.test(newValue)) {
            return
        }
        if (type == 'float' && !floatRegex.test(newValue)) {
            return
        }
        // if (type == 'float' && (isNaN(newValue) || newValue.includes(" "))) {
        //     return
        // }
        setValue(newValue)
    },[])

    const reset = useCallback(() => {
        valid.StopValidation()
        setValue(initialValue)
    },[])

    const obj = useMemo(() => {
        return {value, name, reset, setValue, onChange, ...valid}
    }, [value, valid])

    return obj
}


const ErrorMessages = {
    email: {
        notEmpty: "Email не может быть пустым",
        isEmail: "Некорректный email",
        minLength: "Email слишком короткий",
        maxLength: "Email слишком длинный"
    },
    password: {
        notEmpty: "Пароль не может быть пустым",
        isMatch: "Пароли не совпадают",
        minLength: "Пароль слишком короткий",
        maxLength: "Пароль слишком длинный"
    },
    text: {
        notEmpty: "Поле не может быть пустым",
        isMatch: "Текст не совпадает",
        minLength: "Текст слишком короткий",
        maxLength: "Текст слишком длинный"
    },
    short_text: {
        notEmpty: "Поле не может быть пустым",
        isMatch: "Текст не совпадает",
        minLength: "Текст слишком короткий",
        maxLength: "Текст слишком длинный"
    },
    integer: {
        notEmpty: "Поле не может быть пустым",
        isMatch: "Число не совпадает",
        minNumber: "Число слишком маленькое",
        maxNumber: "Число слишком большое"
    },
    float: {
        notEmpty: "Поле не может быть пустым",
        isMatch: "Число не совпадает",
        minNumber: "Число слишком маленькое",
        maxNumber: "Число слишком большое"
    },
    date: {
        notEmpty: "Дата не может быть пустой",
        isDate: "Неверный формат даты",
        minDate: "Дата меньше максимально допустимой",
        maxDate: "Дата выше максимально допустимой"
    },
    file: {
        notEmpty: "Не выбран файл",
        maxFileSize: "Максимальный размер файла VALUE мегабайт"
    }
}




const useValidation = (value, type, validations) => {

    const [validationOn, setValidationOn] = useState(false)
    const [manualValid, setManualValid] = useState(true)
    const [messages, setMessages] = useState([])

    useEffect(() => {
        if (validationOn && manualValid == false) {
            setManualValid(true)
            let result = StartValidation()
            if (result) {
                setMessages([])
            }
        }
    }, [value])

    const StartValidation = useCallback(() => {
        setValidationOn(true)
        return checkInputs(value, validations, allRules)
    }, [value])
    
    const StopValidation = useCallback(() => {
        setValidationOn(false)
    }, [])


    const notEpmtyRule = useValidationRule('notEmpty', (value, isRequired) => {
        if (isRequired == false) { return true }
        return ((value !== null) && (value !== ''))
    }, value, validations, validationOn, type)

    const minLengthRule = useValidationRule('minLength', (value, goal) => {
        return value.length >= goal
    }, value, validations, validationOn, type)

    const maxLengthRule = useValidationRule('maxLength', (value, goal) => {
        return value.length <= goal
    }, value, validations, validationOn, type)

    const minNumberRule = useValidationRule('minNumber', (value, goal) => {
        return value >= goal
    }, value, validations, validationOn, type)

    const maxNumberRule = useValidationRule('maxNumber', (value, goal) => {
        return value <= goal
    }, value, validations, validationOn, type)

    const isDateRule = useValidationRule('isDate', (value, goal) => {
        return (( value === null ) || ((value instanceof Date) && (value != 'Invalid Date')))
    }, value, validations, validationOn, type)

    const minDateRule = useValidationRule('minDate', (value, goal) => {
        return (( value === null ) || (value instanceof Date) && (value > goal))
    }, value, validations, validationOn, type)

    const maxDateRule = useValidationRule('maxDate', (value, goal) => {
        return (( value === null ) || (value instanceof Date) && (value < goal))
    }, value, validations, validationOn, type)

    const matchRule = useValidationRule('isMatch', (value, goal) => {
        return (value === goal)
    }, value, validations, validationOn, type)

    const maxFileSizeRule = useValidationRule('maxFileSize', (value, goal) => {
        if (value === null) {
            return true
        }
        const sizeInMb = Math.round(value.size / 1024 ** 2);
        return sizeInMb <= goal
    }, value, validations, validationOn, type)

    const emailRule = useValidationRule('isEmail', (value, goal) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase())
    }, value, validations, validationOn, type)

    const allRules = useMemo(() => {
        return [notEpmtyRule, minLengthRule, maxLengthRule, minNumberRule, maxNumberRule, isDateRule, minDateRule, maxDateRule, matchRule, maxFileSizeRule, emailRule]
    }, [notEpmtyRule, minLengthRule, maxLengthRule, minNumberRule, maxNumberRule, isDateRule, minDateRule, maxDateRule, matchRule, maxFileSizeRule, emailRule])


    const allRulesValids = useMemo(() => {
        return [notEpmtyRule.isValid,minLengthRule.isValid,maxLengthRule.isValid,minNumberRule.isValid,maxNumberRule.isValid,isDateRule.isValid,
        minDateRule.isValid,maxDateRule.isValid,matchRule.isValid,maxFileSizeRule.isValid,emailRule.isValid]
    }, [notEpmtyRule.isValid, minLengthRule.isValid,maxLengthRule.isValid,minNumberRule.isValid,maxNumberRule.isValid,isDateRule.isValid,
        minDateRule.isValid,maxDateRule.isValid,matchRule.isValid,maxFileSizeRule.isValid,emailRule.isValid]
    )

    const isValid = useMemo(() => {
        return allRulesValids.every(Boolean) && manualValid
    }, [allRulesValids])


    useEffect(() => {
        if (validationOn) { 
            if (isValid) {
                setMessages([])
            } else {
                let msg = createErrorMessges(allRules)
                setMessages(msg)
            }
         }
    }, [allRulesValids])


    const setFormErrors = (errors) => {
        if (errors.length > 0) {
            let _messages = []
            errors.forEach((error) => {
                _messages = [..._messages, error]
            })
            setMessages(_messages)
        }
    }

    const obj = useMemo(() => {
        return { isValid, setManualValid, StartValidation, StopValidation, messages, setFormErrors }
    }, [isValid, StartValidation, messages, setFormErrors]
    )

    return obj

}

export function setFormsErrors(fields, errors) {
    let errorsObj;
    if (Array.isArray(errors)) {
        errorsObj = errors[0]
    } else {
        errorsObj = errors
    }
    fields.forEach(field => {
        if (errorsObj[field.name]) {
            let err_msg = []
            for (const x in errorsObj[field.name]) {
                err_msg.push(errorsObj[field.name][x])
            }
            field.setManualValid(false)
            field.setFormErrors(err_msg)
        }
    })
};

function createErrorMessges(allRules) {
    let msg = []
    let _notEpmtyRule = allRules.find(_rule => { return _rule.name === 'notEmpty' })
    let _isDateRule = allRules.find(_rule => { return _rule.name === 'isDate' })
    if (!_notEpmtyRule.isValid) { return [_notEpmtyRule.getErrorMessage()] }
    if (!_isDateRule.isValid) { return [_isDateRule.getErrorMessage()] }
    allRules.forEach(rule => {
        if (!rule.isValid) {
            msg = [...msg, rule.getErrorMessage()]
        }
    })
    return msg
}

const checkInputs = (value, validations, allRules) => {
    let _isValid = true
    for (const validation in validations) {
        let rule = allRules.find(_rule => { return _rule.name === validation })
        let result = rule.manualCheck(value, validations[validation])
        if (result === false) { return false }
    }
    return _isValid
}


const useValidationRule = (ruleName, method, value, validations, validationOn, type) => {
    const [name, setName] = useState(ruleName)
    const [isValid, setIsValid] = useState(true)

    const manualCheck = (_value, _goal) => {
        return method(_value, _goal)
    }

    const getErrorMessage = () => {
        return ErrorMessages[type]?.[name]?.replace('VALUE', validations[ruleName])
    }

    useEffect(() => {
        if (validationOn && name in validations && validations[name] != null) {
            let result = method(value, validations[name])
            setIsValid(result)
        }
    }, [validationOn, value])

    const obj = useMemo(() => {
        return {name, manualCheck, isValid, getErrorMessage}
    }, [name, manualCheck, isValid, getErrorMessage])

    return obj
}

