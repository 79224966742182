import { useState, useEffect, useRef } from 'react'
import { useFetching } from '../hooks/useFetching'
import { Container } from 'react-bootstrap';
import PostPagination from '../components/Posts/PostPagination';
import { useLocation, useNavigate } from "react-router-dom";
import { useNamedQuery } from '../hooks/useQuery';
import RecipeService from '../services/RecipeService';
import RecipeCard from '../components/Recipes/RecipeCard';
import '../styles/pages/RecipesListPage.css'
import Loader from '../components/UI/Loader/Loader'
import Button from '../components/UI/Button/Button'
import RepiceFiltersMenu from '../components/Recipes/RepiceFiltersMenu';
import { toggleFilterInArray } from '../components/Recipes/utils';

function RecipesListPage() {
    const [recipes, setRecipes] = useState([])
    const [pagination, setPagination] = useState(null)
    const [isInit, setIsInit] = useState(true)
    const [isFiltersOpen, setFiltersOpen] = useState(false)
    const { search } = useLocation();
    const currentPage = useNamedQuery('page')
    const filtersWrapper = useRef(null)
    const location = useLocation()
    const navigate = useNavigate()


    const [filters, setFilters] = useState({
        diet: [],
        meal: [],
        time: [
            {slug: 'lte_10', name: "Менее 10 минут", is_active: false},
            {slug: 'lte_30', name: "Менее 30 минут", is_active: false},
            {slug: 'gte_30', name: "Более 30 минут", is_active: false},
        ]
    })

    const [fetchAllTags] = useFetching(async () => {
        const response = await RecipeService.getAllTags()
        setInitialFilters(response.data)
        setIsInit(false) // initialization complete
    })

    const [fetchRecipes, recipesLoading, recipesError] = useFetching(async () => {
        let param = {}
        param['page_size'] = '5'
        param['order'] = '-date_created'
        console.log('fetchRecipes location?.state', location.state)
        if (location?.state?.diet) {param['diet'] = location.state.diet}
        if (location?.state?.meal) {param['meal'] = location.state.meal}
        if (location?.state?.time) {param['time'] = location.state.time}

        if (currentPage && currentPage > 1) {param['page'] = currentPage}
        
        const response = await RecipeService.getRecipes(param)
        setRecipes(response.data.recipes)
        setPagination(response.data.pagination)
    })

    // Fetch all filters
    useEffect(() => {
        fetchAllTags()
    }, [])

    // fetch Recipes on every Search change (page number change), including initialization
    useEffect(() => {
        fetchRecipes()
    }, [search])

    // update State on every Filters change, except initialization
    useEffect(() => {
        if (!isInit) {
            updateState(filters, navigate)
        }
    }, [filters])

    // fetch Recipes on every State change, except initialization
    useEffect(() => {
        if (!isInit) {
            fetchRecipes()
        }
    }, [location.state])


    const setInitialFilters = (filtersResponse) => {
        // This method sets filters that were received from server
        // Server sets Diet and Meal filters
        // Time filters are set by front end
        // This method checks which filters are initially active

        let dietFilters = checkActiveFiltersByType(location, 'diet', filtersResponse.diet)
        let mealFilters = checkActiveFiltersByType(location, 'meal', filtersResponse.meal)
        let timeFilters = checkActiveFiltersByType(location, 'time', filters.time)

        setFilters({ ...filters, diet: dietFilters, meal: mealFilters, time: timeFilters })
    }


    useEffect(()=>{
        if (isFiltersOpen) {
            filtersWrapper?.current?.classList?.add('open')
        } else {
            filtersWrapper?.current?.classList?.remove('open')
        }
    }, [isFiltersOpen])


    const toggleFilterMenu = ()=> {
        if (isFiltersOpen) {
            filtersWrapper.current.classList.add('open')
            setFiltersOpen(false)
        } else {
            filtersWrapper.current.classList.remove('open')
            setFiltersOpen(true)
        }
    }


    return (
        <>
            <Container className="mt-3 fullheight-container">
                {Object.keys(filters).length > 0 &&
                    <section>
                        <div className="recipe-filters-wrapper" ref={filtersWrapper}>
                            <div className="recipe-filters-controls">
                                <Button className={"btn"} onClick={toggleFilterMenu}>Фильтры</Button>

                            </div>
                            <div className='active-filters'>
                                {filters.meal.map(filter => {
                                    if (filter.is_active) {
                                        return <ActiveFilter key={filter.slug} filter={filter} type={'meal'} allFilters={filters} setAllFilters={setFilters}/>
                                    }    
                                })}
                                {filters.diet.map(filter => {
                                    if (filter.is_active) {
                                        return <ActiveFilter key={filter.slug} filter={filter} type={'diet'} allFilters={filters} setAllFilters={setFilters}/>
                                    }    
                                })}
                                {filters.time.map(filter => {
                                    if (filter.is_active) {
                                        return <ActiveFilter key={filter.slug} filter={filter} type={'time'} allFilters={filters} setAllFilters={setFilters}/>
                                    }    
                                })}
                            </div>
                            {isFiltersOpen &&
                                <RepiceFiltersMenu 
                                    isOpen={isFiltersOpen}
                                    setOpen={toggleFilterMenu}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                            }

                        </div>
                    </section>
                }
                {(recipesLoading)
                    ?
                        <Loader background={false}/>
                    :
                        (recipes.length > 0)
                            ?
                            <section className='recipe-list'>
                                {recipes.map(recipe => 
                                    <RecipeCard key={recipe.id} recipe={recipe}/>
                                )}
                            </section>
                            :
                            <div className='no-items'>Рецепты не найдены</div>
                        
                }

                

            </Container>
            {pagination &&
                <PostPagination pagination={pagination}/>
            }
        </>
    )
}

export default RecipesListPage

const ActiveFilter = ({filter, type, allFilters, setAllFilters}) => {
    return <button 
                onClick={()=>toggleFilterInArray(filter, type, allFilters, setAllFilters)} 
                className='active-filter'>
                    {filter.name}<i className="fa-solid fa-xmark"></i>
            </button>
}


const checkActiveFiltersByType = (location, filterType, filtersOfType) => {

    let currectFilterState = location?.state?.[filterType]
    let currentFilterTags = []

    if (currectFilterState) { currentFilterTags = currectFilterState.split(',') }

    let processedFilters = filtersOfType.map(filter => {
        if (currentFilterTags.includes(filter.slug)) {
            return {...filter, is_active: true}
        } else {
            return {...filter, is_active: false}
        }
    })

    return processedFilters
}


const updateState = (filters, navigate) => {
    const state = { ...navigate.location.state };
    Object.keys(filters).map(key => {
        let activeFilterStates = []
        filters[key].forEach(filter => {
            if (filter.is_active) {
                activeFilterStates.push(filter.slug)
            }
        })
        if (activeFilterStates.length > 0) {
            state[key] = activeFilterStates.join(',')
        } else {
            delete state[key]
        }
    })
    // FIX IT!!
    // navigate()
    // navigate.replace({ ...history.location, state });
}