import { createContext, useEffect } from 'react';
import { useMediaQuery } from '../hooks/useMediaQuery'
import { useLocalStorage } from '../hooks/useStorage'


const ThemeContext = createContext()
export default ThemeContext;


export const ThemeProvider = ({children}) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    const [darkMode, setDarkMode] = useLocalStorage("useDarkMode", prefersDarkMode)

    useEffect(() => {
        if(darkMode) {
            // trans()
            document.documentElement.setAttribute('data-theme', 'dark')
        } else {
            // trans()
            document.documentElement.setAttribute('data-theme', 'light')
        }
    }, [darkMode])

    let contextData = {
        darkMode:darkMode,
        setDarkMode:setDarkMode
    }

    return (
        <ThemeContext.Provider value={contextData}>
            {children}
        </ThemeContext.Provider>
    )
}

let trans = () => {
    document.documentElement.classList.add('transition');
    window.setTimeout(() => {
        document.documentElement.classList.remove('transition')
    }, 1000)
}