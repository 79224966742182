import React from 'react'
import { useState, useEffect, useContext } from "react";
import formatISO from 'date-fns/formatISO'
import { styled } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ruLocale from 'date-fns/locale/ru';
import AuthContext from '../../context/AuthContext';
import UserService from '../../services/UserService';
import ImageCroppper from './ImageCroppper';
import { Row, Col } from 'react-bootstrap';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import HelperText from '../UI/HelperText/HelperText';
import { useToggle } from '../../hooks/useToggle';
import { useFetching } from '../../hooks/useFetching';
import { useInput } from '../../hooks/useInput';
import InputErrorMsg from '../InputErrorMsg';
import { DropdownMenu, DropdownItem } from '../UI/Dropdown/DropdownMenu';
import Button from '../UI/Button/Button';
import Avatar from 'components/UI/Avatar/Avatar';
import SubHead from 'components/UI/SubHead';



function UserInfo({toggleMobileMenu}) {

    const { user, setUser, isAuth, test } = useContext(AuthContext);
    const [showSuccessMessage, setShowSucccessMessage] = useState(false)
    const minBirthDate = new Date('1900-01-01')
    const maxBirthDate = new Date()
    const first_name = useInput(user.first_name, 'first_name', 'text', {notEmpty: true, minLength: 3, maxLength: 50})
    const last_name = useInput(user.last_name, 'last_name', 'text', {maxLength: 50})
    const birth_date = useInput(user.birth_date ? new Date(user.birth_date) : null, 'birth_date', 'date', {isDate: true, minDate: minBirthDate, maxDate: maxBirthDate})
    const bio = useInput(user.bio, 'bio', 'text', {maxLength: 50})


    const [newAvatar, setNewAvatar] = useState(null)
    const [openImageCropper, setOpenImageCropper] = useState(false)
    const [showAvatarMenu, toogleAvatarMenu] = useToggle(false)



    useEffect(() => {
        if(newAvatar && newAvatar.size > 0) {
            setOpenImageCropper(true)
        }
    }, [newAvatar])


    const onFileChange = e => {
        setNewAvatar(e.target.files[0])
    }


    function submit(e) {
        e.preventDefault()
        const isFirstNameValid = first_name.StartValidation()
        const isLastNameValid = last_name.StartValidation()
        const isBirthDateValid = birth_date.StartValidation()
        const isBioValid = bio.StartValidation()
        if (isFirstNameValid && isLastNameValid && isBirthDateValid && isBioValid) {
            updateUserInfo()
        }
    }



    const [updateUserInfo, updateUserInfoLoading, updateUserInfoError] = useFetching(async (e) => {
        const formData = new FormData()
        formData.append('first_name', first_name.value)
        formData.append('last_name', last_name.value)
        formData.append('bio', bio.value)

        if (birth_date.value) {
            const formatedDate = formatISO(birth_date.value, { representation: 'date' })
            formData.append('birth_date', formatedDate)
        } else {
            formData.append('birth_date', '')
        }

        const response = await UserService.updateBasicInfo(formData)

        first_name.setValue(response.data.first_name)
        last_name.setValue(response.data.last_name)
        bio.setValue(response.data.bio)

        let new_date = response.data.birth_date
        birth_date.setValue(new_date ? new Date(response.data.birth_date) : null)

        setShowSucccessMessage(true)

        setTimeout(() => {
            setShowSucccessMessage(false)
        }, [5000]);
    })


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <SubHead title={'Моя информация'} toggleMobileMenu={toggleMobileMenu} />
        <Row>
            <Col sm={12} lg={8} className='mb-4'>
            <form className="profile-form">
                <div>
                    <dl className="form-group">
                        <dt><label className="form-label" htmlFor="inputFirstName">Имя *</label></dt>
                        <dd>
                            <input 
                            type="text" 
                            id="inputFirstName" 
                            className="form-control short" 
                            value={first_name.value} 
                            onChange={e => first_name.onChange(e)} 
                            required/>
                            <div className="note">
                            Имя и фамилия видны другим пользователям
                            </div>
                        </dd>
                        <InputErrorMsg field={first_name}/>
                    </dl>
                    <dl className="form-group">
                        <dt><label className="form-label" htmlFor="inputLastName">Фамилия</label></dt>
                        <dd>
                            <input 
                            type="text" 
                            id="inputLastName" 
                            className="form-control short" 
                            value={last_name.value} 
                            onChange={e => last_name.onChange(e)}
                            />
                        </dd>
                        <InputErrorMsg field={last_name}/>
                    </dl>
                    <dl className="form-group date">
                        <dt><label className="form-label" htmlFor="inputBirthDate">Дата рождения</label></dt>
                        <dd>
                        <DatePicker   
                                mask={'__.__.____'} 
                                value={birth_date.value}
                                onChange={(newValue) => birth_date.setValue(newValue)}
                                minDate={minBirthDate}
                                maxDate={maxBirthDate}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <div className="date-wrapper">
                                        <input ref={inputRef} {...inputProps} className="form-control short"/>
                                        {InputProps?.endAdornment}
                                    </div>
                                )}
                            />
                            <div className="note">
                            Дата рождения видна толька вам
                            </div>
                        </dd>
                        <InputErrorMsg field={birth_date}/>
                    </dl>
                    <dl className="form-group">
                        <dt><label className="form-label" htmlFor="inputBio">Обо мне</label></dt>
                        <dd>
                            <TextareaAutosize
                            minRows={4}
                            className="form-control bio"
                            value={bio.value} 
                            onChange={e => bio.onChange(e)}
                            />
                            <div className="note">
                            Расскажите что-нибудь о себе
                            </div>
                        </dd>
                        <InputErrorMsg field={bio}/>
                    </dl>
                </div>
                <div className='d-flex flex-row align-items-center'>
                    <button onClick={submit} className="btn btn-primary me-3">Сохранить</button>
                    <HelperText 
                        message={'Информация обновлена'} 
                        visible={showSuccessMessage} 
                        size={'sm'}
                        success
                    />
                </div>

            </form>

            </Col>
            <Col sm={12} lg={4}>
                <dl>
                    <dt><label className="form-label">Аватарка</label></dt>
                    <div className="avatar-upload position-relative">
                        <div className="position-relative">
                            <Avatar className="avatar-user" user={user} showDefault={true}/>
                            <Button onClick={toogleAvatarMenu} className='btn change-avatar-btn'><PenIcon />&nbsp;Изменить</Button>
                            <DropdownAvatarMenu isOpen={showAvatarMenu} setOpen={toogleAvatarMenu} onFileChange={onFileChange}/>
                        </div>
                    </div>
                </dl>
            </Col>
        </Row>
        <ImageCroppper 
        isOpen={openImageCropper}
        setOpen={setOpenImageCropper}
        user={user} 
        setUser={setUser} 
        newAvatar={newAvatar}
        />
        </LocalizationProvider>
    )
}

export default UserInfo

function DropdownAvatarMenu({isOpen, setOpen, onFileChange}) {
    const Input = styled('input')({ display: 'none' });

    function handleFileChange(e){
        onFileChange(e);
        setOpen(false);
    }

    return (
        <DropdownMenu isOpen={isOpen} setOpen={setOpen} className={'dropdown-menu-left'}>
            <label htmlFor="file-input1">
                
                <DropdownItem className={'small'} setOpen={setOpen} blockClosing>Загрузить аватар</DropdownItem>
                <Input accept="image/*" id="file-input1" type="file" onChange={(e) => handleFileChange(e)}/>
            </label>
            <DropdownItem className={'small'} setOpen={setOpen}>Сбросить аватар</DropdownItem>
        </DropdownMenu>
    );
  }

const PenIcon = () => (
    <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-pencil">
        <path fillRule="evenodd" d="M11.013 1.427a1.75 1.75 0 012.474 0l1.086 1.086a1.75 1.75 0 010 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 01-.927-.928l.929-3.25a1.75 1.75 0 01.445-.758l8.61-8.61zm1.414 1.06a.25.25 0 00-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 000-.354l-1.086-1.086zM11.189 6.25L9.75 4.81l-6.286 6.287a.25.25 0 00-.064.108l-.558 1.953 1.953-.558a.249.249 0 00.108-.064l6.286-6.286z"></path>
    </svg>
);


