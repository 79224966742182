import $api from '../utils/AxiosInstance';


export default class RecipeService {
    static async getRecipes(params) {
        return $api.get('/api/recipes/', { params: params })
    }

    static async getRecipe(slug) {
        return $api.get(`/api/recipes/${slug}`, {})
    }

    static async getDietTags() {
        return $api.get(`/api/recipes/tags/diet`, {})
    }

    static async getMealTags() {
        return $api.get(`/api/recipes/tags/meal`, {})
    }

    static async getAllTags() {
        return $api.get(`/api/recipes/tags/all`, {})
    }

    // static async getComments(post_id) {
    //     return $api.get(`/api/posts/${post_id}/comments`, {})
    // }

    // static async addNewComment(formData) {
    //     return $api.post(`/api/posts/comments/add`, formData)
    // }

    // static async updateComment(comment_id, formData) {
    //     return $api.put(`/api/posts/comments/${comment_id}`, formData)
    // }

    // static async deleteComment(comment_id) {
    //     return $api.delete(`/api/posts/comments/${comment_id}`, {})
    // }

    // static async likeComment(comment_id) {
    //     return $api.post(`/api/posts/comments/${comment_id}/like`)
    // }

}