import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import './SwipeableMenu.css'

function SwipeableMenu({children, isOpen, setOpen, width=250, className}) {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleMobileMenu = (new_state) => (event) => {
    if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
    ) {
        return;
    }
    setOpen(new_state);
  };

  const handleItemClick = (e) => {
    if (!e.target.getAttribute('data-block-closing')) {
      setOpen(false)
    }
  }


  return (
    <SwipeableDrawer
        anchor={'left'}
        open={isOpen}
        onClose={handleMobileMenu(false)}
        onOpen={handleMobileMenu(true)}
        disableBackdropTransition={true}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        // disableBackdropTransition={!iOS}
        // disableDiscovery={iOS}
        PaperProps={{ sx: { backgroundColor: "var(--color-canvas-default)" } }}
    >
        <div
            style={{ width: width }}
            onClick={e => handleItemClick(e)}

            // onClick={handleMobileMenu(false)}
            // onKeyDown={handleMobileMenu(false)}
            className={className ? className : 'swipeable-menu'}
        >
          {children}
        </div>
    </SwipeableDrawer>
  )
}

export default SwipeableMenu