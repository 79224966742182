import axios from "axios";
import $api from '../utils/AxiosInstance';

export default class CourseService {

    static getCoursesLink = '/api/courses';

    static async getCourses(params) {
        return $api.get( this.getCoursesLink, {params: params})
    }

    static async getCourse(slug, params) {
        return $api.get(`${this.getCoursesLink}/${slug}/`, {params: params})
    }

    static async requestPayment(platform, data) {
        return $api.post(`/api/payments/new_payment/${platform}/`, data)
    }

    static async checkPayment(id) {
        return $api.get(`/api/payments/check`, {params: {'id': id}})
    }

    static async makeTestPayment(data) {
        return $api.post(`/api/payments/notifications/tt/`, data)
    }
    
    static async getUserCourse(slug) {
        return $api.get(`/api/study/get_course`, {params: {'course_slug': slug}})
    }

    static async getCourseStartEndPage(courseID, type) {
        return $api.get(`/api/study/${type}_page`, {params: {'course_id': courseID}})
    }

    static async buyCourse(data) {
        return $api.post(`/api/courses/buy`, data)
    }

    static async extendCourse(data) {
        return $api.post(`/api/courses/extend/`, data)
    }

    // Get owned courses info & progress
    static async getUserCourses() {
        return $api.get('/api/user_courses', {})
    }


    // Course comments
     
    static async getCommentsByStepID(step_id) {
        return $api.get(`/api/steps/${step_id}/comments/`)
    }

    static async likeCommentByID(comment_id) {
        return $api.post(`/api/steps/comments/${comment_id}/like/`)
    }


    static async addNewComment(formData, setUploadPercentage) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressEvent => {
                setUploadPercentage (
                    parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                )
            }
        }
        return $api.post(`/api/steps/comments/add/`, formData, config)
    }

    static async updateComment(comment_id, formData, setUploadPercentage) {
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressEvent => {
                setUploadPercentage (
                    parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                )
            }
        }
        return $api.put(`/api/steps/comments/${comment_id}/`, formData, config)
    }

    static async deleteComment(comment_id) {
        return $api.delete(`/api/steps/comments/${comment_id}/`)
    }
}