import { useState, useEffect } from "react";
import CourseService from 'services/CourseService';
import PostService from 'services/PostService';
import { useFetching } from 'hooks/useFetching';
import Container from 'components/UI/Container';
import PostBasicCard from 'components/Posts/PostBasicCard';
import CourseCard from 'components/Courses/CourseCard';
import RecipesSection from 'pages/Home/RecipesSection';
import AboutStudySection from "./AboutStudySection";
import TopBanner from 'pages/Home/TopBanner';
import 'styles/pages/HomePage.css'
import AboutMeSection from "components/AboutMeSection";
import { useData } from "hooks/useFetching";
import PostServiceLinks from "services/PostServiceLinks";
import { useLocation } from "react-router-dom";

function HomePage() {

    const [courses] = useData([], CourseService.getCoursesLink)
    const [ posts ] = useData([], PostServiceLinks.getPosts, { 'page_size': 10, promoted: true })
    let { state }= useLocation()

    useEffect(() => {
        console.log('courses' , courses)
        console.log('home state' , state)

    }, [courses])


    return (
        <>
        <TopBanner/>
        
        {/* <section className="studio-description">
        <Container>
        <div className="container">
            <div className="studio-description-text">
                <h4>Видеотренировки для любого уровня</h4>
                <h6>Мы верим, что фитнес должен быть доступен для всех, всегда и везде.</h6>
                <h6>Занимайся дома по полчаса в день и ты получишь фигуру своей мечты, без необходимости в спорт зале и дорогом оборудовании.</h6>
            </div>
        </div>
        </Container>
        </section> */}
        
        <section className="courses-section">
            <Container>
                <div className='course-list'>
                    {courses.map(course => <CourseCard key={course.id} course={course}/> )}
                </div>
            </Container>
        </section>
        
        

        <AboutMeSection />
        

        {/* <RecipesSection /> */}
        
        {/* <section className="best-posts-section" id="posts">
            <Container>
                <div className="section-title">
                    <h3>Лучшее из блога</h3>
                </div>
                <Row>
                    {posts.map(post =>
                        <Col sm={12} lg={4} className="mb-5" key={post.id}>
                            <PostBasicCard post={post}/>
                        </Col>
                    )}
                </Row>
                <div className="read-all-btn">
                    <Link className='btn btn-primary' size="large" to='blog'>Читать все</Link>
                </div>
            </Container>
        </section> */}
        </>
    )
}

export default HomePage


