import React, {useEffect, useContext, useState} from 'react';
import { CircularProgress } from '@mui/material';
import { useFetching } from '../../hooks/useFetching';
import UserService from '../../services/UserService';
import { updateItemInArray } from '../../utils/Array';
import MessageContext from '../../context/MessageContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BlackTooltip from '../UI/BlackTooltip/BlackTooltip';
import SubHead from 'components/UI/SubHead';

function Notifications({toggleMobileMenu}) {

    const [subscribedEmails, setSubscribedEmails] = useState([])
    const [subTypes, setSubTypes] = useState(null)
    const [index, setIndex] = useState(0);
    const validSubscriptions = subTypes !== null && subscribedEmails.length > 0

    const { setMessages } = useContext(MessageContext)


    useEffect(() => {
        getSubTypes()
        getSubs()
    }, [])

    const [getSubs, getSubsLoading, getSubssError] = useFetching(async () => {
        // Returns subscriptions per email
        const response = await UserService.getUserSubscriptions()
        setSubscribedEmails(response.data)
    })

    const [getSubTypes, getSubTypesLoading, getSubTypesError] = useFetching(async () => {
        const response = await UserService.getSubscriptionTypes()
        setSubTypes(response.data)
    })

    // const [changeSub, changeSubLoading, changeSubError] = useFetching(async (action, value) => {
    //     const formData = new FormData();
    //     formData.append('email', subscribedEmails[index].email)
    //     formData.append('action', action)
    //     formData.append('value', value)

    //     const response = await UserService.changeSubscriptions(formData)
    //     console.log('changeSub response', response);

    //     updateItemInArray(response.data, subscribedEmails, setSubscribedEmails)
    // })


    const handleChange = (e) => {
        setIndex(e.target.value)
    }

    return (
        <div>
        <SubHead title={'Уведомления на почту'} toggleMobileMenu={toggleMobileMenu} />
        <p className="mb-3">Какие новости вы хотели бы получать на почту:</p>
        <div id="settings-emails" className="box p-3">
            <p className="note text-bold mb-2">Выберите почтовый ящик</p>
            <select 
                id='email_select'
                name='email'
                className='form-select'
                value={index} 
                onChange={handleChange} 
            >
                {subscribedEmails && subscribedEmails.map((subscribedEmail, index) =>
                <option key={subscribedEmail.email} value={index}>
                    {subscribedEmail.email}
                </option>
                )}
            </select>
            {validSubscriptions &&
                subTypes.map(subType => 
                    <SubscriptionCheckbox 
                        subscription={subType} 
                        index={index}
                        subscribedEmails={subscribedEmails}
                        setSubscribedEmails={setSubscribedEmails}
                        key={subType.id}
                    />
                )
            }            
        </div>
        </div>
    )
}

export default Notifications;


const SubscriptionCheckbox = ({subscription, index, subscribedEmails, setSubscribedEmails}) => {
    const [checked, setChecked] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const [success, setSuccess] = useState(false)
    const { setMessages } = useContext(MessageContext)


    useEffect(() => {
        const found = subscribedEmails[index].subscriptions.find(_id => _id == subscription.id)
        if (found) { setChecked(true) }
        else { setChecked(false) }
        resetStatus()
    }, [])


    const [changeSub, changeSubLoading, changeSubError] = useFetching(async () => {
        const formData = new FormData();
        formData.append('email', subscribedEmails[index].email)
        formData.append('slug', subscription.slug)
        formData.append('action', !checked ? 'add' : 'delete')
        setChecked(!checked)
        const response = await UserService.changeSubscriptions(formData)
        handleResponse(response)
    })

    function handleResponse(response) {
        updateItemInArray(response.data, subscribedEmails, setSubscribedEmails)
        setSuccess(true)
        setShowStatus(true)
        setTimeout(() => {
            resetStatus()
        }, [3000]);
    }

    useEffect(() => {
        if(changeSubError?.data) {
            setMessages({level: 'error', message: 'Произошла ошибка. Пожалуйста, попробуйте позже.'})
            setSuccess(false)
            setShowStatus(true)
        }
    }, [changeSubError])

    const resetStatus = () => {
        setShowStatus(false)
        setSuccess(false)
    }

    return (
        <>
        <div className="form-checkbox" key={subscription.id}>
            <label htmlFor={`subscribtion_checkbox_${subscription.id}`}>
                <input 
                    type={"checkbox"} 
                    name="subscribtion_checkbox" 
                    id={`subscribtion_checkbox_${subscription.id}`}
                    checked={checked}
                    onChange={changeSub}
                />
                {subscription.name}
                

                <span className="status-indicator">
                {changeSubLoading && <CircularProgress size={'.8rem'} color={'inherit'}/>}
                {showStatus && success &&
                    <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-check">
                        <path fillRule="evenodd" d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"></path>
                    </svg>
                }
                {showStatus && !success &&
                    <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16" data-view-component="true" className="octicon octicon-x">
                        <path fillRule="evenodd" d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z"></path>
                    </svg>
                }

                </span>
            </label>
            <span className='ms-2'>
                <BlackTooltip title={subscription.description}>
                    <InfoOutlinedIcon fontSize='inherit'/>
                </BlackTooltip>
            </span>
        </div>
        </>
        
    )
}