import React from 'react'
import SwipeableMenu from '../SwipeableMenu/SwipeableMenu'
import './NavMenu.css'

function NavMenuMobile({children, title, isOpen, setOpen, width, className}) {
  return (
        <SwipeableMenu isOpen={isOpen} setOpen={setOpen} width={width} className={className}>
            <nav className="nav-menu-mobile">
                <span className="nav-menu-heading">{title}</span>
                {children}
            </nav>
        </SwipeableMenu>
  )
}

export default NavMenuMobile