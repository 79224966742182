import React, { useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext'
import BackdropLoader from '../components/UI/BackdropLoader/BackdropLoader';
import useLocalContext from '../hooks/useLocalContext';

function AuthUpdateChecker() {
    let { isAuth, updateAuth, isAuthUpdateLoading } = useContext(AuthContext);

    const ctx = useLocalContext({ isAuth })

    useEffect(() => {
        console.log('AuthUpdateChecker Running 1', isAuth, ctx)
        console.log('AuthUpdateChecker Running 1 token', localStorage.getItem('token') )

        const checkAuthOnFocus = () => {
            if (document.visibilityState === 'visible') {
                if (!ctx.isAuth && localStorage.getItem('token') ) {
                    console.log('AuthUpdateChecker Running 2')
                    updateAuth()
                }
            }
        };
        document.addEventListener("visibilitychange", checkAuthOnFocus);
        return () => {
            document.removeEventListener("visibilitychange", checkAuthOnFocus);
        }
    }, [])

    return (
        isAuthUpdateLoading ? <BackdropLoader/> : null
    )
}

export default AuthUpdateChecker
