import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

function ProtectedRoute({children}) {
    const { isAuth, isAuthLoading } = useContext(AuthContext);
    const { pathname } = useLocation();


    if (isAuth == false && isAuthLoading == false) {
        return <Navigate to="/login" replace state={{ from: pathname }}/>;
    }
  
    return children;
}

export default ProtectedRoute