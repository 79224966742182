import React, { useEffect, useState, useRef } from 'react'
import Cropper from 'cropperjs';
import UserService from '../../services/UserService';
import Modal from '../UI/Modal/Modal';
import Loader from '../UI/Loader/Loader';
import { useFetching } from '../../hooks/useFetching';
import LoadingButton from '../../components/UI/Button/LoadingButton';


function ImageCroppper({isOpen, setOpen, user, setUser, newAvatar}) {

    const [newImageUrl, setNewImageUrl] = useState(null)
    const [imageCropper, setImageCropper] = useState(null)
    const [cropperReady, setCropperReady] = useState(false)
    const [isCropperInProcess, setCropperInProcess] = useState(false);
    const cropArea = useRef(null)
    

    const toggleCropperReady = () => {
        setCropperReady(true)
    }

    React.useEffect(() => {
        if (isOpen) {
            setNewImageUrl(URL.createObjectURL(newAvatar))
            const doc = cropArea.current
            doc.addEventListener('ready', toggleCropperReady)     
            return () => {
                setNewImageUrl(null)
                setImageCropper(null)
                setCropperReady(false)
                doc.removeEventListener('ready', toggleCropperReady)
            }
        }
        return undefined
    }, [isOpen])


    useEffect(() => {
        if(newImageUrl) {
            let options = { background: false, aspectRatio: 1 / 1, autoCropArea: 1, highlight: false }
            setImageCropper( new Cropper( cropArea.current, options ) )
        }
    }, [newImageUrl])


    function cropNewAvatar() {
        setCropperInProcess(true)
        imageCropper.getCroppedCanvas().toBlob((blob)=>{
            const formData = new FormData()
            formData.append('profile_image', blob, 'new_avatar.png')
            uploadNewAvatar(formData)
        })
    }


    const [uploadNewAvatar, uploadNewAvatarLoading, uploadNewAvatarError] = useFetching(async (formData) => {
        const response = await UserService.updateAvatar(formData)
        setUser({ ...user, profile_image: response.data.profile_image })
        setCropperInProcess(false)
        setOpen(false)
    })

    
    const isLoading = isCropperInProcess || uploadNewAvatarLoading

    return (
        <Modal
        isOpen={isOpen}
        setOpen={setOpen}
        >
            <div className="box cropper-box anim-fade-in fastn">
                <div className="box-header">
                    <h3 className="Box-title">Обрезка фотографии</h3>
                </div>
                <div className="box-body cropper-box-content">
                    {!cropperReady && <Loader background/>}
                    <div>
                        <img ref={cropArea} id="image-to-crop" src={newImageUrl} rounded="" loaded=""/>
                    </div>
                </div>
                <div className="box-footer">
                    <LoadingButton
                        onClick={cropNewAvatar}
                        isLoading={isLoading}
                        className='btn fullwidth btn-primary-outlined'
                    >
                        Загрузить изображение
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    )
}

export default ImageCroppper
