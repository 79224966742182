import { useState, useRef } from 'react'
import Container from 'components/UI/Container'

function FAQ({questions_answers}) {
    return (
        <section className="faq-section">
            <Container>
                <h2 className="section-title">Частые <span className="text-color-theme">вопросы</span></h2>
                <div className='faq-content-wrapper'>
                    {questions_answers.map(item => (
                            <FaqItem key={item} question={item[0]} answer={item[1]}/>
                        ))}
                </div>
            </Container>
        </section>
    )
}

export default FAQ


const FaqItem = ({question, answer}) => {
    const [maxHeight, setMaxHeight] = useState(0)
    const answerRef = useRef(null)

    const toggleAnswer = () => {
        if (maxHeight == 0) {
            setMaxHeight(answerRef.current.clientHeight)
        } else {
            setMaxHeight(0)
        }
    }

    return (
        <div className='faq-item'>
            <div className={maxHeight > 0 ? 'faq-question open' : 'faq-question'} onClick={toggleAnswer}>
                <p dangerouslySetInnerHTML={{ __html: question }}></p>
            </div>
            <div className='faq-answer-wrapper' style={{maxHeight: maxHeight}}>
                <div className='faq-answer' ref={answerRef}>
                    <p dangerouslySetInnerHTML={{ __html: answer }}></p>
                </div>
            </div>
        </div>
    )
}