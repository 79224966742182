import React, { useMemo } from 'react'
import { useParams, useLocation } from "react-router-dom";
import RippleNavLink from '../UI/RippleLink/RippleNavLink';

function StepLink({step, className, disableRipple}) {
    const enabled = useMemo(() => step?.progress?.is_opened, [step])
    const { course_number, lesson_number } = useParams();
    const { pathname } = useLocation();
    // const enabled = true

    return (
        enabled
        ?
            <RippleNavLink 
                to={`/study/${course_number}/${lesson_number}/${step.number}`}
                className={`${className ? className : ''}`}
                disableRipple={disableRipple}
                isActive={`/study/${course_number}/${lesson_number}/${step.number}` == pathname}
            >
                {step.number}
            </RippleNavLink>
        :
            <span className={`disabled ${className ? className : ''}`}>
                {step.number}
            </span>  
    )
}

export default StepLink


