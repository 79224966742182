import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import 'styles/pages/StudyPage.css'
import CourseService from 'services/CourseService';
import SurveyService from "services/SurveyService";
import { useFetching } from 'hooks/useFetching';
import LessonService from 'services/LessonService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import StepService from 'services/StepService';
import Comments from 'components/Courses/Comments';
import StepPlayer from 'components/Player/StepPlayer';
import Button from 'components/UI/Button/Button';
import { Container, Row, Col } from 'react-bootstrap';
import StepLink from 'components/Study/StepLink';
import { useToggle } from 'hooks/useToggle';
import { getIsMobile } from 'hooks/useIsMobile';
import SwipeableMenu from 'components/UI/SwipeableMenu/SwipeableMenu';
import FinishLesson from 'components/Study/FinishLesson';
import FinishCourseModal from 'components/Study/FinishCourseModal';
import LessonNavMenu from 'components/Study/LessonNavMenu';
import MessageContext from 'context/MessageContext';
import VideoPagination from "./VideoPagination";
import usePDFDownload from "hooks/usePDFDownload";
import PdfLinks from "./PdfLinks";
import Modal from "components/UI/Modal/Modal";
import StudyStandard from "./StudyStandard";



function StudyPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const { setMessages } =useContext(MessageContext)

    const [course, setCourse] = useState(null)
    const [surveyBefore, setSurveyBefore] = useState(null)
    const [surveyAfter, setSurveyAfter] = useState(null)


    const [showLessonNavDefault, toggleLessonNavDefault] = useToggle(true)
    const [showLessonNavMobile, toggleLessonNavMobile] = useToggle(false)

    let { slug, lesson_number, step_number } = useParams();

    const [showFinishCourseModal, setShowFinishCourseModal] = useToggle(false)


    useEffect(() => {
        console.log('StudyPage params', slug, lesson_number, step_number)
        if (course == null || course.slug != slug) {
            fetchCourseBySlug(slug)
        }
    }, [slug])


    //#region Course
    const courseErrorHandler = (response) => {
        if (response.status === 403) {
            //If user has no access to course ->
            //show error message and push to main page
            setMessages([{level: 'error', message: response?.data?.detail}])
            navigate('/')
        } else {
            //or show error on console
            console.log(response?.data)
        }
    }

    const [fetchCourseBySlug] = useFetching(async (slug) => {
        const response = await CourseService.getUserCourse(slug);
        setCourse(response.data)
    }, courseErrorHandler)

    //#endregion


    if (course == null) {
         return null
    }

    if (course.course_type == 'Standard') {
        return <StudyStandard course = {course} setCourse = {setCourse}/>
    }

    return null

}

export default StudyPage