import React, { useEffect } from 'react'
import { getHashParameter, getQueryParameter } from '../../utils/Common'
import AuthService from '../../services/AuthService'
import { useFetching } from '../../hooks/useFetching'
import { useParams } from 'react-router-dom';

function AuthPopupCallBack() {

    const params = useParams()
    const provider = params.provider
    // const socketID = params.socket
    const socket = getQueryParameter("socket")
    const code = getQueryParameter("code")
    const access_token = getHashParameter("access_token")
    const state = getQueryParameter("state") ? getQueryParameter("state") : getHashParameter("state")

    const redirectUri = localStorage.socialRedirectUri;
    const isStateCorrect = state === localStorage.socialState;


    const [redirectCode] = useFetching(async () => {
        let formData = new FormData()
        formData.append('socket', socket)
        formData.append('provider', provider)
        formData.append('code', code)
        formData.append('access_token', access_token)
        formData.append('redirectUri', redirectUri)

        await AuthService.RedirectAuthCode(formData);
        window.close()
    })


    // if ((!socket || !state) && (!code && !token)) {
    //     window.close()
    // }

    useEffect(() => {

        if (socket && state && isStateCorrect && redirectUri && (code || access_token)) {

            localStorage.socialState = '';
            localStorage.socialRedirectUri = '';
            redirectCode()
        } else {
            console.error("Invalid social callback");
        }
    }, [])

    return (
        <div></div>
    )
}

export default AuthPopupCallBack