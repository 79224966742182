import React, { useState, useEffect, useRef } from "react";
import './Button.css'

function Button({ children, onClick, className, disabled, type='button' }) {

    const [size, setSize] = useState(0);
    const rippleRef = useRef(null);

    useEffect(() => {
        calculateSize()
    }, [])

    function calculateSize() {
        const largestSide =  Math.sqrt(
            Math.pow(rippleRef.current.offsetWidth, 2) +
            Math.pow(rippleRef.current.offsetHeight, 2)
        )
        setSize(Math.ceil((largestSide * 2) / 100))
    }


    function startRiple(event) {
        const yCoord = event.pageY;
        const xCoord = event.pageX;
        const x = ((xCoord - rippleRef.current.offsetLeft) * 100)  / rippleRef.current.offsetWidth;
        const y = ((yCoord - rippleRef.current.offsetTop) * 100)  / rippleRef.current.offsetHeight;

        const dot = document.createElement('span');

        dot.style = `
		        width: ${size}px;
		        height: ${size}px;
		    `;

        dot.className = "btn-ripple"
        
        rippleRef.current.appendChild(dot)

        dot.style.left = x + '%';
		dot.style.top = y + '%';

        setTimeout(() => {
            try {
                rippleRef.current.removeChild(dot)
            } catch {
                
            }
        }, 2000);
    }


    return (

        <button
            className={className}
            ref={rippleRef}
            onMouseDown={e => startRiple(e)}
            onClick={e => {onClick && onClick(e)}}
            disabled={disabled}
            type={type}
        >
            {children}
        {/* <span className="button-content">{children}</span> */}
        
        </button>

    );
}

export default Button