import { useEffect, useState } from 'react';
import Container from 'components/UI/Container'
import { useFetching } from 'hooks/useFetching';
import { $static } from 'utils/AxiosInstance';

function ToWhom({itemsArray, imageUrl}) {
    const [leftColumn, setLeftColumn] = useState([])
    const [rightColumn, setRightColumn] = useState([])
    const [svg, setSvg] = useState(null);

    const [fetchImage, imageLoading, imageError] = useFetching(async (url) => {
        const response = await $static.get(url)
        setSvg(response.data)
    })

    useEffect(() => {
        const middleIndex = Math.ceil(itemsArray.length/2)
        setLeftColumn(itemsArray.slice( 0 , middleIndex ))
        setRightColumn(itemsArray.slice( middleIndex , itemsArray.length ))

        fetchImage(imageUrl)

    }, [itemsArray, imageUrl])

    return (
        <section className="course-to-whom">
            <Container>
                <h2 className="section-title"><span className='line'>Этот курс для</span> <span className='line'><span className="text-color-theme">вас</span> если?</span></h2>
                <div className='to-whom-content'>
                    <div className='to-whom-left'>
                        {leftColumn.map(item =>(
                            <div className='to-whom-item' key={item}>
                                <p dangerouslySetInnerHTML={{ __html: item }}></p>
                            </div>
                        ))}
                        <img className='to-whom-arc' src="/images/arc-line.png" alt="Этот курс для вас если?"/>
                    </div>
                    <div className='to-whom-middle' >
                        <div className='to-whom-pose' dangerouslySetInnerHTML={{ __html: svg }}></div>
                        <div className='to-whom-sun'></div>
                    </div>
                    <div className='to-whom-right'>
                        {rightColumn.map(item =>(
                            <div className='to-whom-item' key={item}>
                                <p dangerouslySetInnerHTML={{ __html: item }}></p>
                            </div>
                        ))}
                        <img className='to-whom-arc' src="/images/arc-line.png" alt="Этот курс для вас если?"/>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default ToWhom