import Container from 'components/UI/Container'


function Equipment({equipmentArray}) {
    return (
        <section className="equipment-section">
            <Container>
                <h2 className="section-title">Вам <span className="text-color-theme">понадобится</span></h2>
                <h3 className="section-subtitle">Закажите на любом маркетплейсе или в спортивном магазине</h3>
                <div className='equipment-content'>
                    {equipmentArray.map(equipmentItem => (
                        <div className='equipment-item' key={equipmentItem}>
                            <img src={equipmentItem[2]} alt="Вам понадобится"/>
                            <p className='equipment-title'>{equipmentItem[0]}</p>
                            <p className='equipment-subtitle'>{equipmentItem[1]}</p>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    )
}

export default Equipment