import React from 'react'
import './NavMenu.css'

function NavMenu({children, title}) {

    return (
        <nav className="nav-menu-default">
            <span className="nav-menu-heading">{title}</span>
            {children}
        </nav>
    )
}

export default NavMenu

