import { useState, useEffect } from "react";
import { useFetching } from 'hooks/useFetching';
import CourseService from 'services/CourseService';
import { useLocation, useParams } from 'react-router-dom';
import SurveyService from "services/SurveyService";
import { useInput } from "hooks/useInput";
import InputErrorMsg from "components/InputErrorMsg";
import TextArea from "components/Comments/TextArea";
import Button from "components/UI/Button/Button";
import BackdropLoader from "components/UI/BackdropLoader/BackdropLoader";
import Loader from "components/UI/Loader/Loader";


function StartFinishPage({courseID, type}) {
    const [textContent, setTextContent] = useState('')
    const [survey, setSurvey] = useState(null)
    const [inputs, setInputs] = useState({})
    const [images, setImages] = useState({})
    const [isSubmitted, setSubmitted] = useState(false)
    const location = useLocation()
    const [showValidationErrors, setShowValidationErrors] = useState(false)


    const [fetchCourseStartEndPage] = useFetching(async () => {
        console.log('fetchCourseStartPage')
        const response = await CourseService.getCourseStartEndPage(courseID, type);
        const data = response.data
        const content = data?.start_page_web ? data.start_page_web : data.end_page_web ? data.end_page_web : ""
        setTextContent(content)
    }, (error) => console.log('error', error))

    // Survey

    const getSurveyErrorHandler = (response) => {
        console.log('courseErrorHandler', response)
        // if (response.status === 403) {
        //     setMessages([{level: 'error', message: response?.data?.detail}])
        //     navigate('/')
        // } else {
        //     console.log(response?.data?.detail)
        // }
    }

    const [getSurvey, getSurveyLoading] = useFetching(async () => {
        console.log('getSurveys')
        const params = {'course_id': courseID, 'type': type}
        const response = await SurveyService.getServey(params)
        console.log('getSurveys', response.data)
        if (response.status == 201) {
            // Alredy submitted
            setSubmitted(true)
            return
        }
        if (response.status == 204) {
            // Course has no Survey
            return
        }
        setSubmitted(false)
        setInputs({})
        setImages({})
        setSurvey(response.data)
    }, getSurveyErrorHandler)

    const [submitSurvey, submitSurveyLoading, submitSurveyError] = useFetching(async (e) => {
        e.preventDefault()
        setShowValidationErrors(true)
        const isAllInputsValid = Object.values(inputs).every(input => input.isValid == true)
        const isAllImagesValid = Object.values(images).every(image => image.isValid == true)

        if (isAllInputsValid == false || isAllImagesValid == false) { return }

        const formData = new FormData();
        formData.append('course_id', courseID)
        formData.append('type', type)
        console.log('submitSurvey 1')
        console.log('isAllInputsValid', isAllInputsValid)
        console.log('isAllImagesValid', isAllImagesValid)



        let inputsCounter = 0
        for (let inputKey in inputs) {
            formData.append(`answers[${inputsCounter}]value`, inputs[inputKey].value)
            formData.append(`answers[${inputsCounter}]question`, inputKey)
            inputsCounter++
        }

        let imagesCounter = 0
        for (let imageKey in images) {
            // formData.append(`images[${imagesCounter}]file`, images[imageKey].value)
            formData.append(`images[${imagesCounter}]file`, images[imageKey].value ? images[imageKey].value : '')

            formData.append(`images[${imagesCounter}]image_upload`, imageKey)
            imagesCounter++
        }

        const response = await SurveyService.submitServey(formData)
        if (response.data?.detail == "submitted") {
            setSubmitted(true)
            return
        }
        // const response = await SurveyService.submitServey(formData)
        console.log('submitSurvey', response.data)
    })

    useEffect(() => {
        fetchCourseStartEndPage()
        getSurvey()
    }, [type])

    if (getSurveyLoading) {
        return <Loader position={'absolute'}/>
    }

    return (
        <>
            <form className="profile-form">
                <div dangerouslySetInnerHTML={{ __html: textContent }}></div>
                {survey && isSubmitted == false &&
                    <div>
                        <p className="mb-4">Пожалуйста, заполните анкету</p>
                        <div className="survey-content">
                            <div className="survey-questions">
                                {survey.questions.map(question =>
                                    <SurveyQuestion
                                        question={question}
                                        showValidationErrors={showValidationErrors}
                                        key={question.id}
                                        setInputs={setInputs}
                                    />
                                )}
                            </div>
                            <div className="survey-image-uploads">
                                {survey.images_upload.map(image_upload =>
                                    <ImageUpload
                                        image_upload={image_upload}
                                        key={image_upload.id}
                                        setImages={setImages}
                                    />
                                )}
                            </div>
                        </div>
                        <button onClick={submitSurvey} className="btn">Отправить</button>
                    </div>
                }
                {isSubmitted == true &&
                    <div>
                        <p>Анкета заполнена</p>
                    </div>
                }
                
            </form>
        </>
    )
}

export default StartFinishPage


function ImageUpload({image_upload, setImages}){
    const file = useInput(null, 'image', 'file', {maxFileSize: 1})
    const [fileUrl, setFileUrl] = useState('')

    const onFileChange = e => {
        file.setValue(e.target.files[0])
    }

    function clear() {
        file.setValue(null)
        // file.reset() is not used here because it stops validation
    }

    useEffect(() => {
        setImages(prev => {
            prev[image_upload.id] = {
                value: file.value,
                isValid: file.isValid
            }
            return ({...prev})
        })
        
        if (file.value) {
            setFileUrl(URL.createObjectURL(file.value))
        } else {
            setFileUrl('')
        }
    }, [file.value, file.isValid])

    useEffect(() => {
        file.StartValidation()
    }, [])

    return (

        <div className="survey-image-upload-wrapper">
            <div className="survey-image-upload">
                <label className="form-label">{image_upload.title}</label>
                <div className={file.isValid ? "image-upload-preview" : "image-upload-preview invalid"}>
                    <img src={fileUrl}/>
                </div>
                {file.value == null
                    ? <label className="btn" htmlFor={`image_upload_${image_upload.id}`}>Выбрать</label>
                    : <Button className="btn btn-alert" onClick={clear}>Удалить</Button>
                }
                <input
                    type="file"
                    id={`image_upload_${image_upload.id}`}
                    name="myImage"
                    accept=".jpg, .jpeg, .png"
                    onChange={onFileChange}/>
            </div>
            <InputErrorMsg field={file} visible={true}/>
        </div>
    )
}


function SurveyQuestion({question, showValidationErrors, setInputs}) {


    const userInput = useInput('', 'first_name', question.type,
        {
            notEmpty: !question.allow_empty,
            minLength: question.min_length,
            maxLength: question.max_length,
            minNumber: question.min_number,
            maxNumber: question.max_number,
            maxFileSize: question.max_file_size
        }
    )

    useEffect(() => {
        setInputs(prev => {
            prev[question.id] = {
                value: userInput.value,
                isValid: userInput.isValid
            }
            return ({...prev})
        })
    }, [userInput.isValid, userInput.value])

    useEffect(() => {
        userInput.StartValidation()
    }, [])


    return (
        <dl className="form-group">
            <dt><label className="form-label" htmlFor="inputFirstName">{question.title}</label></dt>
            <dd>
                {question.type == 'text'
                    ?
                    <TextArea value={userInput.value} onChange={e => userInput.onChange(e)} />
                    :
                    <input 
                        // type={question.type == 'short_text' ? 'text' : 'number'} 
                        type='text'
                        inputMode={question.type == 'short_text' ? 'text' : 'numeric'} 
                        id="inputFirstName" 
                        className="form-control full-width" 
                        value={userInput.value} 
                        onChange={e => userInput.onChange(e)} 
                    />
                }
                {/* <div className="note">
                    Имя и фамилия видны другим пользователям
                </div> */}
            </dd>
            <InputErrorMsg field={userInput} visible={showValidationErrors}/>
        </dl>
    )
}