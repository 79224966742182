import { useState, useEffect, useCallback, useEffectEvent, useRef } from 'react'
import $api from 'utils/AxiosInstance';


export const useFetching = (callback, errorHandler, text='NOT') => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const isMounted = useRef(false);


    useEffect(() => {
        isMounted.current = true; // mounted
        return () => {
            isMounted.current = false; // unmounted
        }
    }, []); // run once on mount

    const fetching = async (...args) => {
        setIsLoading(true)
        try {
            await callback(...args)
        } catch (error) {
            if (isMounted) {
                console.log('Mounted')
                setErrors(error?.response)
                if (errorHandler) {
                    errorHandler(error.response)
                }
            } else {
                console.log('Not mounted', error)
            }
        } finally {
            setIsLoading(false)
        }

    };

    // const fetching = useCallback(async (...args) => {
    //     console.log('useCallback RUNNING', text)
    //     setIsLoading(true)
    //     try {
    //         await callback(...args)
    //     } catch (error) {
    //         if (isMounted) {
    //             setErrors(error?.response)
    //             if (errorHandler) {
    //                 errorHandler(error.response)
    //             }
    //         } else {
    //             console.log('Not mounted', error)
    //         }
    //     } finally {
    //         setIsLoading(false)
    //     }

    // }, [callback, errorHandler]);

    return [fetching, isLoading, errors]
}


export const useData = (initialValue, url, params={}) => {
    const [data, setData] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    console.log('useData')

    useEffect(() => {
        const fetchData = async () => {
            let ignore = false;
            setIsLoading(true);
            try {
                const response = await $api.get(url, {params: params});
                if (!ignore) {
                    setIsLoading(false);
                    setError(null);
                    setData(response.data);
                }
            } catch (error) {
                if (!ignore) {
                    setError(`${error} Could not Fetch Data `);
                    setIsLoading(false);
                }
            }
            return () => {
                ignore = true;
            };
        };
        fetchData();
    }, [url]);

    return [data, isLoading, error];
};


export const useCallData = (method, url, errorHandler) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef();

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const fetchData = async ({params, data}) => {
        abortControllerRef.current = new AbortController();
        setIsLoading(true);
        console.log('fetchData', url, data)
        try {
            const response = await $api.request({
                method: method,
                url,
                params,
                data,
                signal: abortControllerRef.current.signal,
            });
            setIsLoading(false);
            setError(null);
            return response
        } catch (error) {
            if (!abortControllerRef.current?.signal.aborted) {
                setError(`${error} Could not Fetch Data `);
                setIsLoading(false);
                if (errorHandler) {
                    errorHandler(error.response)
                }
            }
        } finally {
            setIsLoading(false)
        }
    };

    return [fetchData, isLoading, error];
};



// export const useAxios = (axiosParams) => {
//     const [response, setResponse] = useState(undefined);
//     const [error, setError] = useState('');
//     const [loading, setloading] = useState(true);

//     const fetchData = async (params) => {
//       try {
//        const result = await axios.request(params);
//        setResponse(result.data);
//        } catch( error ) {
//          setError(error);
//        } finally {
//          setLoading(false);
//        }
//     };

//     useEffect(() => {
//         fetchData(axiosParams);
//     }, []);

//     return { response, error, loading };
// };

export const useSending = (callback) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    
    const sending = async (...args) => {
        try {
            setIsUploading(true)
            await callback(...args)
        } catch (e) {
            setError(e.message)
        } finally {
            setIsUploading(false)
        }
    }
    
    return [sending, setIsUploading, error]
}


export const useTesting = (callback) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    
    const sending = async (...args) => {
        try {
            setIsUploading(true)
            await callback(...args)
        } catch (e) {
            setError(e.message)
        } finally {
            setIsUploading(false)
        }
    }
    
    return [sending, setIsUploading, error]
}


export const useTest = (callback) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    
    const sending = async (...args) => {

        try {
            setIsUploading(true)
            await callback(...args)
        } catch (e) {
            setError(e.message)
        } finally {
            setIsUploading(false)
        }
    }
    
    return [sending, setIsUploading, error]
}