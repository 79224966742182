import $api from '../utils/AxiosInstance';

export default class PostService {
    static async getPosts(params) {
        return $api.get('/api/posts/', { params: params })
    }

    static async getPost(slug) {
        return $api.get(`/api/posts/${slug}`, {})
    }

    static async getComments(post_id) {
        return $api.get(`/api/posts/${post_id}/comments/`, {})
    }

    static async addNewComment(formData) {
        return $api.post(`/api/posts/comments/add/`, formData)
    }

    static async updateComment(comment_id, formData) {
        return $api.put(`/api/posts/comments/${comment_id}/edit/`, formData)
    }

    static async deleteComment(comment_id) {
        return $api.delete(`/api/posts/comments/${comment_id}/edit/`, {})
    }

    static async likeComment(comment_id) {
        return $api.post(`/api/posts/comments/${comment_id}/like/`)
    }

}