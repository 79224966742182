import { useEffect, useState, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFetching } from 'hooks/useFetching';
import CourseService from 'services/CourseService';
import MessageContext from 'context/MessageContext';
import BackdropLoader from 'components/UI/BackdropLoader/BackdropLoader';


const paymentErrorMessages = {
    pending: 'Не удается подтвердить оплату. Попробуй позже или обратитесь в службу поддержки.',
    failed: 'Ошибка оплаты.',
    refunded: 'Платеж возращен.',
    cancelled: 'Платеж отменен.',
    processing: 'Платеж все еще обрабатывается. Попробуй позже или обратитесь в службу поддержки.',
    status_unknown: 'Не удается определить статус платежа.',
    request_error: 'Ошибка запроса. Попробуй позже или обратитесь в службу поддержки.',
}


function PaymentCheck() {
    const { setMessages } = useContext(MessageContext)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const paymentID = searchParams.get('id')
    let didInit = false
    let retries = 0
    const maxRetries = 3
    const retryInterval = 3000


    const [checkPayment] = useFetching(async () => {
        const response = await CourseService.checkPayment(paymentID)
        const status = response.data.status
        const course = response.data.course

        // Payment successful
        if (status == 'completed') {
            setLoading(false)
            if (course.type == 'survey') {
                setMessages([{level: 'success', fadeEnabled: false, message: 'Анкета уже отправлена на вашу почту. Пожалуйста, проверьте входящие письма.</br>Если письмо не пришло в течение 2х минут, проверьте папку для спама или обратитесь в службу поддержки.'}])
            }
            navigate(`/course/${course.slug}`)
            return
        }

        // Payment is still pending. Try 3 times
        if (status == 'pending' && retries < maxRetries) {
            retries++
            setTimeout(() => {
                checkPayment()
            }, retryInterval);
            return
        }


        setLoading(false)
        // Payment not successful or still pending. Show status or error message
        const errorMessage = paymentErrorMessages[status] ?? paymentErrorMessages['status_unknown']
        setMessages([{level: 'error', message: errorMessage}])
        navigate(`/course/${course.slug}`)

    }, () => {
        setLoading(false)
        setMessages([{level: 'error', message: paymentErrorMessages['request_error']}])
        // No course info. Redirect to home page
        navigate('/')
    })


    useEffect(() => {
        if (!didInit) {
            didInit = true
            checkPayment()
        }
    }, [])


    return (
        loading ?
        <BackdropLoader />
        :
        <div></div>
    )
}

export default PaymentCheck