import React from 'react'
import PropTypes from 'prop-types'
import plyr from 'plyr'
// import 'plyr/dist/plyr.css'
import './PlyrPlayer.css'
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


function PlyrPlayer(props) {
    const [player, setPlayer] = useState(null)
    const ref = useRef()
    const endedRef = useRef(props.onEnded ? props.onEnded : null)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        let options = props.options ? {...defaultOptions, ...props.options} : defaultOptions

        let _player = new plyr('.js-plyr', options)

        setPlayer(_player)

        // return () => {
        //   console.log('Destroy')
        //   ref.player.destroy()
        // }

    }, [])

    useEffect(() => {
        if (player && props.onEnded) {
            // Run onEnded function on video end
            if (typeof props.onEnded === 'function') {
                if (endedRef.current) {
                    // Remove an event listener
                    player.off('ended', endedRef.current);
                }
                // Add an event listener
                player.on('ended', props.onEnded);
                endedRef.current = props.onEnded
            }
        }
    }, [player, props.onEnded])

    useEffect(() => {
        if (player) {
            // Stop current video if there is a new video loading
            if (props.isVideoLoading) {
                player.stop()
            }
        }
    }, [props.isVideoLoading])

    useEffect(() => {
        if (player && props.videos.length > 0) {

            player.source = {
                type: 'video',
                sources: props.videos,
                poster: props?.poster ? props.poster : '',
            }

            if (location?.state?.autoplay === true) {
                player.play()
                // Delete Autoplay state after autoplay proceeded
                // const state = { ...history.location.state };
                // delete state.autoplay
                // history.replace({ ...history.location, state });
            }
        }

    }, [player, props.videos])

    return (
        <video ref={ref} className='js-plyr plyr'>
        </video>
    )
}

export default PlyrPlayer


const defaultOptions = {
    ratio: '16:9',
    controls: [
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'settings',
        'fullscreen',
    ],
    i18n: {
        play: 'Смотреть',
        pause: 'Пауза',
        volume: 'Звук',
        mute: 'Отключение звука',
        unmute: 'Включить звук',
        enterFullscreen: 'Во весь кэран',
        exitFullscreen: 'Выход из полноэкранного режима',
        frameTitle: '{title}',
        settings: 'Настройки',
        speed: 'Скорость',
        normal: 'Обычная',
        quality: 'Качество',
        pip: 'Широкий экран'
    },
    tooltips: {
        controls: true,
        seek: true
    },
}


// sources: {
//     type: 'video',
//     sources: [
//         {
//             src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
//             type: 'video/mp4',
//             size: 480,
//         },
//         {
//             src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4',
//             type: 'video/mp4',
//             size: 720,
//         },
//         {
//             src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4',
//             type: 'video/mp4',
//             size: 1080,
//         },
//     ],
// }