import React, { createContext, useState, useEffect } from 'react'
import { useFetching } from '../../hooks/useFetching';
import CourseService from '../../services/CourseService';
import CommentForm from './CommentForm';
import Comment from './Comment';
import { updateComment, updateLikeStats } from '../Comments/utils';
import { updateItemsInArray } from '../../utils/Array';
import { useQuery } from '../../hooks/useQuery';

export const CommentsContext = createContext(null)

function Comments({step}) {

    const [comments, setComments] = useState([])
    const [users, setUsers] = useState([])
    const [highlighted , setHighlighted] = useState(null)

    let query = useQuery();

    useEffect(() => {
        if (step && step.id) {
            fetchCommentsByStepID(step.id)
            setHighlighted(query.get('comment'))
        }
    }, [step])

    const [deleteComment] = useFetching(async (comment_id) => {
        const response = await CourseService.deleteComment(comment_id)
        if (response && response.status === 200) {
            updateItemsInArray(response.data, comments, setComments)
        }
    })

    const [likeComment] = useFetching(async (comment_id) => {
        const response = await CourseService.likeCommentByID(comment_id)
        updateLikeStats(response.data, comments, setComments)
    })

    const [fetchCommentsByStepID] = useFetching(async (step_id) => {
        const response = await CourseService.getCommentsByStepID(step_id)
        setComments(response.data.comments)
        setUsers(response.data.users)
    })

    let context = {
        comments: comments,
        setComments: setComments,
        users: users,
        setUsers: setUsers,
        deleteComment: deleteComment,
        likeComment: likeComment,
        highlighted: highlighted
    }

    if (step == null) { return null }

    return (
        <CommentsContext.Provider value={context}>
        <div className="all-comments">
            <div className="new-comment my-3">
                <CommentForm mode={'new'} stepID={step.id}/>
            </div>
            <div className="all-comments">
                {comments.length > 0 && comments.map(_comment => {
                    if (!_comment.thread) {
                        return (
                            <div className="comment-thread" key={_comment.id}>
                                <Comment comment={_comment}/>
                                <div className="comment-replies">
                                    {_comment.descendants.length > 0 &&
                                        _comment.descendants.map(_descendant_id => {
                                                const result = comments.find(_item => _item.id === _descendant_id)
                                                if (result) {
                                                    return <Comment key={result.id} comment={result}/>
                                                } else {
                                                    return null
                                                }
                                            }
                                        )
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
        </CommentsContext.Provider>
    )
}

export default Comments
